import { createSlice } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

export enum SenderType {
  USER = "USER",
  BOT = "BOT"
}

export interface MessageProps {
  run_id: string,
  text: string,
  files?: File[],
  sender: SenderType,
  timestamp: string,
}

export interface ConversationProps {
  id?: string,
  conversation_id: string,
  thread_id?: string,
  name: string,
  intro: boolean,
  messages: MessageProps[]
}

export interface SimpleConversationProps {
  conversation_id: string,
  message: MessageProps
}

const initialState = {
  currentConversation: "",
  conversationList: [] as ConversationProps[]
}

const conversationSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setCurrentConversation: (state, {payload}) => {
      state.currentConversation = payload;

      return state;
    },
    setHistoryConversation: (state, {payload}) => {
      state.conversationList = payload.conversations;


      return state;
    },
    addHistoryConversation: (state, {payload}) => {
      state.conversationList = state.conversationList.map(conversation => {
        if (conversation.conversation_id === payload.conversation_id) {
          conversation = payload;
        }
        return conversation;
      });
      state.currentConversation = payload.conversation_id;
    },
    newConversation: (state, {payload}) => {
      const uid = v4();
      state.conversationList.push({
        conversation_id: uid,
        name: "",
        intro: true,
        messages: []
      })
      state.currentConversation = uid;

      return state;
    },
    addConversation: (state, { payload }) => {
      state.conversationList.push({
        conversation_id: payload.conversation_id,
        name: "Something...",
        intro: true,
        messages: [payload.message]
      });
      state.currentConversation = payload.conversation_id;

      return state;
    },
    removeConversation: (state, { payload }) => {
      state.conversationList = state.conversationList.filter(conversation => conversation.conversation_id !== payload);

      return state;
    },
    addMessageToConversation: (state, { payload }) => {
      const conversation = state.conversationList.find(conversation => conversation.conversation_id === payload.conversation_id);
      if(conversation) {
        conversation.messages.push(payload.message);
        conversation.intro = false;
      } else {
        state.conversationList.push({
          conversation_id: payload.conversation_id,
          name: "Something...",
          intro: true,
          messages: [payload.message]
        });
      }

      return state;
    },
    updateMessageInConversation: (state, { payload }) => {
      const conversation = state.conversationList.find(conversation => conversation.conversation_id === payload.conversation_id);
      if(conversation) {
        const message = conversation.messages.find(message => message.run_id === payload.message.run_id);
        if(message) {
          message.text = message.text + payload.message.text;
          message.timestamp = payload.message.timestamp;
        } else {
          conversation.messages.push(payload.message);
          conversation.intro = false;
        }
      } else {
        state.conversationList.push({
          conversation_id: payload.conversation_id,
          name: "Something...",
          intro: true,
          messages: [payload.message]
        });
      }

      return state;
    },

    addTitleToConversation: (state, { payload }) => {
      const conversation = state.conversationList.find(conversation => conversation.conversation_id === payload.conversation_id);

      console.log("ADD TITLE TO CONVERSATION", payload, conversation);

      if(conversation) {
        conversation.name = payload.name;
        conversation.thread_id = payload.thread_id;
      }
      return state;
    },
    clearConversations: (state) => {
      state.conversationList = [];
      state.currentConversation = "";

      return state
    }
  }
})
const conversationReducer = conversationSlice.reducer;

export const {setHistoryConversation, newConversation, setCurrentConversation, addHistoryConversation, clearConversations, removeConversation, addConversation, addMessageToConversation, updateMessageInConversation, addTitleToConversation}  = conversationSlice.actions;
export default conversationReducer;
