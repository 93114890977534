import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import instance from '../../../utils/api'
import { getAllUniqueCountries, getCountryFromISO } from './utils'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import SearchCountryInstituteField from './SearchCountryInstituteField'
import { EndUserAgreement, Integration, Option } from './interface'
import classNames from 'classnames'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useAppSelector } from '../../../state/stateHooks'
import { useAuth0 } from '@auth0/auth0-react'

interface GoCardlessModalProps {
    demo?: boolean
}
const GoCardlessModal : React.FC<GoCardlessModalProps> = (props) => {
    const { user } = useAuth0();
    const data = useAppSelector(state => state.onboardingReducer)
    const { demo } = props

    const [open, setOpen] = useState<boolean>(false)

    const [countries, setCountries] = useState<Option[]>([])
    const [institutes, setInstitutes] = useState<Integration[]>([])
    const [filteredInstitute, setFilteredInstitute] = useState<Integration[]>([])
    const [search, setSearch] = useState<{
        type: string,
        value: string
    }>({
        type: '',
        value: ''
    })
    const [country, setCountry] = useState<string>('')
    const [institute, setInstitute] = useState<Integration>()
    const [agreement, setAgreement] = useState<EndUserAgreement>()
    const [step, setStep] = useState<number>(0)


    useEffect(() => {
        if (data.tenantName) {
            instance.get("/api/v1/tenant/bank-account/connect")
                .then(res => {
                    console.log("CALLBACK", res.data)
                    const countries = getAllUniqueCountries(res.data).map(country => {
                        return {
                            value: country,
                            label: getCountryFromISO(country)
                        }
                    })


                    setCountries(countries)
                    setInstitutes(res.data)
                    console.log("COUNTRIES", countries)

                })
                .catch(err => {
                    console.log("CALLBACK ERROR", err)
                })
        }
    }, [data.tenantName])

    const setClose = async () => {
        setOpen(false)

        try {
            await instance.get("/api/v1/tenant/bank-account/sync", {
                params: {
                    permanent: true,
                    initial: true,
                    institute_id: institute?.id,
                }
            })
        } catch (err) {
            console.log("SYNC ERROR")
        }
    }

    const handleSearchChange = (search: string, type: string) => {
        setSearch({
            type: type,
            value: search
        })
    }

    const handleCountryClick = (country: string) => {
        setCountry(country)
        const countryInstitutes = institutes.filter(institute => institute.countries.includes(country))
        setFilteredInstitute(countryInstitutes)
        setStep(1)
    }

    const handleInstituteClick = (institute: Integration) => {
        setInstitute(institute)

        instance.post("/api/v1/tenant/bank-account/agreement", {
            institute_id: institute.id
        })
            .then(res => {
                console.log("INSTITUTE AGREEMENT", res.data)
                setAgreement(res.data)
                setStep(2)
            })
            .catch(err => {
                console.log("INSTITUTE AGREEMENT ERROR", err)
            })
    }

    const trackOpenPopup = (link: string, institute: Integration, demo: boolean = false) => {
        const popup = window.open(link, "GoCardless", "width=500,height=900");

        const interval = setInterval(async () => {
            try {
                if (!popup || popup.closed) {
                    clearInterval(interval);
                    console.log("Popup closed by user or authentication complete.");
                }
                // Check if the redirect URI is loaded
                if (popup && popup.location.href.startsWith("http://localhost:3000/")) {
                    // Parse the authorization code from the URL
                    const params = new URLSearchParams(popup.location.search);
                    const code = params.get("ref");

                    console.log("Authorization code:", popup.location.search, code);

                    // Close the popup
                    popup.close();
                    clearInterval(interval);

                    // Handle the authorization code (e.g., exchange it for tokens)
                    try {
                        await instance.post("/api/v1/tenant/bank-account/agreement/confirm", {
                            institute_id: institute.id,
                            user_id: user?.userId,
                            code: code,
                            demo: demo
                        })

                        setStep(3)
                    } catch(e) {
                        console.log("AGREEMENT CONFIRM ERROR", e)
                    }
                }
            } catch (err) {
                // Ignore cross-origin errors until the redirect URI is loaded
            }
        }, 500);
    }

    const handleAgreementAccept = () => {
        if (institute && agreement) {
            if (demo) {
                //window.location.href = "/integration/bank-account/callback/demo"

                trackOpenPopup("/integration/bank-account/callback/demo", institute, true)

            } else {
                instance.post("/api/v1/tenant/bank-account/agreement/accept", {
                    institute_id: institute.id,
                    agreement_id: agreement.id
                })
                    .then(res => {
                        const link = res.data.link;

                        // Open the link in a new small window
                        /*const popup = window.open(link, "GoCardless", "width=500,height=900");

                        const interval = setInterval(() => {
                            try {
                                if (!popup || popup.closed) {
                                    clearInterval(interval);
                                    console.log("Popup closed by user or authentication complete.");
                                }
                                // Check if the redirect URI is loaded
                                if (popup && popup.location.href.startsWith("http://localhost:3000/")) {
                                    // Parse the authorization code from the URL
                                    const params = new URLSearchParams(popup.location.search);
                                    const code = params.get("ref");

                                    console.log("Authorization code:", popup.location.search, code);

                                    // Close the popup
                                    popup.close();
                                    clearInterval(interval);

                                    // Handle the authorization code (e.g., exchange it for tokens)
                                    instance.post("/api/v1/tenant/bank-account/agreement/confirm", {
                                        institute_id: institute.id,
                                        user_id: user.userId,
                                        code: code
                                    })
                                }
                            } catch (err) {
                                // Ignore cross-origin errors until the redirect URI is loaded
                            }
                        }, 500);*/

                        //TODO set this inside of the confirmation return statement
                        setStep(3)

                        console.log("AGREEMENT ACCEPTED", res.data)
                    })
                    .catch(err => {
                        console.log("AGREEMENT ACCEPTED ERROR", err)
                    })
            }
        }
    }

    return (
        <>
            <Box
                sx={{
                    //backgroundColor: loading ? '#f2f2f2' : '#fff',
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '10px',

                    "&:hover": {
                        backgroundColor: '#f5f5f5',
                        cursor: 'pointer'
                    }
                }}
                onClick={() => {
                    setOpen(true)
                    // console.log('IntegrationField Clicked');
                    // window.location.href = "https://link.tink.com/1.0/business-transactions/connect-accounts/?client_id=81e0a33a360240e084a6b5b4ef7bdaae&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fintegration%2Fbank-account%2Fcallback&market=AT&locale=de_DE";
                }}
            >
                <Typography variant="h2">
                    Connect with your Bank {demo ? "(DEMO)" : ""}
                </Typography>
                <Typography variant="body1">Click here to connect your bank account</Typography>
            </Box>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={open}
            >
                <DialogTitle>Connect your bank account</DialogTitle>
                <DialogContent>
                    {step === 0 && (
                        <Box>
                            <SearchCountryInstituteField
                                type={"Country"}
                                callback={(search) => handleSearchChange(search, "Country")}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    marginTop: '20px'
                                }}
                            >
                                {countries.filter(cntry => {
                                    if (search.type === 'Country' && cntry.label) {
                                        return cntry.label.toLowerCase().includes(search.value.toLowerCase())
                                    }
                                    return true
                                }).map((country, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                flex: '1 0 30%',
                                            }}
                                        >
                                            <Button
                                                fullWidth={true}
                                                key={`country-${index}`}
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<span className={classNames("fi", `fi-${country.value.toLowerCase()}`)}></span>}
                                                onClick={() => handleCountryClick(country.value)}
                                                endIcon={<KeyboardArrowRightIcon />}
                                            >
                                                {country.label}
                                            </Button>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}

                    {step === 1 && (
                        <Box>
                            <SearchCountryInstituteField
                                type={"Institute"}
                                callback={(search) => handleSearchChange(search, "Institute")}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    marginTop: '20px'
                                }}
                            >
                                {filteredInstitute.filter(institute => {
                                    if (search.type === 'Institute' && institute.name) {
                                        return institute.name.toLowerCase().includes(search.value.toLowerCase())
                                    }
                                    return true
                                }).map((institute, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                flex: '1 0 49%',
                                            }}
                                        >
                                            <Button
                                                fullWidth={true}
                                                key={`country-${index}`}
                                                variant="outlined"
                                                color="primary"
                                                sx={{
                                                    '& img': {
                                                        width: '50px',
                                                        height: 'auto',
                                                        marginRight: '10px'
                                                    }
                                                }}
                                                startIcon={<img src={institute.logo} alt={institute.name} />}
                                                onClick={() => handleInstituteClick(institute)}
                                                endIcon={<KeyboardArrowRightIcon />}
                                            >
                                                {institute.name}
                                            </Button>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}

                    {step === 2 && (
                        <Box>
                            <h1>CyronAI uses GoCardless to connecto to your Bank account.</h1>
                            <p>CyronAI will have access to the following data:</p>
                            <ul>
                                <li>Account details</li>
                                <li>Your account balances</li>
                                <li>Your transaction history for the last 90 days
                                    Access to your data will expire after 180 days.</li>
                            </ul>

                            <Collapse in={false} >
                                <p>
                                    GoCardless requires access to your account information to share it with the merchant, enabling them to provide their service to you. GoCardless may retrieve this information multiple times a day to ensure the most up-to-date data is shared with the merchant. This may result in more than four accesses to your account data within 24 hours.

                                    As part of our service, GoCardless may also refine payment account information and use it to improve and develop the products and services we offer to you and merchants. This includes analytics, fraud prevention, and other valuable services.
                                </p>
                            </Collapse>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAgreementAccept}
                            >
                                Connect
                            </Button>
                        </Box>
                    )}

                    {step === 3 && (
                        <Box>
                            <h1>Success</h1>
                            <p>Your bank account has been successfully connected.</p>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={setClose}
                            >Close</Button>
                        </Box>
                    )}

                </DialogContent>
            </Dialog>
        </>
    )
}

export default GoCardlessModal
