import React, { ReactNode, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Paper, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { initOnboardingError, OnboardingStateErrorType } from '../../state/reducer/onboardingSlice'

interface ChatMessageQuestionInputProps {
  question: string;
  value?: string;
  showButton?: boolean;
  inputNode?: ReactNode;
  loading?: boolean;
  loadingText?: string;
  error?: OnboardingStateErrorType;
  onSend: (message: string) => void;
}

const ChatMessageQuestionInput: React.FC<ChatMessageQuestionInputProps> = (props) => {
  const { question, value, inputNode, onSend, loadingText, error = initOnboardingError, showButton = true, loading = false } = props;
  const [message, setMessage] = useState('');

  const handleSend = () => {
      onSend(message);
  };

  useEffect(() => {
    if (value) {
      setMessage(value);
    }
  }, [value]);

  return (
    <Box
      sx={{
        backgroundColor: '#343434',
        padding: 2,
        borderRadius: '12px',
      }}
    >
      <Paper
        sx={{
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#343434',
        }}
        elevation={0}
      >
        {inputNode ?  inputNode :


        <TextField
          fullWidth
          error={error.active}
          helperText={error.active ? error.error : ''}
          variant="outlined"
          label={question}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSend();
            }
          }}
          sx={{
            marginRight: '8px',
            border: "none",
            input: {
              border: "none",
              color: '#E0E0E0' },
            '& fieldset': {
              border: "none",
            },
            '& label': { color: '#9d9d9d' },
            '& label.Mui-focused': {
              color: '#9d9d9d',
              borderColor: '#9d9d9d'
            },
          }}
        />

        }
        {showButton && (
          <Button
            color="primary"
            variant="contained"
            sx={{
              color: '#ffffff'
            }}
            disabled={loading}
            onClick={handleSend}
            endIcon={loading ? <SendIcon /> : <CircularProgress />}
          >
            {(loading && loadingText) ? loadingText : 'Submit'}
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default ChatMessageQuestionInput;