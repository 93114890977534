import React, { ReactNode, useEffect, useState } from 'react'
import { CSSObject, styled, Theme, useColorScheme, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import {
  Badge,
  Box, Button,
  Container,
  CssBaseline,
  Divider, FormControlLabel, FormGroup,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Logo from '../assets/image/CyronAILogo.png'
import { MainMenu } from '../components/MainMenu/MainMenu'
import { UserMenuAvatar } from '../components/UserMenuAvatar/UserMenuAvatar'
import { useAppSelector } from '../state/stateHooks'
import MoreIcon from '@mui/icons-material/MoreVert'
import SidebarChat from '../components/Chat/SidebarChat'
import ChatList from '../components/Chat/ChatList'
import MessageModal from '../components/Modal/MessageModel/Message.Modal'
import NotificationTab from '../components/Notification/NotificationTab'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../routes/routes'
import SettingsIcon from '@mui/icons-material/Settings';
import UserMenu from '../components/Modal/UserMenu/UserMenu'
import MUISwitch from '../components/Icons/MUISwitch'
import { ColorModeContext } from '../index'
import DataViewDrawer from '../components/Drawer/DataViewDrawer'
import instance from '../utils/api'
import classNames from 'classnames'



interface LeftMenuRightChatLayoutProps {
  className?: any,
  header?: ReactNode,
  title?: string,
  sidebar?: ReactNode,
  container?: boolean
  children: ReactNode
  chatIntegrated?: boolean
}

const drawerWidth = 260;
const chatDrawerWidth = 400;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const openChatMixin = (theme: Theme): CSSObject => ({
  width: chatDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,

  "& .logo": {
    height: '30px',
    marginLeft: '16px',

    "& img": {
      height: '100%',
      width: 'auto'
    }
  }
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface ElevationScrollProps {
  children: React.ReactElement<{ elevation?: number }>;
}

const ElevationScroll: React.FC<ElevationScrollProps> = (props) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: '#fff',
  borderBottom: '1px solid #e0e0e0',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export const RightSidebarDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    paddingTop: '64px',
    marginRight: '20px',
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
    },
    width: chatDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openChatMixin(theme),
          '& .MuiDrawer-paper': openChatMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);


const LeftMenuRightChatLayout: React.FC<LeftMenuRightChatLayoutProps> = (props) => {
  const data = useAppSelector((state) => state.onboardingReducer);
  const userData = useAppSelector((state) => state.userReducer);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openRight, setOpenRight] = useState(true);

  const colorMode = React.useContext(ColorModeContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{
      backgroundColor: '#f1f1f1',
      minHeight: '100vh',
      display: 'flex'
    }}>
      <CssBaseline />
      <ElevationScroll>
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{
            "& .logo": {
              height: '30px',
              marginLeft: '0px',
              marginRight: '36px',
              display: open ? 'none' : 'block',

              "& img": {
                height: '100%',
                width: 'auto'
              }
            }
          }}>
            {/*<div className="header">
              <div className="logo">
                <img src={LogoSmall} alt="CyronAI Logo" />
              </div>
            </div>*/}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[
                {
                  marginRight: 5,
                },
                open && { display: 'none' },
              ]}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {props.title ? props.title : ""}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <MUISwitch
                  sx={{ m: 1 }}
                  onChange={(event) => {
                    colorMode.toggleColorMode();
                  }}
              />

              <MessageModal />
              <NotificationTab />

              <IconButton
                  component={NavLink}
                  to={ROUTES.SETTINGS}
                  className={classNames({active: window.location.pathname === ROUTES.SETTINGS})}
                  sx={{
                    borderRadius: '5px',

                    '&.active': {
                      color: '#000',
                      background: '#eee',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      borderRadius: '5px'
                    }
                  }}
              >
                <SettingsIcon />
              </IconButton>

              <UserMenu />
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>

          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer
        sx={{
          flexShrink: 0,
          marginRight: '20px',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        variant="permanent" open={open}>
        <DrawerHeader>
          <div className="header">
            <div className="logo">
              <img src={Logo} alt="CyronAI Logo" />
            </div>
          </div>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          height: '100%',
          justifyContent: 'space-between',
          padding: '0 10px'
        }}>
          <Box>
            <MainMenu
              open={open}
            />

            <Divider />

            <ChatList />
          </Box>

          <UserMenuAvatar userData={{
            name: userData.firstName + ' ' + userData.lastName,
            email: userData.email || ""
          }} />
        </Box>
      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1,
      }}>
        <DrawerHeader />
        <Box
          sx={{
            marginTop: "20px"
          }}
        >
          {props.container ? <Container
            maxWidth={'xl'}
            sx={{
              minWidth: '250px',
            }}
          >{props.children}</Container> : <>{props.children}</>}
        </Box>
      </Box>

      {props.chatIntegrated ?
        <RightSidebarDrawer
          anchor="right"
          variant="permanent" open={openRight}>
        <DrawerHeader />

        <Box sx={{
          marginTop: "20px"
        }}>
          <Typography variant="h4">
            Chat Title
          </Typography>

          <Divider />

          <SidebarChat />
        </Box>
      </RightSidebarDrawer>
          : <DataViewDrawer />}

    </Box>
  );
};

export default LeftMenuRightChatLayout;
