import { createSlice } from '@reduxjs/toolkit'
import { IFullFirm } from '../../interface/IFirm'

export interface OnboardingStateErrorType {
    active: boolean,
    error: string,
    type: string,
    code: string | number
}

const error ={
    active: false,
    error: "",
    type: "",
    code: "",
} as OnboardingStateErrorType;

const initialState = {
    admin:
      {
        id: -1,
        authId: "",
        firstName: "",
        lastName: "",
        email: "",
        birthDate: '',
        phone: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        tenantId: '',
      },
    adminIsSet: false,
    companyData: {
        id: "",
        isin: "",
        lei: "",
        bvdId: "",
        name: "",
        ticker: "",
        description: "",
        industry: "",
        street: "",
        city: "",
        zip: "",
        country: "",
        state: "",
        phone: "",
        webUrl: "",
        logoUrl: "",
        delisted: false,
    },
    error: error,
    companyIsSet: false,
    activeStep: 0,
    stepIsLoading: false,
    skipped: new Set<number>(),
    tenantId: "",
    tenantIsSet: false,
    tenantName: "",
    tenantDomain: "",
    finishedOnbarding: false,
    loadingOnboardingData: false,
}

const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        setLoadingOnboardingData: (state, {payload}) => {
            state.loadingOnboardingData = payload;

            return state;
        },
        setAdminInfos: (state, {payload}) => {
            console.log("PAYLOAD", payload)

            state.admin.authId = payload.authId;
            state.admin.firstName = payload.firstName;
            state.admin.lastName = payload.lastName;
            state.admin.email = payload.email;
            /*state.admin.birthDate = payload.birthDate;
            state.admin.phone = payload.phone;
            state.admin.address = payload.address;
            state.admin.zipCode = payload.zipCode;
            state.admin.city = payload.city;
            state.admin.country = payload.country;*/

            return state;
        },
        setAdminInfosSaved: (state, {payload}) => {
            state.adminIsSet = true;
            state.admin.id = payload.id;

            return state;
        },
        setCompanyInfos: (state, {payload} : {payload: IFullFirm}) => {
            state.companyData.id = payload.id;
            state.companyData.isin = payload.isin;
            state.companyData.bvdId = payload.bvdId;
            state.companyData.name = payload.name;
            state.companyData.street = payload.street;
            state.companyData.city = payload.city;
            state.companyData.zip = payload.zip;
            state.companyData.country = payload.country;
            state.companyData.phone = payload.phone;
            state.companyData.webUrl = payload.webUrl;
            state.companyData.logoUrl = payload.logoUrl;
            state.companyData.ticker = payload.ticker;
            state.companyData.description = payload.description;
            state.companyData.industry = payload.industry;
            state.companyData.state = payload.state;
            state.companyData.lei = payload.lei;
            state.companyData.delisted = payload.isDelisted;

            return state;
        },
        setCompanyInfosSaved: (state, {payload}) => {
            state.companyIsSet = true;
            state.companyData.id = payload.id;

            return state;
        },
        setTenantFormData: (state, {payload}) => {
            console.log("PAYLOAD", payload)

            if (payload.tenantName) {
                state.tenantName = payload.tenantName;
            }
            if (payload.tenantDomain) {
                state.tenantDomain = payload.tenantDomain;
            }

            return state;
        },
        setTenantInfos: (state, {payload}) => {
            state.tenantId = payload.tenantId;
            state.tenantName = payload.name;
            state.tenantDomain = payload.tenantDomain;

            state.tenantIsSet = true;

            return state;
        },
        setActiveStep: (state, {payload}) => {
            console.log("PAYLOAD", payload)
            state.activeStep = payload

            return state;
        },
        setFinishedOnboarding: (state) => {
            state.finishedOnbarding = true;

            return state;
        },
        setError: (state, payload: { payload: OnboardingStateErrorType }) => {
            if (payload.payload.active) {
                state.error = payload.payload;
            } else {
                state.error = error;
            }
            return state;
        },
        toggleStepLoading: (state, {payload}) => {
            state.stepIsLoading = payload;

            return state;
        }
    }
})
const onboardingReducer = onboardingSlice.reducer;

export const {setLoadingOnboardingData, setAdminInfos, setCompanyInfos, setCompanyInfosSaved, setAdminInfosSaved, setTenantFormData, setTenantInfos, setActiveStep, setFinishedOnboarding, setError, toggleStepLoading} = onboardingSlice.actions;
export default onboardingReducer;
export const initOnboardingError = error;
