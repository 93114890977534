import React from 'react'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { Box, Button } from '@mui/material'
import { NavLink, Outlet, Route, Routes } from 'react-router-dom'
import * as ROUTES from '../../routes/routes'
import IntegrationPage from './integration/Integration.Page'

const SettingsPage = () => {
    return (
        <LeftMenuRightChatLayout
            container={true}
            chatIntegrated={true}
            title={"Overview"}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
                    pb: 1,
                    mb: 3,

                    '& a': {
                        textDecoration: 'none',
                        color: '#000',
                        padding: '10px 20px',

                        '&:hover, &:active, &:focus, &.active': {
                            color: '#000',
                            background: 'rgba(0, 0, 0, 0.05)',
                            textDecoration: 'none'
                        }
                    }
                }}
            >
                <NavLink
                    to={ROUTES.SETTINGS}
                    className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}
                    >General</NavLink>
                <NavLink
                    to={ROUTES.SETTINGS_INTEGRATION}
                    className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}
                >Integration</NavLink>
                {/*<NavLink
                    to={ROUTES.SETTINGS}
                    className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}
                >General</NavLink>
                <NavLink
                    to={ROUTES.SETTINGS}
                    className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}
                >General</NavLink>*/}
            </Box>

            <Outlet />
        </LeftMenuRightChatLayout>
    )
}

export default SettingsPage