import React, { FC, useEffect, useState } from 'react'
import { IFirm } from '../../interface/IFirm'
import instance from '../../utils/api'
import { Autocomplete, Button, CircularProgress, TextField } from '@mui/material'
import CompanyCreateModal from '../Modal/CompanyCreateModal/CompanyCreate.Modal'


type FirmSearchAutocompleteProps = {
  setFirmInformation?: (firm: IFirm) => void;
  value?: IFirm;
  setCompanyNotFoundAndCreate: () => void;
}
const FirmSearchAutocomplete: FC<FirmSearchAutocompleteProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly IFirm[]>([]);
  const [searchValue, setSearchValue] = useState<IFirm>({id: '', name: '', description: ''});
  const [nothingFound, setNothingFound] = useState(false);
  const [value, setValue] = useState<IFirm | null>(null);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (searchValue.name === '') {
      return undefined;
    }
    console.log('Search value:', searchValue);

    const timer = setTimeout(() => {
        instance.get("/api/v1/firm", {
            params: {
                "s": searchValue.name,
                "demo": true
            }
        }).then((response) => {
            if (response.data.length === 0) {
                setNothingFound(true);
                setOpen(false);
            } else {
                setNothingFound(false);
            }
            setOptions(response.data.map((data: any) => {
                return {
                    id: data.id,
                    name: data.name,
                    description: ''
                }
            }));
        })
        .catch((error) => {
            console.error('Error fetching firms:', error);
        })
    }, 500);

    return () => {
        clearTimeout(timer)
    }


  }, [searchValue.name]);

  useEffect(() => {
    if (props.value && props.value.name !== "") {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Autocomplete
      id={"company-search"}
      sx={{
        width: '100%',
        marginRight: '8px',
        border: "none",
        input: {
          border: "none",
          color: '#E0E0E0' },
        '& fieldset': {
          border: "none",
        },
        '& label': { color: '#9d9d9d' },
        '& label.Mui-focused': {
          color: '#9d9d9d',
          borderColor: '#9d9d9d'
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onInputChange={(event, value) => {
        setSearchValue({ name: value, id: '', description: '' });
      }}
      inputValue={searchValue.name}
      onChange={(event, value) => {
        setValue(value);

        if (props.setFirmInformation && value) {
          props.setFirmInformation(value);
        } else if (props.setFirmInformation) {
          props.setFirmInformation({id: '', name: '', description: ''});
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for your company"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> :
                    <>
                        {nothingFound ? <Button
                            onClick={props.setCompanyNotFoundAndCreate}
                        >Create Company</Button> : ''}
                    </>
                }
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FirmSearchAutocomplete;
