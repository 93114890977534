import * as React from 'react'

interface IFilterIcon {

}

const FilterIcon : React.FC<IFilterIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16.402}
        height={16.517}
        viewBox="0 0 16.402 16.517"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: none;\n        stroke: #000;\n        stroke-linecap: round;\n        stroke-linejoin: round;\n        stroke-width: 1.5px;\n      }\n    "
                }
            </style>
        </defs>
        <path
            id="Pfad_619"
            data-name="Pfad 619"
            className="cls-1"
            d="M1387.38,110.422a26.6,26.6,0,0,1-4.36-4.215,1.493,1.493,0,0,1-.38-.9c-.17-1.31-.25-1.965.13-2.388s1.07-.423,2.43-.423h9.6c1.36,0,2.04,0,2.43.423s.3,1.078.13,2.388a1.493,1.493,0,0,1-.38.9,26.322,26.322,0,0,1-4.37,4.221.852.852,0,0,0-.33.623c-.21,2.276-.4,3.522-.52,4.152-.19,1.018-1.63,1.631-2.4,2.177a.711.711,0,0,1-1.08-.565c-.12-.96-.33-2.91-.56-5.764A.873.873,0,0,0,1387.38,110.422Z"
            transform="translate(-1381.799 -101.75)"
        />
    </svg>
);
export default FilterIcon;
