import React from 'react'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const MetricSection = styled(Box)(({ theme }) => ({
    padding: "20px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    "&:last-child": {
        borderBottom: "none"
    },
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.05)"
    }
}));

const MetricHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px"
});

const PercentageDisplay = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px"
});


interface MetricDataBoxProps {
    metric: {
        title: string,
        trending: string,
        percentage: string,
        value: string,
        subtext: string
    }
}

const MetricDataBox: React.FC<MetricDataBoxProps> = ({ metric }) => {

    const renderArrow = (trending: string) => {
        if (trending === "up") {
            return <ArrowCircleRightIcon />;
        }
        return <ArrowCircleLeftIcon />;
    };

    return (
        <MetricSection role="article" aria-label={metric.title}>
            <MetricHeader>
                <Typography variant="h6" component="h2">
                    {metric.title}
                </Typography>
                <MonetizationOnIcon />
            </MetricHeader>
            <PercentageDisplay>
                {renderArrow(metric.trending)}
                <Typography
                    variant="subtitle1"
                    color={metric.trending === "up" ? "#4caf50" : "#ffc107"}
                >
                    {metric.percentage}
                </Typography>
            </PercentageDisplay>
            <Typography variant="h3" sx={{ my: 1, fontWeight: "bold" }}>
                {metric.value}
            </Typography>
            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
                {metric.subtext}
            </Typography>
        </MetricSection>
    )
}

export default MetricDataBox