import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton, Link,
  List,
  ListItem, ListItemButton, ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { downloadFile, fetchAllReports, fetchFiles } from '../../utils/api/file'
import { FileProps } from '../../interface/IFile'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { useSnackbar } from 'notistack'
import { ReportProps } from '../../interface/ReportProps'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NavLink } from 'react-router-dom'
import instance from '../../utils/api'


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  transition: "box-shadow 0.3s",
  /*"&:hover": {
    boxShadow: theme.shadows[4],
  },*/
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: "transform 0.2s",
  background: '#000',
  color: '#fff',
  "&:hover": {
    transform: "scale(1.05)",
  },
}));
const ReportPage = () => {
  const [reports, setReports] = useState<ReportProps[]>([])

  useEffect(() => {
    fetchAllReports()
      .then((response) => {
        if (response.data) {
          setReports(response.data);
        }
      })
  }, []);


  /*const [reports, setReports] = useState([
    { id: 1, name: "Sales Report Q1", date: "2023-03-31", status: "Completed" },
    { id: 2, name: "User Activity Report", date: "2023-04-15", status: "Completed" },
    { id: 3, name: "Inventory Status", date: "2023-05-01", status: "In Progress" },
  ]);*/

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateReport = (reportType : string) => {
    setLoading(true);

    instance.get('/api/v1/file/report/generate')
        .then(response => {
            console.log(response)
            setLoading(false)

          fetchAllReports()
              .then((response) => {
                if (response.data) {
                  setReports(response.data);
                }
              })

            enqueueSnackbar(`${reportType} Report generated successfully`, {
                anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
                }
            });
        })


    /*setTimeout(() => {
      const newReport = {
        id: reports.length + 1,
        name: `${reportType} Report`,
        date: new Date().toISOString().split("T")[0],
        status: "Completed",
      };
      //setReports([...reports, newReport]);
      setLoading(false);
      enqueueSnackbar(`${reportType} Report generated successfully`, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        }
      });
    }, 2000);*/
  };

  const handleDeleteReport = (id : number) => {
    setReports(reports.filter((report) => report.id !== id));
    enqueueSnackbar("Report deleted successfully", {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }
    });
  };
  const handleDownloadReport = async (id : number) => {
    const doc = reports.find((report) => report.id === id);
    if (doc && doc.filePath && doc.fileName) {
      const resp = await downloadFile(doc.filePath, doc.fileName)
      //console.log(resp);
    }
  };


  return (
    <LeftMenuRightChatLayout
      container={true}
      chatIntegrated={true}
      title={"Reports"}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Report Manager</Typography>
          </Grid>
        </Grid>
      </Box>

      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>
          Generate New Report
        </Typography>
        <Grid container spacing={2}>
          {["Bank Report", "Monthly Financial Report", "Annual Financial Report"].map((type) => (
              <Grid item xs={12} sm={6} md={4} key={type}>
                <StyledButton
                    variant="contained"
                    color="primary"
                    //startIcon={<FaFileAlt />}
                    fullWidth
                    onClick={() => handleGenerateReport(type)}
                    disabled={loading}
                >
                  {type}
                </StyledButton>
              </Grid>
          ))}
        </Grid>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>
          Existing Reports
        </Typography>
        <List>
          {reports.map((report) => (
            <ListItem
              key={report.id}
              secondaryAction={
                <Box>
                  <IconButton
                    component={NavLink}
                    to={`/report/${report.reportId}`}
                    edge="end"
                    aria-label="view"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownloadReport(report.id)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteReport(report.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText
                primary={report.title || "Untitled Report"}
                secondary={`Date: ${report.createdAt} | Status: Completed`}
              />
            </ListItem>
          ))}
        </List>
      </StyledPaper>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CircularProgress />
        </Box>
      )}

    </LeftMenuRightChatLayout>
  );
};

export default ReportPage;