import React, { ReactNode, useState } from 'react'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../../routes/routes'
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import ChatIcon from '@mui/icons-material/Chat'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CalculateIcon from '@mui/icons-material/Calculate'
import AssessmentIcon from '@mui/icons-material/Assessment'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { MARKET_OVERVIEW } from '../../routes/routes'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const LinkStyle = {
    color: '#000',
    padding: '0',
    borderRadius: '10px',
    marginBottom: '5px',
    marginTop: '5px',
    // marginLeft: '8px',
    // marginRight: '8px',
    // width: '94%',
    '.MuiListItemIcon-root': {
        color: '#000',
    },
    '&:hover': {
        color: '#fff',
        background: '#000',
      '.MuiListItemIcon-root': {
        color: '#fff',
      },
    },
    '&:active': {
        color: '#fff',
        background: '#000',
      '.MuiListItemIcon-root': {
        color: '#fff',
      },
    },
    '&.active': {
        color: '#fff',
        background: '#000',
      '.MuiListItemIcon-root': {
        color: '#fff',
      },
    },
    '&:focus': {
        color: '#fff',
        background: '#000',
      '.MuiListItemIcon-root': {
        color: '#fff',
      },
    }
}

const LinkIconStyle = {
    minWidth: '30px'
}

interface MainMenuProps {
    open?: boolean
}

interface LinkMenuProps {
    open?: boolean,
    chatIcon?: ReactNode,
    text: string
}
const LinkMenu: React.FC<LinkMenuProps> = ({ text, chatIcon, open = true }) => (
  <ListItemButton
    sx={[
        {
            minHeight: 48,
            px: 2.5,
          'span': {
            fontSize: '14px',
          }
        },
        open
          ? {
              justifyContent: 'initial',
          }
          : {
              justifyContent: 'center',
          },
    ]}
  >
      <ListItemIcon
        sx={[
            {
                minWidth: "30px",
                justifyContent: 'center',
            },
            open
              ? {
                  mr: 3,
              }
              : {
                  mr: 'auto',
              },
        ]}
      >
        {chatIcon ? chatIcon : <ChatIcon />}
      </ListItemIcon>
      <ListItemText
        sx={[
            open
              ? {
                  opacity: 1,
              }
              : {
                  opacity: 0,
              },
        ]}
        primary={text} />
  </ListItemButton>
)


export const MainMenu: React.FC<MainMenuProps> = ({ open = true }) => {
    const [subOpen, setSubOpen] = useState(false);

    const handleClick = () => {
        setSubOpen(!subOpen);
    }

    return (
        <div className="main_menu">
            <List>
                <ListItem component={NavLink} to={ROUTES.CHAT} sx={LinkStyle}>
                    <LinkMenu
                      open={open}
                      text={"Cyron"}
                      chatIcon={<ChatIcon />}
                    />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.HOME} sx={LinkStyle}>
                    <LinkMenu
                      open={open}
                      text={"Overview"}
                      chatIcon={<SpaceDashboardIcon />}
                    />
                </ListItem>
                <ListItemButton onClick={handleClick} sx={LinkStyle}>
                    <LinkMenu
                        open={open}
                        text={"Budget & Finance"}
                        chatIcon={<AccountBalanceIcon />}
                    />
                    {subOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={subOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{
                        background: '#f5f5f5',
                    }}>
                        <ListItem component={NavLink} to={ROUTES.BUDGET_FINANCE_OVERVIEW} sx={LinkStyle} >
                            <LinkMenu
                                open={open}
                                text={"Overview"}
                                chatIcon={<SubdirectoryArrowRightIcon />}
                            />
                        </ListItem>
                        <ListItem component={NavLink} to={ROUTES.BUDGET_FINANCE_CREATE} sx={LinkStyle} >
                            <LinkMenu
                                open={open}
                                text={"Create new Budget"}
                                chatIcon={<SubdirectoryArrowRightIcon />}
                            />
                        </ListItem>
                    </List>
                </Collapse>

              <ListItem component={NavLink} to={ROUTES.CASH_FLOW_OVERVIEW} sx={LinkStyle}>
                <LinkMenu
                  open={open}
                  text={"CashFlow Management"}
                  chatIcon={<AttachMoneyIcon />}
                />
              </ListItem>
              <ListItem component={NavLink} to={ROUTES.TAX_OVERVIEW} sx={LinkStyle}>
                <LinkMenu
                  open={open}
                  text={"Tax Management"}
                  chatIcon={<CalculateIcon />}
                />
              </ListItem>
              <ListItem component={NavLink} to={ROUTES.REPORT_OVERVIEW} sx={LinkStyle}>
                <LinkMenu
                  open={open}
                  text={"Report Management"}
                  chatIcon={<AssessmentIcon />}
                />
              </ListItem>
              <ListItem component={NavLink} to={ROUTES.MARKET_OVERVIEW} sx={LinkStyle}>
                <LinkMenu
                  open={open}
                  text={"Market Analysis"}
                  chatIcon={<EmojiTransportationIcon />}
                />
              </ListItem>

                <ListItem component={NavLink} to={"http://localhost:9000/"} sx={LinkStyle}>
                    <LinkMenu
                        open={open}
                        text={"Automation"}
                        chatIcon={<SettingsSuggestIcon />}
                    />
                </ListItem>
                {/*<ListItem component={NavLink} to={ROUTES.BALANCE} sx={LinkStyle}>
                    <LinkMenu
                      open={open}
                      text={"Balance"}
                    />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.REPORTS} sx={LinkStyle}>
                    <LinkMenu
                      open={open}
                      text={"Reports"}
                    />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.MESSAGES} sx={LinkStyle}>
                    <LinkMenu open={open} text={"Messages"} />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.FILES} sx={LinkStyle}>
                    <LinkMenu open={open} text={"Files"} />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.ACCOUNT_SETUP} sx={LinkStyle}>
                    <LinkMenu open={open} text={"Account Setup"} />
                </ListItem>
                <ListItem component={NavLink} to={ROUTES.INTEGRATION} sx={LinkStyle}>
                    <LinkMenu open={open} text={"Integration"} />
                </ListItem>*/}
            </List>
        </div>
    );
};
