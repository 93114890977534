import instance from './index'
import { AdminProps, AdminUpdateInput } from '../../interface/IAdmin'

const fetchAdminData = async (userId: string) : Promise<AdminProps | undefined> => {
  const userData = await instance.get<AdminProps>(`/api/v1/instance/user/auth/${userId}`)

  if (userData && userData.data) {
    return userData.data;
  }

  return undefined;
}

const updateAdminData = async (userId: number, data: AdminUpdateInput) : Promise<AdminProps | undefined> => {
  const userData = await instance.put(`/api/v1/instance/user/${userId}`, data)

  if (userData && userData.data) {
    return userData.data;
  }

  return undefined;
}


export {
  fetchAdminData,
  updateAdminData
}
