import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './state/reducer'
import { axiosMiddleware } from './utils/api/middleware'

const currentStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(axiosMiddleware.middleware),
})


export const store = currentStore;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof currentStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof currentStore.dispatch

export type AppStore = ReturnType<typeof rootReducer>