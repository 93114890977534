import React from 'react'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { DashboardCard } from '../../components/Cards'
import MetricDataBox from '../../components/DataView/MetricDataBox'

import revenueTransactionExamples from '../../assets/test/revenue_transaction_example.json';
import LineChart from '../../components/Chart/LineChart'
import moment from 'moment'

const metricsExamples = [
  {
    title: "Revenue",
    value: "€86,044",
    percentage: "160%",
    subtext: "From €175.26",
    trending: "up"
  },
  {
    title: "Transactions",
    value: "132",
    percentage: "160%",
    subtext: "From 8 months",
    trending: "up"
  },
  {
    title: "Avg. Transactions",
    value: "€6,144",
    percentage: "60%",
    subtext: "From €29.21",
    trending: "down"
  }
];


const goalsExamples = [
    {
        title: "Achieve a 20% Annual Revenue Growth",
        subtitle: "Increase year-over-year revenue by 20%.",
        description: "Set a target to drive top-line growth by expanding product offerings, entering new markets, or improving sales strategies. This revenue growth can indicate strong business health and provide resources for future investments.",
        progress: 0.4
    },
    {
        title: "Improve Profit Margins by 5%",
        subtitle: "Boost profit margins by reducing costs and optimizing operations.",
        description: "Focus on cost control strategies, such as negotiating with suppliers, optimizing production processes, and minimizing overhead costs. By improving profit margins, the company can retain more income from each dollar earned, which is crucial for scalability and reinvestment." ,
        progress: 0.2
    },
    {
        title: "Increase Cash Flow by 25%",
        subtitle: "Strengthen cash flow by improving collection processes and managing expenses.",
        description: "Set up better collection methods to reduce payment delays and create a cash reserve for smoother financial operations. Enhanced cash flow will ensure adequate resources for operational and expansion needs, especially during slower revenue periods." ,
        progress: 0.6
    },
    {
        title: "Reduce Debt-to-Equity Ratio by 15%",
        subtitle: "Improve financial stability by lowering reliance on debt.",
        description: "Focus on paying down existing debt and increasing retained earnings to improve the debt-to-equity ratio. A lower ratio signifies a healthier balance sheet, increasing financial stability and attractiveness to investors." ,
        progress: 0.8
    },
    {
        title: "Establish a 6-Month Operating Cash Reserve",
        subtitle: "Build a cash reserve to cover six months of operational expenses.",
        description: "Create a financial buffer to safeguard against economic uncertainties or unexpected expenses. A 6-month reserve can help maintain smooth operations during downturns and enable you to make strategic decisions without immediate cash flow pressure." ,
        progress: 0.3
    }
]

const BudgetAndFinancePage = () => {

  const preData = revenueTransactionExamples
        .map((transaction: any) => ({
          ...transaction,
            Date: moment(transaction.Date)
        }))
        //Group by week
        .reduce((acc: any, transaction: any) => {
            const week = transaction.Date.week();
            if (!acc[week]) {
                acc[week] = [];
            }
            acc[week].push(transaction);

            return acc;
        }, {});

  const data = Object.keys(preData)
      //Aggregate by week
      .map((week: any) => {
          const revenue = preData[week].reduce((acc: number, transaction: any) => acc + transaction.Revenue, 0);
          return {
              Date: preData[week][0].Date,
              Revenue: revenue
          }
      })
      .sort((a: any, b: any) => a.Date - b.Date)
      .map((transaction: any) => [transaction.Date.valueOf() ,transaction.Revenue]);

  console.log("EXAMPLE DATA", data);

  return (
    <LeftMenuRightChatLayout
        container={true}
        chatIntegrated={true}
        title={"Budget & Finance"}
    >
      <Box
          className="main-container">

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DashboardCard>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {metricsExamples.map((metric, index) => (
                      <MetricDataBox key={index} metric={metric} />
                  ))}
                </Grid>

                <LineChart
                    options={{
                      chart: {
                        type: 'line',
                        backgroundColor: 'transparent',
                      },
                      credits: {
                        enabled: false
                      },
                      title: {
                        text: 'Revenue'
                      },
                      xAxis: {
                        type: 'datetime'
                      },
                      legend: {
                        enabled: false
                      },
                      plotOptions: {
                        series: {
                          marker: {
                            enabled: false
                          },
                          label: {
                            connectorAllowed: false
                          }
                        }
                      },
                      series: [{
                        type: 'line',
                        name: 'Revenue',
                        data: data
                      }]
                    }}
                  />

              </Grid>
            </DashboardCard>
          </Grid>

            <Grid item xs={6}>
                <DashboardCard>
                    <Typography variant="h5">Company Goals</Typography>
                    {goalsExamples.map((goal, index) => (
                        <Box key={index} sx={{
                            my: 2,
                            textAlign: "left",
                        }}>
                            <Box
                                sx={{
                                    position: "relative"
                                }}
                            >
                                <LinearProgress
                                    sx={{
                                        height: 20
                                    }}
                                    variant="determinate"
                                    value={goal.progress * 100} />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        right: '20px',
                                        top: 0,
                                        "& p": {
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            color: goal.progress < 0.9 ? 'black' : 'white'
                                        }
                                    }}>
                                    <Typography variant="body1">{`${Math.round(goal.progress * 100)}%`}</Typography>
                                </Box>
                            </Box>
                            <Typography variant="h6">{goal.title}</Typography>
                            <Typography variant="body2">{goal.subtitle}</Typography>
                            {/*<Typography variant="body1">{goal.description}</Typography>*/}
                        </Box>
                    ))}
                </DashboardCard>
            </Grid>
        </Grid>
      </Box>
    </LeftMenuRightChatLayout>
  );
};

export default BudgetAndFinancePage;