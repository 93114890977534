import instance from './index'


const loadAllUnreadNotifications = async () => {
  return instance('/api/v1/notification');
}


export {
    loadAllUnreadNotifications
}