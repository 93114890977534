import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    styled,
    TextField,
    Typography
} from '@mui/material'
import { CloudUpload, GetApp, Search, Share, Sort, Visibility } from '@mui/icons-material'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { fetchFiles } from '../../utils/api/file'
import { FileProps } from '../../interface/IFile'
import Moment from 'react-moment'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'

const FilesPage = () => {

  const [files, setFiles] = useState<FileProps[]>([]);

  useEffect(() => {
    fetchFiles("UPLOAD")
      .then((response) => {
        if (response.data) {
          setFiles(response.data);
        }
      })
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("name");

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handle file upload logic here
    //console.log("File uploaded:", event.target.files[0]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (criteria : string) => {
    setSortBy(criteria);
    // Implement sorting logic here
  };

  const handlePreview = (file : string) => {
    // Implement file preview logic here
    console.log("Preview file:", file);
  };

  const handleDownload = (file : string) => {
    // Implement file download logic here
    console.log("Download file:", file);
  };

  const handleShare = (file: string) => {
    // Implement file sharing logic here
    console.log("Share file:", file);
  };

  const FileIcon = (props: any) => {
    switch (props.type) {
      case "pdf":
        //return <FaFilePdf size={24} color="#e53935" />;
        return <PictureAsPdfIcon sx={{
          color: '#e53935',
          fontSize: '24px'
        }} />;
      case "image":
        //return <FaImage size={24} color="#43a047" />;
        return <p>"image"</p>;
      case "csv":
        //return <FaFileCsv size={24} color="#fbc02d" />;
        return <PivotTableChartIcon sx={{
          color: '#e53935',
          fontSize: '24px'
          }} />;
      default:
        //return <FaFileAlt size={24} color="#1e88e5" />;
        return <p>"file"</p>;
    }
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    transition: "transform 0.2s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: theme.shadows[4]
    }
  }));

  const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }));

  return (
    <LeftMenuLayout
      container={true}
      chatIntegrated={false}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>File Manager</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mb={3}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search files..."
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <UploadButton
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
        >
          Upload File
          <input type="file" hidden onChange={handleFileUpload} />
        </UploadButton>

        <Box>
          <Button
            startIcon={<Sort />}
            onClick={() => handleSort("name")}
            color={sortBy === "name" ? "primary" : "inherit"}
          >
            Name
          </Button>
          <Button
            startIcon={<Sort />}
            onClick={() => handleSort("type")}
            color={sortBy === "type" ? "primary" : "inherit"}
          >
            Type
          </Button>
          <Button
            startIcon={<Sort />}
            onClick={() => handleSort("size")}
            color={sortBy === "size" ? "primary" : "inherit"}
          >
            Size
          </Button>
          <Button
            startIcon={<Sort />}
            onClick={() => handleSort("date")}
            color={sortBy === "date" ? "primary" : "inherit"}
          >
            Date
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {files.map((file) => (
          <Grid item xs={12} sm={6} md={4} key={file.id}>
            <StyledCard>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <FileIcon type={"csv"} />
                  <Typography variant="h6" component="h2" ml={1}>
                    {file.documentName}
                  </Typography>
                </Box>
                <Typography color="textSecondary" gutterBottom>
                  Type: {file.detailType}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Upload Date: <Moment format={"DD/MM/YYYY"}>{file.createdAt}</Moment>
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <IconButton
                    aria-label="Preview file"
                    //onClick={() => handlePreview(file)}
                  >
                    <Visibility />
                  </IconButton>
                  <IconButton
                    aria-label="Download file"
                    //onClick={() => handleDownload(file)}
                  >
                    <GetApp />
                  </IconButton>
                  <IconButton
                    aria-label="Share file"
                    //onClick={() => handleShare(file)}
                  >
                    <Share />
                  </IconButton>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </LeftMenuLayout>
  );
};

export default FilesPage;