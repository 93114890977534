import React from 'react';
import {Avatar, Box, Grid, Typography} from "@mui/material";

interface UserMenuAvatarProps {
    userData: {
        name: string,
        email: string
    }
}


export const UserMenuAvatar: React.FC<UserMenuAvatarProps> = (props) => {
    return (
        <Box sx={{
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '30px'
        }}>
            <Grid container>
                <Grid item xs={3}>
                    <Avatar sx={{ bgcolor: '#000' }}>N</Avatar>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h6" sx={{
                        fontSize: '1rem',
                        textAlign: 'left',
                    }}>{props.userData.name}</Typography>
                    <Typography variant="body2" sx={{
                        fontSize: '0.7rem',
                        textAlign: 'left',
                        color: '#888E9E'
                    }}>{props.userData.email}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
