import React from 'react'
import { Box, Button, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { DashboardCard } from '../../components/Cards'
import VerifyAccountImage from '../..//assets/image/verifyAccountImage.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'

const AccountSetup = () => {
  return (
    <LeftMenuLayout
      container={true}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Account Setup</Typography>
            <Typography variant="body1" sx={{
              textAlign: 'left',
            }}>Get ready to use the full functionality of Cyron!</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DashboardCard
              backgroundColor={"#fff"}
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >

              {/** Cyron Score Box **/}
              <Box sx={{
                width: '100%',
              }}>
                <Grid container>
                  <Grid item xs={8}
                        sx={{
                          textAlign: 'left'
                        }}
                  >
                    <Typography
                      variant={"h4"}
                      sx={{
                        fontSize: '1.1rem',
                        color: '#000',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        marginBottom: '20px',
                        marginLeft: '10px'
                      }}
                    >Let's get verified your information</Typography>

                    <Typography
                      variant={"body1"}
                      sx={{
                        fontWeight: '100',
                        marginLeft: '10px'
                      }}
                    >
                      We require some additional details about you and your business to comply with regulations and begin processing your payments.
                    </Typography>

                    <List
                      sx={{
                        width: '100%',
                      }}
                      >
                      <ListItemButton>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Click on "Verify Account" to submit your details and start the verification process.'} />
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Stay updated on your progress through email'} />
                      </ListItemButton>
                    </List>

                    <Button
                      variant={"contained"}
                      sx={{
                        marginLeft: '10px',
                        marginTop: '20px',
                        backgroundColor: '#000',
                        color: '#fff',
                      }}
                      startIcon={<PrivacyTipIcon />}
                      >Verify your account</Button>

                  </Grid>
                  <Grid item xs={4}>
                    <img alt={"Verify Account Image"} src={VerifyAccountImage} />
                  </Grid>
                </Grid>
              </Box>
            </DashboardCard>
          </Grid>
        </Grid>
      </Box>
    </LeftMenuLayout>
  );
};

export default AccountSetup;