import * as React from 'react'

interface IDiscoverIcon {

}

const DiscoverIcon : React.FC<IDiscoverIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.167}
        height={18.167}
        viewBox="0 0 18.167 18.167"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1, .cls-2, .cls-3 {\n        fill: none;\n        stroke: #555f75;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 1.5px;\n      }\n\n      .cls-2, .cls-3 {\n        stroke-linecap: round;\n        stroke-linejoin: round;\n      }\n\n      .cls-3 {\n        stroke-width: 2px;\n        fill-rule: evenodd;\n      }\n    "
                }
            </style>
        </defs>
        <g id="discover-circle" transform="translate(0.75 0.75)">
            <g id="elements" transform="translate(-1.667 -1.667)">
                <path
                    id="Ellipse_1244"
                    data-name="Ellipse 1244"
                    className="cls-1"
                    d="M8.333,16.667a8.333,8.333,0,1,1,8.333-8.333A8.333,8.333,0,0,1,8.333,16.667Z"
                    transform="translate(1.667 1.667)"
                />
                <path
                    id="Vector"
                    className="cls-2"
                    d="M4.5,1.082,6.934.271C7.673.024,8.042-.1,8.237.1s.072.564-.174,1.3L7.252,3.832A5.834,5.834,0,0,1,6.171,6.171,5.834,5.834,0,0,1,3.832,7.252L1.4,8.063C.66,8.309.291,8.432.1,8.237s-.072-.564.174-1.3L1.082,4.5A5.834,5.834,0,0,1,2.162,2.162,5.834,5.834,0,0,1,4.5,1.082Z"
                    transform="translate(5.833 5.833)"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    className="cls-3"
                    d="M0,0H.007"
                    transform="matrix(-0.707, 0.707, -0.707, -0.707, 10, 10)"
                />
            </g>
        </g>
    </svg>
);
export default DiscoverIcon;
