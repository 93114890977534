import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ChatWindow from '../../components/Chat/ChatWindow'
import { useAppDispatch, useAppSelector } from '../../state/stateHooks'
import { useNavigate } from 'react-router-dom'
import {
    setActiveStep,
    setAdminInfos,
    setAdminInfosSaved,
    setCompanyInfos,
    setCompanyInfosSaved,
    setError,
    setFinishedOnboarding,
    setLoadingOnboardingData,
    setTenantFormData,
    setTenantInfos,
    toggleStepLoading
} from '../../state/reducer/onboardingSlice'
import { HOME } from '../../routes/routes'
import { fetchAdminData, updateAdminData } from '../../utils/api/admin'
import {
  fetchCompany,
  fetchTenant,
  fetchTenantByName, saveCompanyInformation,
  sendTenantData,
  setFinalFirmData,
  updateTenantData
} from '../../utils/api/instance'
import { setUserData } from '../../state/reducer/userSlice'
import { fetchUserMasterData } from '../../utils/api/user'
import { IFirm, IFullFirm } from '../../interface/IFirm'
import { useAuth0, User } from '@auth0/auth0-react'

const CyronOnboarding = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const onboardingData = useAppSelector((state) => state.onboardingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveStep(0));
    dispatch(setLoadingOnboardingData(true))
    const checkUserAlreadyExists = async (user: User) => {
      const userId = user.sub || user.userId
      const userData = await fetchAdminData(userId);
      const userDetailData = await fetchUserMasterData(userId);

      console.log("CURRENT USER DATA", userData)

      if (userData) {
        dispatch(setAdminInfos({
          authId: userData.authId,
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email
        }))

        dispatch(setAdminInfosSaved({
          id: userData.id
        }))

        if (userData.company) {
          const companyId = userData.company;
          const tenantId = userData.tenantId;

          const company = await fetchCompany(companyId);

          if (company) {
            dispatch(setCompanyInfos(company));
            dispatch(setCompanyInfosSaved({
              id: companyId
            }));

            dispatch(setActiveStep(1));

            if (tenantId) {
              const tenant = await fetchTenant(tenantId);

              console.log("FOUND TENANT", tenant);

              if (tenant) {
                dispatch(setTenantInfos(tenant));
                dispatch(setActiveStep(2));

                if (tenant.isActive && tenant.onboardingStatus !== "SUCCESS") {
                  dispatch(setActiveStep(3));
                } else if (tenant.isActive && tenant.onboardingStatus === "SUCCESS") {
                  dispatch(setUserData(userDetailData));
                  dispatch(setFinishedOnboarding());
                  navigate(HOME);
                }
              }
            }
          }
        }
        dispatch(setLoadingOnboardingData(false))
      }
    }

    if (user && isAuthenticated) {
      console.log("CURRENT USER", user);

      checkUserAlreadyExists(user)
        .finally(() => {

        });

      /*if (!data.finishedOnbarding) {
        checkUserAlreadyExists()
          .finally(() => setUsername());
      } else {
        // Redirect to dashboard
        navigate(HOME);
      }*/
    }
  }, [user]);

  console.log('Onboarding data:', onboardingData);

  //TODO: PUT TO OWN FILE
  /*const setFinalFirmData = (id: string) => {

    instance.put(`/api/v1/instance/user/${onboardingData.admin.id}`, {
      company: id
    })
      .then((response: AxiosResponse) => {
        console.log('Final firm data:', response.data);
        dispatch(setActiveStep(onboardingData.activeStep + 1));
      })
      .catch((error) => {
        console.error('Error setting final firm data:', error);
      })
  }*/

  const onSubmit = async (data: any, step: number) => {
    console.log('Data:', data, step);

    dispatch(toggleStepLoading(true));

    switch(step) {
      case 0:
        if (data) {
          console.log(data, step);
          const userData = { ...onboardingData.admin };
          userData.firstName = data.split(" ")[0];
          userData.lastName = data.split(" ")[1];

          dispatch(setAdminInfos(userData));
          const userUpdated = await updateAdminData(userData.id, {
              firstName: userData.firstName,
              lastName: userData.lastName
          });

          dispatch(setActiveStep(onboardingData.activeStep + 1));
        }
        break;
      case 1:
        if (data && data.id) {

          const response = await saveCompanyInformation({
            id: data.id,
            bvdid: data.id,
            name: data.name,
            description: data.description,
            adminUserId: onboardingData.admin.id
          });

          const company = {
            id: response.id,
            name: response.name,
            description: response.description || "",
            webUrl: response.website || "",
            street: response.address || "",
            city: response.city || "",
            country: response.country || ""
          } as IFullFirm;

          dispatch(setCompanyInfos(company));
          dispatch(setCompanyInfosSaved({
            id: company.id
          }))
          dispatch(setActiveStep(onboardingData.activeStep + 1));
        } else {
          try {
            await setFinalFirmData(onboardingData.companyData.id, onboardingData.admin.id);
            dispatch(setActiveStep(onboardingData.activeStep + 1));
          }
            catch (error) {
                console.error('Error setting final firm data:', error);
            }
        }

        // Set company information
        break;
      case 2:
        console.log(data);

        const foundTenants = await fetchTenantByName(data);

        if (foundTenants.length > 0) {
          dispatch(setError({
            active: true,
            error: 'Tenant already exists. Please specify another name.',
            type: 'STEP_1',
            code: 400
          }))
        } else {
          dispatch(setError({
          active: false,
            error: '',
            type: '',
            code: ""
          }))
          dispatch(setTenantFormData({
            tenantName: data
          }))
          dispatch(setActiveStep(onboardingData.activeStep + 1));
          // Set tenant information
        }

        break;
      case 3:
        console.log("TENANT DOMAIN", data);
        dispatch(setTenantFormData({
          tenantDomain: data
        }))
        const tenant = await sendTenantData({
          name: onboardingData.tenantName,
          domain: data,
          userId: onboardingData.admin.id,
          companyName: onboardingData.companyData.name,
          companyId: onboardingData.companyData.id
        })

        if (tenant) {
          const userUpdated = await updateAdminData(onboardingData.admin.id, {
            tenantId: tenant.tenantId
          });

          if (userUpdated) {
            dispatch(setTenantInfos(tenant));
            dispatch(setActiveStep(onboardingData.activeStep + 1));
          }
        }
        // Set welcome information
        break;
      case 4:
        if (onboardingData.tenantId) {
          const tenant = await updateTenantData({
            isActive: true,
            onboardingStatus: 'SUCCESS',
            activationDate: new Date()
          }, onboardingData.tenantId);

          if (tenant) {
            dispatch(setFinishedOnboarding());
            dispatch(toggleStepLoading(false));
            navigate(HOME);
          }
        }

        break;
      default:
        break;
    }

    dispatch(toggleStepLoading(false));

  }


  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      minHeight: '100vh',
      backgroundColor: '#121212'
    }}>
      {onboardingData.loadingOnboardingData ?
        <Box sx={{
          maxWidth: 680,
          width: '100%',
          display: 'flex',
          color: '#ffffff'
        }}>
          <Typography>Loading (Please wait)</Typography>
        </Box>
      : <Box sx={{
          maxWidth: 680,
          width: '100%',
          display: 'flex'
        }}>
          <ChatWindow onboarding={true} onSubmit={onSubmit} />
        </Box>}
    </Box>
  );
};

export default CyronOnboarding;
