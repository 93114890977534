import { IFullFirm } from '../../interface/IFirm'
import instance from './index'


const fetchFirm = async (id: string) : Promise<IFullFirm | undefined> => {
  const response = await instance.get<IFullFirm>(`/api/v1/firm/${id}`)

  if (response && response.data) {
    return response.data;
  }
  return undefined;
}

const fetchLatestMetric = async(id: string) : Promise<any | undefined> => {
  const response = await instance.get(`/api/v1/firm/${id}/metric/latest`)

  if (response && response.data) {
    return response.data;
  }
  return undefined;
}


export {
  fetchFirm,
  fetchLatestMetric
}