import { createListenerMiddleware } from '@reduxjs/toolkit'
import { setTenantInfos } from '../../state/reducer/onboardingSlice'
import instance from './index'


const axiosMiddleware = createListenerMiddleware()

axiosMiddleware.startListening({
  actionCreator: setTenantInfos,
  effect: async (action, listenerApi) => {
    console.log("SETUP TENANT", action.payload);
    if (action.payload.tenantName !== "") {
      instance.defaults.headers.common["x-tenant-id"] = action.payload.tenantName;
    }
  }
})

export { axiosMiddleware };
