// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRange-Holder.DateRange-Size-small input, .DateRange-Holder.DateRange-Size-small label {
  font-size: 10px;
}
.DateRange-Holder.DateRange-Size-medium input, .DateRange-Holder.DateRange-Size-medium label {
  font-size: 12px;
}
.DateRange-Holder.DateRange-Size-large input, .DateRange-Holder.DateRange-Size-large label {
  font-size: 14px;
}
.DateRange-Holder .DateRangePicker:first-child {
  margin-right: 10px;
}
.DateRange-Holder .DateRangePicker:last-child {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Date/DateRange.scss"],"names":[],"mappings":"AAIM;EACE,eAAA;AAHR;AAQM;EACE,eAAA;AANR;AAWM;EACE,eAAA;AATR;AAeI;EACE,kBAAA;AAbN;AAgBI;EACE,iBAAA;AAdN","sourcesContent":["\n.DateRange-Holder {\n  &.DateRange-Size {\n    &-small {\n      input, label {\n        font-size: 10px;\n      }\n    }\n\n    &-medium {\n      input, label {\n        font-size: 12px;\n      }\n    }\n\n    &-large {\n      input, label {\n        font-size: 14px;\n      }\n    }\n  }\n\n  .DateRangePicker {\n    &:first-child {\n      margin-right: 10px;\n    }\n\n    &:last-child {\n      margin-left: 10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
