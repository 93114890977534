import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useAppDispatch, useAppSelector } from '../../state/stateHooks'
import { useCoAgent, useCopilotChat, useCopilotContext, useCopilotMessagesContext } from '@copilotkit/react-core'
import { deleteConversation, getAllConversations, getConversation, parseConversationChat } from '../../utils/api/chat'
import { ChatConversationProps } from '../../interface/ChatProps'
import { AgentState } from './ChatCyron'


const ChatList = () => {
    const [conversationList, setConversationList] = useState<ChatConversationProps[]>([]);
    const { setMessages } = useCopilotMessagesContext();
    //const { messages, setMessages } = useCopilotMessagesContext();
    const { state, setState } = useCoAgent<AgentState>({
        name: "cyron",
        initialState: {
            corporate_id: "AT102983192",
            tenant_id: "XYZ",
            new_conversation: false
        },
    });
    const { setAgentSession } = useCopilotContext();

    const userData = useAppSelector((state) => state.userReducer);
    useEffect(() => {
        if (userData.authId) {
            getAllConversations(userData.authId)
                .then((response) => {
                    if (response) {
                        console.log("CHAT_LIST RESPONSE", response);
                        setConversationList(response)
                    }
                })
        }
    }, [userData])

    //const [conversationList, setConversationList] = useState([]);

    /*useEffect(() => {
        instance.get('/api/v1/chat/chat')
            .then((response) => {
                console.log(response)
                const list = response.data.map((conv: any) => {
                    const conversation = {
                        id: conv.conversation_id,
                        conversation_id: conv.conversation_id,
                        thread_id: conv.thread_id,
                        name: conv.title,
                        intro: false,
                        messages: []
                    } as ConversationProps

                    return conversation
                })

                console.log("CONVERSATION LIST", list)

                dispatch(setHistoryConversation({conversations: list}))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])*/

    const selectConversation =(conversation: ChatConversationProps) => {
        getConversation(conversation.id, userData.authId)
            .then((response) => {
                console.log(response)
                const parsedList = parseConversationChat(response)

                console.log("PARSED LIST", parsedList)
                setMessages(parsedList)
                setAgentSession({
                    agentName: 'cyron',
                    threadId: conversation.threadID,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteFromList = (conversation: ChatConversationProps) => {
        //dispatch(removeConversation(conversation.conversation_id))
        setConversationList(conversationList.filter((item: any) => item.threadID !== conversation.threadID))

        deleteConversation(conversation.id, userData.authId)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

  return (
    <Box
      sx={{
        marginTop: '15px',
        textAlign: 'left'
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
        }}
        variant="h5">Latest Chats</Typography>

      <Box>
        <List>
            {conversationList.map((conversation : ChatConversationProps) => (
                <ListItem
                    onClick={() => selectConversation(conversation)}
                    component={Button}
                    secondaryAction={
                        <IconButton
                            onClick={() => deleteFromList(conversation)}
                            edge="end" aria-label="delete" size="small">
                            <DeleteIcon />
                        </IconButton>}
                >
                    <ListItemAvatar
                        sx={{
                            minWidth: "30px"
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: '4px',
                                height: "15px",
                                width: "15px",
                                background: "#000",
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            fontSize: '12px',
                            whiteSpace: 'normal',

                            "& *": {
                                color: '#010101',
                                fontSize: '12px'
                            }
                        }}
                        primary={conversation.title}
                    />
                </ListItem>
            ))}
        </List>

        <Button
          sx={{
            width: '100%',
            fontSize: '12px',
            color: '#010101',
            borderColor: '#010101',
            border: 'none',
            textAlign: 'left',

            "span": {
              fontSize: '12px'
            }
          }}
          onClick={() => {
              setMessages([])
              setState({
                ...state,
                new_conversation: true
              })
              setAgentSession(null)
          }}
          variant="text" startIcon={<AddCircleIcon />}>
          New chat
        </Button>
      </Box>
    </Box>
  );
};

export default ChatList;
