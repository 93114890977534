import * as React from 'react'

interface IFilterHorizonIcon {

}

const FilterHorizonIcon : React.FC<IFilterHorizonIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16.5}
        height={14.833}
        viewBox="0 0 16.5 14.833"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1, .cls-2 {\n        fill: none;\n        stroke: #323741;\n        stroke-width: 1.5px;\n      }\n\n      .cls-1 {\n        stroke-linecap: round;\n        stroke-linejoin: round;\n      }\n    "
                }
            </style>
        </defs>
        <g id="filter-horizontal" transform="translate(0.75 0.75)">
            <g id="elements" transform="translate(-2.5 -3.333)">
                <path
                    id="Vector_753"
                    data-name="Vector 753"
                    className="cls-1"
                    d="M0,0H2.5"
                    transform="translate(2.5 5.833)"
                />
                <path
                    id="Vector_754"
                    data-name="Vector 754"
                    className="cls-1"
                    d="M0,0H5"
                    transform="translate(2.5 14.167)"
                />
                <path
                    id="Vector_757"
                    data-name="Vector 757"
                    className="cls-1"
                    d="M0,0H2.5"
                    transform="matrix(1, 0, 0, 1, 15, 14.167)"
                />
                <path
                    id="Vector_758"
                    data-name="Vector 758"
                    className="cls-1"
                    d="M0,0H5"
                    transform="matrix(1, 0, 0, 1, 12.5, 5.833)"
                />
                <path
                    id="Rectangle_2129"
                    data-name="Rectangle 2129"
                    className="cls-2"
                    d="M0,2.5A4.156,4.156,0,0,1,.127,1.029a1.667,1.667,0,0,1,.9-.9A4.156,4.156,0,0,1,2.5,0,4.156,4.156,0,0,1,3.971.127a1.667,1.667,0,0,1,.9.9A4.156,4.156,0,0,1,5,2.5a4.156,4.156,0,0,1-.127,1.471,1.667,1.667,0,0,1-.9.9A4.156,4.156,0,0,1,2.5,5a4.156,4.156,0,0,1-1.471-.127,1.667,1.667,0,0,1-.9-.9A4.156,4.156,0,0,1,0,2.5Z"
                    transform="translate(5 3.333)"
                />
                <path
                    id="Rectangle_2130"
                    data-name="Rectangle 2130"
                    className="cls-2"
                    d="M0,2.5A4.156,4.156,0,0,1,.127,1.029a1.667,1.667,0,0,1,.9-.9A4.156,4.156,0,0,1,2.5,0,4.156,4.156,0,0,1,3.971.127a1.667,1.667,0,0,1,.9.9A4.156,4.156,0,0,1,5,2.5a4.156,4.156,0,0,1-.127,1.471,1.667,1.667,0,0,1-.9.9A4.156,4.156,0,0,1,2.5,5a4.156,4.156,0,0,1-1.471-.127,1.667,1.667,0,0,1-.9-.9A4.156,4.156,0,0,1,0,2.5Z"
                    transform="translate(10 11.667)"
                />
            </g>
        </g>
    </svg>
);
export default FilterHorizonIcon;
