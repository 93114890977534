import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
    Box,
    Button, Input, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from '@mui/material'
import ChatMessageSimpleInput from '../../../components/Chat/ChatMessageSimpleInput'
import { saveCompanyInformation, searchCompanyInformation, setFinalFirmData } from '../../../utils/api/instance'
import { CompanySearchProps } from '../../../interface/CompanySearchProps'
import { useAppDispatch, useAppSelector } from '../../../state/stateHooks'
import { setActiveStep, setCompanyInfos, setCompanyInfosSaved } from '../../../state/reducer/onboardingSlice'
import { IFullFirm } from '../../../interface/IFirm'

const CreateCompanyOnboarding = () => {
    const [loading, setLoading] = useState(false)
    const [companyData, setCompanyData] = useState<CompanySearchProps>()
    const [editCompany, setEditCompany] = useState(false)
    const onboardingData = useAppSelector((state) => state.onboardingReducer);
    const dispatch = useAppDispatch();

    const form = useFormik({
        initialValues: {
            companyName: '',
            companyWebsite: '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            const data = await searchCompanyInformation(values.companyName + ", " + values.companyWebsite);
            if (data) {
                setCompanyData(data);
            }
            setLoading(false);
            console.log(data);
        }
    })

    const handleCreateCompany = async () => {
        if (companyData) {
            const response = await saveCompanyInformation({
                id: "NEW",
                name: companyData.name,
                website: companyData.website,
                description: companyData.description,
                address: companyData.address,
                city: companyData.city,
                country: companyData.country,
                adminUserId: onboardingData.admin.id
            });

            const company = {
                id: response.id,
                name: response.name,
                description: response.description || "",
                webUrl: response.website || "",
                street: response.address || "",
                city: response.city || "",
                country: response.country || ""
            } as IFullFirm;

            await setFinalFirmData(company.id, onboardingData.admin.id);

            dispatch(setCompanyInfos(company));
            dispatch(setCompanyInfosSaved({
                id: response.id
            }));
            dispatch(setActiveStep(1));
        }
    }

    const handleEditCompany = () => {
        setEditCompany(true);
    }

    return (
        <>
            {companyData ?(
                <>
                    <Box sx={{ overflowY: 'auto', padding: 2 }}>

                        <Box
                            sx={{
                                color: "#ffffff",
                                textAlign: "left",
                            }}
                        >
                            <Typography variant={"h4"} sx={{
                                lineHeight: 1.5,
                            }}>I made a short search and found the following information regarding your company:</Typography>


                            <Typography variant={"h4"} sx={{
                                lineHeight: 1.5,
                            }}>Are they correct?</Typography>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.name} /> : companyData.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Website: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.website} /> : companyData.website}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Description: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.description} /> : companyData.description}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Address: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.address} /> : companyData.address}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>State: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.state} /> : companyData.state}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>City: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.city} /> : companyData.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Country: </TableCell>
                                            <TableCell>{editCompany ? <TextField fullWidth value={companyData.country} /> : companyData.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {editCompany ? <Button
                                sx={{
                                    maxWidth: '50%',
                                    color: '#ffffff',
                                    marginTop: '1em',
                                    marginRight: '1em',
                                    border: '1px solid #ffffff',
                                    borderRadius: '12px',
                                    '&:hover': {
                                        backgroundColor: '#343434'
                                    },
                                    "&.Mui-disabled": {
                                        backgroundColor: '#343434',
                                        color: 'rgba(255, 255, 255, 0.5)'
                                    }
                                }}
                                disabled={loading}
                                type={"submit"} onClick={() => handleCreateCompany()}>Finished - Please save the company and proceed</Button>
                            :
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            marginTop: '1em',
                                            marginRight: '1em',
                                            border: '1px solid #ffffff',
                                            borderRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: '#343434'
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor: '#343434',
                                                color: 'rgba(255, 255, 255, 0.5)'
                                            }
                                        }}
                                        disabled={loading || onboardingData.companyIsSet}
                                        type={"submit"} onClick={() => handleCreateCompany()}>Yes - Please proceed with this company and start searching in detail</Button>

                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            marginTop: '1em',
                                            border: '1px solid #ffffff',
                                            borderRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: '#343434'
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor: '#343434',
                                                color: 'rgba(255, 255, 255, 0.5)'
                                            }
                                        }}
                                        disabled={loading || onboardingData.companyIsSet}
                                        type={"submit"} onClick={() => handleEditCompany()}>No - Let me change the details manually</Button>
                                </Box>
                            }

                        </Box>
                    </Box>
                </>
            ):
            (<>
                <Box sx={{ overflowY: 'auto', padding: 2 }}>

                    <Box
                        sx={{
                            color: "#ffffff",
                            textAlign: "left",
                        }}
                    >
                        <Typography variant={"h4"} sx={{
                            lineHeight: 1.5,
                        }}>Thank you for helping me and creating your company directly.</Typography>
                    </Box>
                </Box>
                <ChatMessageSimpleInput
                    question={"Please tell me your company name."}
                    value={form.values.companyName}
                    name={"companyName"}
                    disabled={loading}
                    required={true}
                    sx={{
                        marginBottom: 2
                    }}
                    onChange={form.handleChange} />
                <ChatMessageSimpleInput
                    question={"Do you have a website?"}
                    value={form.values.companyWebsite}
                    name={"companyWebsite"}
                    disabled={loading}
                    sx={{
                        marginBottom: 2
                    }}
                    onChange={form.handleChange} />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        sx={{
                            color: '#ffffff',
                            marginTop: '1em',

                            '&:hover': {
                                backgroundColor: '#343434'
                            },
                            "&.Mui-disabled": {
                                backgroundColor: '#343434',
                                color: 'rgba(255, 255, 255, 0.5)'
                            }
                        }}
                        disabled={loading}
                        type={"submit"} onClick={() => form.handleSubmit()}>{loading ? "Loading..." : "Create Company"}</Button>
                </Box>
            </>)
            }
        </>
    )
}

export default CreateCompanyOnboarding