import * as React from 'react'

interface IAffilitateIcon {

}

const AffilitateIcon : React.FC<IAffilitateIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.304}
        height={18.304}
        viewBox="0 0 18.304 18.304"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1, .cls-2, .cls-3 {\n        fill: none;\n        stroke: #555f75;\n        stroke-width: 1.5px;\n      }\n\n      .cls-1, .cls-3 {\n        stroke-linecap: round;\n      }\n\n      .cls-1 {\n        stroke-linejoin: round;\n      }\n    "
                }
            </style>
        </defs>
        <g id="affiliate" transform="translate(0.819 0.819)">
            <g id="elements" transform="translate(-1.667 -1.667)">
                <path
                    id="Vector"
                    className="cls-1"
                    d="M2.258.242c.359.359.212,1.846.212,1.846M.412.031S1.9-.119,2.258.242m0,0L0,2.5"
                    transform="matrix(1, 0, 0, 1, 15.833, 1.667)"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    className="cls-1"
                    d="M2.47.412S2.617,1.9,2.258,2.258m0,0C1.9,2.619.412,2.469.412,2.469m1.846-.211L0,0"
                    transform="matrix(1, 0, 0, 1, 15.833, 15.833)"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    className="cls-1"
                    d="M2.5,2.5.242.242m0,0C.6-.119,2.088.031,2.088.031M.03,2.088S-.117.6.242.242"
                    transform="matrix(1, 0, 0, 1, 1.667, 1.667)"
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    className="cls-1"
                    d="M.242,2.258,2.5,0M2.088,2.469S.6,2.619.242,2.258m0,0C-.117,1.9.03.412.03.412"
                    transform="translate(1.667 15.833)"
                />
                <path
                    id="Vector-5"
                    data-name="Vector"
                    className="cls-2"
                    d="M11.667,5.833A5.833,5.833,0,1,1,5.833,0,5.833,5.833,0,0,1,11.667,5.833Z"
                    transform="matrix(1, 0, 0, 1, 4.167, 4.167)"
                />
                <path
                    id="Vector-6"
                    data-name="Vector"
                    className="cls-3"
                    d="M1.6.7C.675.7,0,1.239,0,1.86S.435,2.869,1.667,2.869c1.357,0,1.667.619,1.667,1.24S2.74,5.22,1.6,5.22M1.6.7a1.7,1.7,0,0,1,1.412.6M1.6.7V0m0,5.22A1.726,1.726,0,0,1,.187,4.715M1.6,5.877V5.22"
                    transform="matrix(1, 0, 0, 1, 8.333, 7.047)"
                />
            </g>
        </g>
    </svg>
);
export default AffilitateIcon;
