import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import Bubble from './Bubble'

interface BubbleMainProps {
  width?: number;
  height?: number;
  dark?: boolean;
}
const BubbleMain: React.FC<BubbleMainProps> = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioAmplitude, setAudioAmplitude] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const mountRef = useRef<HTMLDivElement | null>(null);

  const width = props.width || 350;
  const height = props.height || 350;
  const isDark = props.dark || false;

  let audioContext: AudioContext | null = null;
  let analyser: AnalyserNode | null = null;
  let source: MediaElementAudioSourceNode | null = null;

  // Function to calculate and update audio amplitude
  const calculateAudioAmplitude = () => {
    if (!audioRef.current) return

    audioContext = new AudioContext()
    analyser = audioContext.createAnalyser()
    source = audioContext.createMediaElementSource(audioRef.current)

    source.connect(analyser)
    analyser.connect(audioContext.destination)

    analyser.fftSize = 256
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    getAmplitude(analyser, bufferLength, dataArray)
  };

  const getAmplitude = (analyser : AnalyserNode, bufferLength: number, dataArray: Uint8Array) => {
    analyser.getByteFrequencyData(dataArray)

    let sum = 0
    dataArray.forEach((value) => (sum += value))
    const averageAmplitude = sum / bufferLength / 150

    setAudioAmplitude(averageAmplitude)
    requestAnimationFrame(getAmplitude.bind(null, analyser, bufferLength, dataArray))
  }

  // Function to handle play/pause of the audio
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause()
        setIsPlaying(false)
      } else {
        audioRef.current.play()
        setIsPlaying(true)
        if (!audioContext && !analyser && !source) {
          calculateAudioAmplitude()
        }
      }
    }
  }

  // Setup audio analyser and context
  const setupAudio = () => {
    audioContext = new AudioContext();
    analyser = audioContext.createAnalyser();
    source = audioContext.createMediaElementSource(audioRef.current as HTMLAudioElement);

    source.connect(analyser);
    analyser.connect(audioContext.destination);
    analyser.fftSize = 256;
  };

  // UseEffect to set up the Three.js scene, camera, lights, and animation loop
  useEffect(() => {
    const mount = mountRef.current;
    if (!mount) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.set(0, 0, 2.5);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    renderer.setClearColor(isDark ? 0x000000 : 0xffffff, 0);
    mount.appendChild(renderer.domElement);

    // Ambient Light
    const ambientLight = new THREE.AmbientLight(isDark ? 0xffffff : 0x000000, 0.5);
    scene.add(ambientLight);

    // Point Lights
    const pointLight1 = new THREE.PointLight(isDark ? 0xff0000 : 0x0000ff, 1);
    pointLight1.position.set(5, 5, 5);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(isDark ? 0xffffff : 0x000000, 1);
    pointLight2.position.set(-5, -5, -5);
    scene.add(pointLight2);

    // Create bubbles
    const bubble1 = new Bubble({
      position: new THREE.Vector3(0, 0.1, 0),
      radius: 0.95,
      detail: width <= 150 ? 10 : 20,
      color: isDark ? 0x2d2d2d : 0x000000,
      isPlaying,
      audioAmplitude,
    });

    const bubble2 = new Bubble({
      position: new THREE.Vector3(0, 0.1, 0),
      radius: 0.8,
      detail: width <= 150 ? 10 : 20,
      color: isDark ? 0xffffff : 0x000000,
      isPlaying,
      audioAmplitude,
    });

    scene.add(bubble1);
    scene.add(bubble2);

    // Handle window resize
    const onWindowResize = () => {
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };
    window.addEventListener('resize', onWindowResize);

    // Animation loop
    const animate = () => {
      // Update the geometry of the bubbles based on audioAmplitude and isPlaying
      bubble1.updateGeometry(isPlaying, audioAmplitude);
      bubble2.updateGeometry(isPlaying, audioAmplitude);

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', onWindowResize);
      renderer.dispose();
      mount.removeChild(renderer.domElement);
    };
  }, [isPlaying, audioAmplitude, width, height]);

  return (
    <div>
      {/* Three.js Canvas */}
      <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default BubbleMain;