import instance from './index'
import moment from 'moment'
import { RecommendationProps } from '../../interface/RecommendationProps'


const fetchBankStatementOverview = async (startDate?: Date, endDate?: Date, range?: string) => {
  return instance.get(`/api/v1/tenant/financial/pnl`, {
    params: {
      start: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
      end: startDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
      range: range ? range : undefined
    }
  })
}

const fetchRecommendationList = async () => {
    return instance.get<RecommendationProps[]>(`/api/v1/tenant/recommendation`)
}


export {
  fetchBankStatementOverview,
  fetchRecommendationList
}