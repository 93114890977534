import * as React from 'react'

interface IMoneyIcon {

}

const MoneyIcon : React.FC<IMoneyIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18.167}
        height={15.667}
        viewBox="0 0 18.167 15.667"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1, .cls-2 {\n        fill: none;\n        stroke: #555f75;\n      }\n\n      .cls-1 {\n        stroke-width: 1.5px;\n      }\n\n      .cls-2 {\n        stroke-linecap: round;\n        stroke-linejoin: round;\n        stroke-width: 2px;\n        fill-rule: evenodd;\n      }\n    "
                }
            </style>
        </defs>
        <g id="money-04" transform="translate(0.75 0.75)">
            <g id="elements" transform="translate(-1.667 -2.917)">
                <path
                    id="Vector"
                    className="cls-1"
                    d="M8.333,13.333a10.033,10.033,0,0,1-4.167.833,11.255,11.255,0,0,1-2.5-.273C.349,13.592,0,12.816,0,11.572V2.595a1.337,1.337,0,0,1,1.667-1.2,11.255,11.255,0,0,0,2.5.273A10.033,10.033,0,0,0,8.333.833,10.033,10.033,0,0,1,12.5,0,11.255,11.255,0,0,1,15,.273c1.318.3,1.667,1.077,1.667,2.322v8.977A1.337,1.337,0,0,1,15,12.773a11.255,11.255,0,0,0-2.5-.273A10.033,10.033,0,0,0,8.333,13.333Z"
                    transform="translate(1.667 2.917)"
                />
                <path
                    id="Ellipse_1574"
                    data-name="Ellipse 1574"
                    className="cls-1"
                    d="M4.167,2.083A2.083,2.083,0,1,1,2.083,0,2.083,2.083,0,0,1,4.167,2.083Z"
                    transform="translate(7.917 7.917)"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    className="cls-2"
                    d="M0,0H.007"
                    transform="matrix(0, 1, -1, 0, 4.583, 10.833)"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    className="cls-2"
                    d="M0,0H.007"
                    transform="matrix(0, 1, -1, 0, 15.417, 9.16)"
                />
            </g>
        </g>
    </svg>
);
export default MoneyIcon;
