import { Button, styled } from '@mui/material'


const BlackButton = styled(Button)(({ theme }) => ({
  '&': {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: '8px',
    padding: '10px 20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
    }
  }
}));

export default BlackButton;