import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Box } from '@mui/material'
import classNames from 'classnames'
import './DateRange.scss'
import { Moment } from 'moment'

interface DateRangeProps {
  size?: "small" | "medium" | "large"
  value: {
    start: Moment,
    end: Moment
  },
  onChange: (startDate: Moment | null, endDate: Moment | null) => void
}

const dateFormat = "YYYY-MM-DD"
const DateRange: React.FC<DateRangeProps> = (props) => {

  const size = props.size || "medium";

  const HolderClass = classNames("DateRange-Holder", `DateRange-Size-${size}`)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        className={HolderClass}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          "> .MuiStack-root": {
            paddingBottom: '8px'
          }
        }}
      >
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Start Date"
            format={dateFormat}
            className={"DateRangePicker"}
            value={props.value.start}
            onChange={value => props.onChange(value, null)}  />
        </DemoContainer> -
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="End Date"
            format={dateFormat}
            className={"DateRangePicker"}
            value={props.value.end}
            onChange={value => props.onChange(null, value)} />
        </DemoContainer>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRange;