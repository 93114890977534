import { createSlice } from '@reduxjs/toolkit'


const initialState  = {
  address: "",
  authId: "",
  birthDate: undefined,
  city: "",
  company: "",
  country: "",
  createdAt: undefined,
  email: undefined,
  firstName: "",
  id: -1,
  lastName: "",
  phone: "",
  tenantId: undefined,
  updatedAt: undefined,
  username: undefined,
  zipCode: "",
  language: "english",
  notification: true
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      return payload;
    },
    clearUserData: (state) => {
      return initialState;
    }
  }
});

const userReducer = userSlice.reducer;

export const {setUserData, clearUserData}  = userSlice.actions;
export default userReducer;