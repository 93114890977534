export const HOME = "/"

export const ONBOARDING = "/onboarding"

export const ONBOARDING_TENANT = "/onboarding/tenant"
export const ONBOARDING_COMPANY = "/onboarding/company"
export const ONBOARDING_QUESTIONAIRE = "/onboarding/questionnaire"

export const BUDGET_FINANCE_OVERVIEW = "/budget"
export const BUDGET_FINANCE_CREATE = "/budget/new"
export const CASH_FLOW_OVERVIEW = "/cash"
export const TAX_OVERVIEW = "/tax"
export const REPORT_OVERVIEW = "/report"
export const REPORT_DETAIL = "/report/:reportId"

export const MARKET_OVERVIEW = "/market"


export const ACCOUNT_SETUP = "/account-setup"
export const BALANCE = "/balance"
export const REPORTS = "/reports"
export const CHAT = "/chat"
export const FILES = "/files"
export const MESSAGES = "/messages"
export const NOTIFICATION = "/notification"
export const INTEGRATION = "/integration"
export const CALLBACK_INTEGRATION_BANK = "/integration/bank-account/callback"

// DEMO CALLBACK
export const CALLBACK_INTEGRATION_BANK_DEMO = "/integration/bank-account/callback/demo"

export const ACCOUNT = "/account"
export const COMPANY_ADMIN = "/tenant"

export const SETTINGS = "/settings"
export const SETTINGS_GENERAL = "general"
export const SETTINGS_INTEGRATION = "integration"
