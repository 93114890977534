import axios from 'axios'


const instance = axios.create({
    baseURL: "/"
});

// Add a request interceptor to include the auth token
instance.interceptors.request.use(
  async (config) => {
      try {
          /*const currentSession = await fetchAuthSession();
          const token = currentSession.tokens?.accessToken;
          config.headers.Authorization = `Bearer ${token?.toString()}`;*/
      } catch (error) {
          console.error('Error fetching auth token', error);
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

export default instance;
