import React from 'react'
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material'
import { styled } from '@mui/system'
import ArchiveIcon from '@mui/icons-material/Archive'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import SimpleChat from '../../components/Chat/SimpleChat'
import { useAppDispatch, useAppSelector } from '../../state/stateHooks'
import { setCurrentConversation } from '../../state/reducer/conversationSlice'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'

const drawerWidth = 300;

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  /*transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),*/
  marginLeft: `-${drawerWidth}px`,
  [theme.breakpoints.up("sm")]: {
    marginLeft: 0,
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  backgroundColor: theme.palette.background.chat,
  borderRadius: '25px'
}));

interface MessageProps {
  id: number
  text: string,
  isUser: boolean,
  timestamp: string,
}

const ChatPage = () => {
  const data = useAppSelector((state) => state.onboardingReducer);
  const conversationData = useAppSelector((state) => state.conversationReducer);
  const currentConversationID = conversationData.currentConversation;
  const conversationList = conversationData.conversationList;
  //const currentConversation = conversationList.find((conversation) => conversation.conversation_id === currentConversationID) || {run_id: "", conversation_id: "", messages: []};

  const dispatch = useAppDispatch();

  const handleThreadSelect = (conversationId : string) => {
    dispatch(setCurrentConversation(conversationId))
  };

  const handleCreateThread = () => {
    /*const newThread = {
      id: threads.length + 1,
      name: `New Thread ${threads.length + 1}`,
      unread: 0,
    };
    setThreads([...threads, newThread]);*/
  };

  const handleArchiveThread = (conversationId : string) => {
    /*setThreads(threads.filter((thread) => thread.id !== threadId));*/
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Conversations
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {conversationList.filter(conversation => !conversation.intro).map((conversation) => (
          <ListItemButton
            key={conversation.conversation_id}
            onClick={() => handleThreadSelect(conversation.conversation_id)}
            selected={currentConversationID === conversation.conversation_id}
          >
            <ListItemAvatar>
              <Avatar>{conversation.name[0]}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={conversation.name} />
            {/*{thread.unread > 0 && (
              <Badge badgeContent={thread.unread} color="secondary" />
            )}*/}
            <IconButton
              edge="end"
              aria-label="archive"
              onClick={(e) => {
                e.stopPropagation();
                handleArchiveThread(conversation.conversation_id);
              }}
            >
              <ArchiveIcon />
            </IconButton>
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem onClick={handleCreateThread}>
          <ListItemAvatar>
            <Avatar>
              <AddCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Create New Conversation" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <LeftMenuRightChatLayout
      container={true}
      chatIntegrated={false}
      title={"Cyron"}
    >


    <Box sx={{ display: "flex" }}>
      {/*<Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="chat threads"
      >
        <Drawer
          variant="permanent"
          sx={{
            maxHeight: '80vh',
            height: '100%',
            position: 'relative',
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              position: 'absolute',
              width: drawerWidth,
              backgroundColor: '#000000',
              borderRadius: '25px',
            },
            '& *': {
              color: '#fff'
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>*/}
      <Main>
        <ChatContainer>
          <SimpleChat />
        </ChatContainer>
      </Main>
    </Box>

    </LeftMenuRightChatLayout>
  );
};

export default ChatPage;
