import * as React from 'react'

interface IConfigIcon {

}

const ConfigIcon : React.FC<IConfigIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16.25}
        height={16.25}
        viewBox="0 0 16.25 16.25"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #555f75;\n        fill-rule: evenodd;\n      }\n    "
                }
            </style>
        </defs>
        <g
            id="Gruppe_794"
            data-name="Gruppe 794"
            transform="translate(-3553.875 1064.125)"
        >
            <path
                id="Pfad_634"
                data-name="Pfad 634"
                className="cls-1"
                d="M1346.79,30.745a.813.813,0,0,0-1.58,0,2.057,2.057,0,0,1-3.07,1.274.814.814,0,0,0-1.12,1.116,1.982,1.982,0,0,1,.29.914,1.932,1.932,0,0,1-.15.949,1.973,1.973,0,0,1-.56.777,2.073,2.073,0,0,1-.86.437.813.813,0,0,0,0,1.577,2.075,2.075,0,0,1,.86.438,1.973,1.973,0,0,1,.56.777,1.929,1.929,0,0,1,.15.948,1.98,1.98,0,0,1-.29.913.81.81,0,0,0,1.11,1.116,2.08,2.08,0,0,1,1.87-.144,2.028,2.028,0,0,1,.77.564,1.9,1.9,0,0,1,.44.855.813.813,0,0,0,1.58,0,2.121,2.121,0,0,1,.44-.855,2.026,2.026,0,0,1,.77-.563,2.078,2.078,0,0,1,.95-.151,2.033,2.033,0,0,1,.91.295.814.814,0,0,0,1.12-1.116,1.982,1.982,0,0,1-.29-.914,1.932,1.932,0,0,1,.15-.949,1.973,1.973,0,0,1,.56-.777,2.073,2.073,0,0,1,.86-.437.813.813,0,0,0,0-1.577,2.075,2.075,0,0,1-.86-.438,1.973,1.973,0,0,1-.56-.777,1.929,1.929,0,0,1-.15-.948,1.98,1.98,0,0,1,.29-.913.81.81,0,0,0-1.11-1.116,2.08,2.08,0,0,1-1.87.144,2.028,2.028,0,0,1-.77-.564A1.9,1.9,0,0,1,1346.79,30.745Zm-2.79-.3a2.057,2.057,0,0,1,4,0,.86.86,0,0,0,.48.559.816.816,0,0,0,.37.059.768.768,0,0,0,.36-.116,2.065,2.065,0,0,1,2.84,2.834.774.774,0,0,0-.12.36.8.8,0,0,0,.06.373.906.906,0,0,0,.22.306.874.874,0,0,0,.34.172,2.061,2.061,0,0,1,0,4.006.871.871,0,0,0-.34.172.907.907,0,0,0-.22.306.8.8,0,0,0-.06.373.774.774,0,0,0,.12.36,2.065,2.065,0,0,1-2.84,2.834.769.769,0,0,0-.36-.116.818.818,0,0,0-.37.059.86.86,0,0,0-.48.558,2.057,2.057,0,0,1-4,0,.86.86,0,0,0-.48-.559.816.816,0,0,0-.37-.059.766.766,0,0,0-.36.116,2.065,2.065,0,0,1-2.84-2.834.774.774,0,0,0,.12-.36.8.8,0,0,0-.06-.373.906.906,0,0,0-.22-.306.874.874,0,0,0-.34-.172,2.061,2.061,0,0,1,0-4.006.871.871,0,0,0,.34-.172.907.907,0,0,0,.22-.306.8.8,0,0,0,.06-.373.774.774,0,0,0-.12-.36,2.065,2.065,0,0,1,2.84-2.834A.81.81,0,0,0,1344,30.45Z"
                transform="translate(2216 -1093)"
            />
            <path
                id="Pfad_635"
                data-name="Pfad 635"
                className="cls-1"
                d="M1346,35.125A1.875,1.875,0,1,0,1347.88,37,1.876,1.876,0,0,0,1346,35.125ZM1342.88,37a3.12,3.12,0,1,1,3.12,3.125A3.118,3.118,0,0,1,1342.88,37Z"
                transform="translate(2216 -1093)"
            />
        </g>
    </svg>
);
export default ConfigIcon;
