import React, { ReactNode, useEffect, useState } from 'react'
import { Box, BoxProps, Button, CircularProgress, InputProps, Paper, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { initOnboardingError, OnboardingStateErrorType } from '../../state/reducer/onboardingSlice'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'

interface ChatMessageSimpleInputProps extends InputProps {
    question: string;
    value?: string;
    errorInput?: OnboardingStateErrorType;
    onChange: OutlinedInputProps['onChange']
    required?: boolean;
    disabled?: boolean;
    name: string;
    sx?: BoxProps['sx']
}

const ChatMessageSimpleInput: React.FC<ChatMessageSimpleInputProps> = (props) => {
  const { question, value, name, onChange, required, disabled, sx, errorInput = initOnboardingError } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#343434',
        padding: 2,
        borderRadius: '12px',
          ...sx
      }}
    >
      <Paper
        sx={{
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#343434',
        }}
        elevation={0}
      >
        <TextField
          fullWidth
          error={errorInput.active}
          helperText={errorInput.active ? errorInput.error : ''}
          variant="outlined"
          required={required}
          disabled={disabled}
          label={question}
          value={value}
          onChange={onChange}
          name={name}
          sx={{
            marginRight: '8px',
            border: "none",
            input: {
              border: "none",
              color: '#E0E0E0' },
            '& fieldset': {
              border: "none",
            },
            '& label': { color: '#9d9d9d' },
            '& label.Mui-focused': {
              color: '#9d9d9d',
              borderColor: '#9d9d9d'
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default ChatMessageSimpleInput;