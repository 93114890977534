import React, { useRef } from 'react'
import classNames from 'classnames'
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official'
import Highcharts from 'highcharts'

interface ILineChart extends HighchartsReactProps{
    className?: any
}

const LineChart : React.FC<ILineChart> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options: Highcharts.Options = {
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                }
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }


    return (
        <div className={classNames('Chart', 'LineChart', props.className)}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </div>
    );
};

export default LineChart;