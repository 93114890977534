import FilterHorizonIcon from './FilterHorizonIcon'
import AffilitateIcon from './AffilitateIcon'
import AnalyticsIcon from './AnalyticsIcon'
import DiscoverIcon from './DiscoverIcon'
import ConfigIcon from './ConfigIcon'
import AlertIcon from './AlertIcon'
import QuestionIcon from './QuestionIcon'
import FilterIcon from './FilterIcon'
import MoneyIcon from './MoneyIcon'

export {
    FilterHorizonIcon,
    AffilitateIcon,
    AnalyticsIcon,
    DiscoverIcon,
    ConfigIcon,
    AlertIcon,
    QuestionIcon,
    FilterIcon,
    MoneyIcon
}