import React, { useState } from 'react'
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    styled,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LeftMenuLayout from '../../layout/LeftMenuLayout'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[4]
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const NotificationPage = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: "New message received",
      description: "You have a new message from John Doe",
      timestamp: "2023-06-10T10:30:00Z",
      read: false,
      link: "/messages/1"
    },
    {
      id: 2,
      title: "Payment successful",
      description: "Your payment of $50.00 has been processed",
      timestamp: "2023-06-09T15:45:00Z",
      read: true,
      link: "/transactions/123"
    },
    {
      id: 3,
      title: "New feature available",
      description: "Check out our latest feature: Dark Mode",
      timestamp: "2023-06-08T09:00:00Z",
      read: false,
      link: "/features/dark-mode"
    }
  ]);

  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClearAll = () => {
    setNotifications([]);
    showSnackbar("All notifications cleared");
  };

  const handleDismiss = (id : number) => {
    setNotifications(notifications.filter((notification) => notification.id !== id));
    showSnackbar("Notification dismissed");
  };

  const handleMarkAsRead = (id : number) => {
    setNotifications(
      notifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
    showSnackbar("Notification marked as read");
  };

  const handleNotificationClick = (notification : any) => {
    setSelectedNotification(notification);
  };

  const handleCloseDialog = () => {
    setSelectedNotification(null);
  };

  const showSnackbar = (message : string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <LeftMenuLayout
      container={true}
      chatIntegrated={false}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Message (Mail) Manager</Typography>
          </Grid>
        </Grid>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleClearAll}
        disabled={notifications.length === 0}
        sx={{ marginBottom: 2 }}
      >
        Clear All
      </Button>
      {notifications.length === 0 ? (
        <Typography>No notifications to display.</Typography>
      ) : (
        <List>
          {notifications.map((notification) => (
            <StyledPaper key={notification.id} elevation={1}>
              <StyledListItem
                disablePadding
                onClick={() => handleNotificationClick(notification)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      {/*<FaBell
                        color={notification.read ? theme.palette.text.secondary : theme.palette.primary.main}
                        style={{ marginRight: theme.spacing(1) }}
                      />*/}
                      <Typography variant="h6">{notification.title}</Typography>
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {notification.description}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {new Date(notification.timestamp).toLocaleString()}
                      </Typography>
                    </>
                  }
                />
                <Box>
                  <StyledChip
/*
                    icon={notification.read ? <FaCheckCircle /> : <FaTimesCircle />}
*/
                    label={notification.read ? "Read" : "Unread"}
                    color={notification.read ? "default" : "primary"}
                    size="small"
                  />
                  <IconButton
                    aria-label="mark as read"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkAsRead(notification.id);
                    }}
                    disabled={notification.read}
                  >
{/*
                    <FaCheckCircle />
*/}
                  </IconButton>
                  <IconButton
                    aria-label="dismiss"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDismiss(notification.id);
                    }}
                  >
{/*
                    <FaTrash />
*/}
                  </IconButton>
                </Box>
              </StyledListItem>
            </StyledPaper>
          ))}
        </List>
      )}
      <Dialog
        open={Boolean(selectedNotification)}
        onClose={handleCloseDialog}
        fullScreen={isMobile}
        aria-labelledby="notification-dialog-title"
      >
        {selectedNotification && (
          <>
            <DialogTitle id="notification-dialog-title">{selectedNotification.title}</DialogTitle>
            <DialogContent>
              <Typography gutterBottom>{selectedNotification.description}</Typography>
              <Typography variant="caption" color="textSecondary">
                {new Date(selectedNotification.timestamp).toLocaleString()}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button
                color="primary"
                component="a"
                href={selectedNotification.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Details
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

    </LeftMenuLayout>
  );
};

export default NotificationPage;