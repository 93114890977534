import * as React from 'react'

interface IQuestionIcon {

}

const QuestionIcon : React.FC<IQuestionIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.504}
        height={17.5}
        viewBox="0 0 17.504 17.5"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #555f75;\n        stroke: #fff;\n        stroke-width: 0.023px;\n      }\n    "
                }
            </style>
        </defs>
        <path
            id="Pfad_629"
            data-name="Pfad 629"
            className="cls-1"
            d="M1260.17,33.194h0a2.639,2.639,0,0,1,.94,2.009h0a2.806,2.806,0,0,1-1.89,2.577h0a.961.961,0,0,0-.61.886h0v.443a.141.141,0,0,1-.14.144h-.94a.149.149,0,0,1-.15-.144v-.42a2.262,2.262,0,0,1,.39-1.261,2.171,2.171,0,0,1,1.01-.795h0a1.6,1.6,0,0,0,1.11-1.431,1.922,1.922,0,0,0-3.78,0v.149a.141.141,0,0,1-.14.145h-.94a.141.141,0,0,1-.14-.145V35.2a2.639,2.639,0,0,1,.94-2.009,3.369,3.369,0,0,1,4.34,0ZM1249.26,37a8.74,8.74,0,1,1,8.74,8.738A8.738,8.738,0,0,1,1249.26,37Zm1.46,0a7.28,7.28,0,1,0,7.28-7.277A7.28,7.28,0,0,0,1250.72,37Zm6.74,4.841a.759.759,0,0,1,0-1.088.76.76,0,0,1,1.08,0,.759.759,0,0,1,0,1.088.76.76,0,0,1-1.08,0Z"
            transform="translate(-1249.248 -28.25)"
        />
    </svg>
);
export default QuestionIcon;
