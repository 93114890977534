

export const expensesType = [
  {
    name: 'Rent/Lease',
    id: 'rent_lease',
    color: '#ff4c4c'
  },
  {
    name: 'Rent/Lease',
    id: 'rentlease',
    color: '#ff4c4c'
  },
  {
    name: 'Utilities',
    id: 'utilities',
    color: '#ff4c4c'
  },
  {
    name: 'Salaries/Wages',
    id: 'salarieswages',
    color: '#ff4c4c'
  },
  {
    name: 'Salaries/Wages',
    id: 'salaries_wages',
    color: '#ff4c4c'
  },
  {
    name: 'Employee Benefits',
    id: 'employee_benefits',
    color: '#ff4c4c'
  },
  {
    name: 'Taxes',
    id: 'taxes',
    color: '#ff4c4c'
  },
  {
    name: 'Marketing/Advertising',
    id: 'marketingadvertising',
    color: '#ff4c4c'
  },
  {
    name: 'Insurance',
    id: 'insurance',
    color: '#ff4c4c'
  },
  {
    name: 'Professional Services',
    id: 'professional_services',
    color: '#ff4c4c'
  },
  {
    name: 'Office Supplies',
    id: 'office_supplies',
    color: '#ff4c4c'
  },
  {
    name: 'Travel Expenses',
    id: 'travel_expenses',
    color: '#ff4c4c'
  },
  {
    name: 'Training/Education',
    id: 'training_education',
    color: '#ff4c4c'
  },
  {
    name: 'Software/Subscriptions',
    id: 'softwaresubscriptions',
    color: '#ff4c4c'
  },
  {
    name: 'Software/Subscriptions',
    id: 'software_subscriptions',
    color: '#ff4c4c'
  },
  {
    name: 'IT Services',
    id: 'it_services',
    color: '#ff4c4c'
  },
  {
    name: 'Equipment Purchase/Maintenance',
    id: 'equipment_purchase_maintenance',
    color: '#ff4c4c'
  },
  {
    name: 'Inventory/Supplies',
    id: 'inventory_supplies',
    color: '#ff4c4c'
  },
  {
    name: 'Shipping/Postage',
    id: 'shipping_postage',
    color: '#ff4c4c'
  },
  {
    name: 'Vehicle Expenses',
    id: 'vehicle_expenses',
    color: '#ff4c4c'
  },
  {
    name: 'Depreciation/Amortization',
    id: 'depreciation_amortization',
    color: '#ff4c4c'
  },
  {
    name: 'Research and Development',
    id: 'research_and_development',
    color: '#ff4c4c'
  },
  {
    name: 'Recruitment/HR',
    id: 'recruitment_hr',
    color: '#ff4c4c'
  },
  {
    name: 'Bank Fees/Charges',
    id: 'bank_fees_charges',
    color: '#ff4c4c'
  },
  {
    name: 'Bank Fees/Charges',
    id: 'bank_feescharges',
    color: '#ff4c4c'
  },
  {
    name: 'Licensing/Permits',
    id: 'licensing_permits',
    color: '#ff4c4c'
  },
  {
    name: 'Utilities/Internet',
    id: 'utilitiesinternet',
    color: '#ff4c4c'
  },
  {
    name: 'Property Maintenance',
    id: 'property_maintenance',
    color: '#ff4c4c'
  },
  {
    name: 'Security Services',
    id: 'security_services',
    color: '#ff4c4c'
  },
  {
    name: 'Loan Interest',
    id: 'loan_interest',
    color: '#ff4c4c'
  },
  {
    name: 'Rentals/Leasing',
    id: 'rentals_leasing',
    color: '#ff4c4c'
  },
  {
    name: 'Rentals/Leasing',
    id: 'rentalsleasing',
    color: '#ff4c4c'
  },
  {
    name: 'Client Entertainment',
    id: 'client_entertainment',
    color: '#ff4c4c'
  },
  {
    name: 'Business Meals',
    id: 'business_meals',
    color: '#ff4c4c'
  },
  {
    name: 'Telecommunications',
    id: 'telecommunications',
    color: '#ff4c4c'
  },
  {
    name: 'Charitable Contributions',
    id: 'charitable_contributions',
    color: '#ff4c4c'
  },
  {
    name: 'Corporate Gifts',
    id: 'corporate_gifts',
    color: '#ff4c4c'
  },
  {
    name: 'Legal Fees',
    id: 'legal_fees',
    color: '#ff4c4c'
  },
  {
    name: 'Environmental Fees',
    id: 'environmental_fees',
    color: '#ff4c4c'
  },
  {
    name: 'Subscriptions/Memberships',
    id: 'subscriptions_memberships',
    color: '#ff4c4c'
  },
  {
    name: 'Employee Expenses',
    id: 'employee_expenses',
    color: '#ff4c4c'
  },
  {
    name: 'Miscellaneous Expenses',
    id: 'miscellaneous_expenses',
    color: '#ff4c4c'
  },
  {
    name: 'Bad Debts',
    id: 'bad_debts',
    color: '#ff4c4c'
  },
  {
    name: 'Foreign Exchange Losses',
    id: 'foreign_exchange_losses',
    color: '#ff4c4c'
  },
  {
    name: 'Corporate Taxes',
    id: 'corporate_taxes',
    color: '#ff4c4c'
  }
]

export const costType = [
  {
    name: 'Costs of goods sold',
    id: 'costOfGoodsSold',
    color: '#ff4c4c'
  },
  {
    name: 'Employee Costs',
    id: 'employeeCost',
    color: '#ff4c4c'
  },
  {
    name: 'Office Costs',
    id: 'officeCost',
    color: '#ff4c4c'
  },
  {
    name: 'Marketing Costs',
    id: 'marketingCost',
    color: '#ff4c4c'
  },
  {
    name: 'Technology Costs',
    id: 'technologyCost',
    color: '#ff4c4c'
  },
  {
    name: 'Professional Fees',
    id: 'professionalFees',
    color: '#ff4c4c'
  },
  {
    name: 'Travel and Training Costs',
    id: 'travelAndTrainingCost',
    color: '#ff4c4c'
  },
  {
    name: 'Insurance/Risk Costs',
    id: 'insuranceRiskCost',
    color: '#ff4c4c'
  },
  {
    name: 'Other Expenses',
    id: 'otherExpenses',
    color: '#ff4c4c'
  },
  {
    name: 'Non Operating Expenses',
    id: 'nonOperatingExpenses',
    color: '#ff4c4c'
  }
]