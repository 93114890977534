import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react';

interface AuthenticationProps {
    children: React.ReactNode
}

const Auth0ProviderHolder : React.FC<AuthenticationProps> = (props) => {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
            authorizationParams={{
                redirect_uri: window.location.origin + '/callback'
            }}
        >
            {props.children}
        </Auth0Provider>
    )
}

export default Auth0ProviderHolder
