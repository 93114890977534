import * as React from 'react'

interface IAnalyticsIcon {

}

const AnalyticsIcon : React.FC<IAnalyticsIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.333}
        height={17.333}
        viewBox="0 0 17.333 17.333"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1, .cls-2 {\n        fill: none;\n        stroke: #555f75;\n        stroke-width: 1.5px;\n      }\n\n      .cls-1 {\n        stroke-linecap: round;\n      }\n\n      .cls-2 {\n        stroke-linejoin: round;\n      }\n    "
                }
            </style>
        </defs>
        <g id="analytics-01" transform="translate(0.75 0.75)">
            <g id="elements" transform="translate(-2.083 -2.083)">
                <path
                    id="Vector_4075"
                    data-name="Vector 4075"
                    className="cls-1"
                    d="M0,3.333V0"
                    transform="matrix(1, 0, 0, 1, 5.833, 10.833)"
                />
                <path
                    id="Vector_4077"
                    data-name="Vector 4077"
                    className="cls-1"
                    d="M0,8.333V0"
                    transform="matrix(1, 0, 0, 1, 10, 5.833)"
                />
                <path
                    id="Vector_4078"
                    data-name="Vector 4078"
                    className="cls-1"
                    d="M0,5V0"
                    transform="matrix(1, 0, 0, 1, 14.167, 9.167)"
                />
                <path
                    id="Vector"
                    className="cls-2"
                    d="M0,7.917c0-3.732,0-5.6,1.159-6.757S4.185,0,7.917,0s5.6,0,6.757,1.159,1.159,3.025,1.159,6.757,0,5.6-1.159,6.757-3.025,1.159-6.757,1.159-5.6,0-6.757-1.159S0,11.649,0,7.917Z"
                    transform="translate(2.083 2.083)"
                />
            </g>
        </g>
    </svg>
);
export default AnalyticsIcon;
