import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useDropzone } from 'react-dropzone';
import './FullScreenDropzone.scss';
import { uploadFIle } from '../../utils/api/file'

const FullScreenDropzone = () => {
    const [isDragActive, setIsDragActive] = useState(false);
    const dragCounter = useRef(0); // Use ref to track drag events count

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            setIsDragActive(false);

            try {
                const uploaded = await uploadFIle(acceptedFiles);
            } catch (error) {
                console.error('Error uploading file', error);
            }

            dragCounter.current = 0; // Reset drag counter on drop
        }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
    });

    useEffect(() => {
        const handleDragEnter = (event: DragEvent) => {
            event.preventDefault();
            dragCounter.current += 1; // Increment drag counter
            setIsDragActive(true); // Show overlay when dragging starts
        };

        const handleDragLeave = (event: DragEvent) => {
            event.preventDefault();
            dragCounter.current -= 1; // Decrement drag counter
            if (dragCounter.current === 0) {
                setIsDragActive(false); // Hide overlay when no drag events remain
            }
        };

        const handleDrop = (event: DragEvent) => {
            event.preventDefault();
            setIsDragActive(false); // Hide overlay on drop
            dragCounter.current = 0; // Reset counter on drop
        };

        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragover', (event) => event.preventDefault()); // Prevent default to allow drops

        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
    }, []);

    return (
        <>
            {isDragActive && (
                <div {...getRootProps()} className="fullscreen-dropzone">
                    <input {...getInputProps()} />
                    <div className="overlay">
                        <p>Drop files here to upload</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default FullScreenDropzone;
