import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { ConfigIcon, QuestionIcon } from '../Icons'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../../routes/routes'
import NotificationTab from '../Notification/NotificationTab'
import classNames from 'classnames'
import { useAuth0 } from '@auth0/auth0-react'

interface IMainHeader {

}

const MainHeader: React.FC<IMainHeader> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user, logout } = useAuth0();
  const [usernameAvatar, setUsernameAvatar] = useState('U');

  useEffect(() => {
    const getUserAttributes = async () => {
      /*const attributes = await fetchUserAttributes();

      console.log('Attributes:', attributes);
      const username = attributes['preferred_username'] as string;

      setUsernameAvatar(username.charAt(0).toUpperCase());*/
    }

    if (user) {
      getUserAttributes()
        .finally(() => {

        })
    }
  }, [user])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
        <Box sx={{
            width: '100%',
            minWidth: 'calc(100vw - 300px)',
            height: '100%',
            background: '#fff',
            padding: '10px',
            borderRadius: '50px',
            marginTop: '15px',
            marginBottom: '25px'
        }}
             className={"MainHeader"}
        >
            <Grid container justifyContent={'space-between'}>
                <Grid xs={9}>
                    {/*<FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <OutlinedInput
                            placeholder={"Search"}
                            id="search-header-field"
                            startAdornment={<InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputAdornment>}
                        />
                    </FormControl>*/}
                </Grid>
                <Grid xs={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginRight: '20px',
                                marginLeft: '20px',
                                height: '100%',
                                maxWidth: '180px',
                            }}
                        >
                            <Button
                                sx={{
                                    minWidth: '35px',
                                    width: '35px',
                                    height: '35px',
                                    margin: '0px 5px'
                                }}
                            >
                                <QuestionIcon />
                            </Button>

                          <NotificationTab />
                            <Button
                                component={NavLink}
                                to={ROUTES.SETTINGS}
                                className={classNames({active: window.location.pathname === ROUTES.SETTINGS})}
                                sx={{
                                    minWidth: '35px',
                                    width: '35px',
                                    height: '35px',
                                    margin: '0px 5px',

                                    '&.active': {
                                        color: '#000',
                                        background: '#fff',
                                        border: '1px solid #000',
                                        borderRadius: '50%'
                                    }
                                }}>
                                <ConfigIcon />
                            </Button>
                            <Box
                                sx={{
                                    minWidth: '35px',
                                    margin: '0px 5px'
                                }}
                            >
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  aria-controls={open ? 'account-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                >
                                  <Avatar sx={{ bgcolor: '#000' }}>{usernameAvatar}</Avatar>
                                </IconButton>
                              </Tooltip>
                            </Box>

                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                    paper: {
                                    elevation: 0,
                                    sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                    '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                  },
                                },
                              }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          >
                            <MenuItem component={NavLink} to={ROUTES.ACCOUNT}>
                              <Avatar /> Profile
                            </MenuItem>
                            <MenuItem component={NavLink} to={ROUTES.COMPANY_ADMIN}>
                              <Avatar /> Company Administration
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleClose}>
                              <ListItemIcon>
                              </ListItemIcon>
                              Invite another account
                            </MenuItem>
                            <MenuItem onClick={() => logout()}>
                              <ListItemIcon>
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </Menu>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MainHeader;
