import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { llmSocket, notificationSocket } from '../utils/notification/socket'
import { useAppDispatch, useAppSelector } from '../state/stateHooks'
import {
  addConversation,
  addMessageToConversation,
  addTitleToConversation,
  SenderType,
  updateMessageInConversation
} from '../state/reducer/conversationSlice'
import { MessageData } from '../utils/helper/messageData'
import { NotificationProps } from '../interface/NotificationProps'
import { addNotification } from '../state/reducer/notificationSlice'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'

export interface SocketNotificationProviderProps {
  children: React.ReactNode;
}

export interface SocketNotificationContextType {
  socket: typeof llmSocket;
  message: NotificationProps | undefined;
  sendSocketMessage: (message: any) => void;
}

export interface LangChainMessage {
  data: {
    chunk: string;
    last_message?: boolean;
  };
  event: string;
  metadata: any;
  name: string;
  parent_ids: string[];
  run_id: string;
  tags: string[];
}

export enum SocketReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

/*export type WebSocketMessage = string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView;

export type SendMessage = (message: WebSocketMessage, keep?: boolean) => void;
export type SendJsonMessage = <T = unknown>(jsonMessage: T, keep?: boolean) => void;*/

const SocketNotificationContext = createContext<SocketNotificationContextType>({} as SocketNotificationContextType);

const socket = notificationSocket;

export const SocketNotificationProvider: React.FC<SocketNotificationProviderProps> = ({ children }) => {
  const { user } = useAuth0();
  const userData = useAppSelector((state) => state.userReducer);
  const tenantData = useAppSelector((state) => state.onboardingReducer);
  const dispatch = useAppDispatch();

  const [readyState, setReadyState] = useState<SocketReadyState>(SocketReadyState.UNINSTANTIATED);
  const [message, setMessage] = useState<NotificationProps>();
  useEffect(() => {
    if (user && userData.id !== -1) {
      console.log("START CONNECTING TO LLM SOCKET...");
      socket.io.opts.query = {
        tenantId: tenantData.tenantDomain,
        userId: user.userId,
      }

      socket.connect();

      socket.on("connect", () => {
        setReadyState(SocketReadyState.OPEN);
        console.log("Connected to Notification socket", Math.random());
        socket.emit("join", { user_id: user.userId, user_name: userData.firstName + " " + userData.lastName });
      })

      socket.on("notification", (message: NotificationProps) => {
        console.log("MESSAGE RECEIVED", message);
        setMessage(message);
        dispatch(addNotification(message));


      })
      socket.on("disconnect", (reason) => {
        if (socket.active) {
          // temporary disconnection, the socket will automatically try to reconnect
        } else {
          // the connection was forcefully closed by the server or the client itself
          // in that case, `socket.connect()` must be manually called in order to reconnect
          console.log(reason);
          setReadyState(SocketReadyState.CLOSED);
        }
      });
    }

    return () => {
      socket.disconnect();
    }
  }, [user, userData]);

  const sendMessage = useCallback((message: any) => {
    console.log("NEW SEND MESSAGE", message);
    socket.emit("notification", {
      ...message,
    });
  }, []);

/*
  const sendJsonMessage: SendJsonMessage = useCallback((message, keep = true) => {
    sendMessage(JSON.stringify(message), keep);
  }, [sendMessage]);
*/

  const value = {
    socket: socket,
    sendSocketMessage: sendMessage,
    message: message,
  }

  return (
    <SocketNotificationContext.Provider value={value}>
      {children}
    </SocketNotificationContext.Provider>
  );
};

export const useSocketNotificationContext = () => useContext(SocketNotificationContext);
