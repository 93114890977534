import React, { FC, useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'


interface GaugeChartProps {
    value: number;
    maxValue?: number;
    width?: number;
    height?: number;
    duration?: number;
}

const GaugeChart : FC<GaugeChartProps> = ({ value, maxValue = 9999, width = 300, height = 150, duration = 1000 }) => {
    const [currentValue, setCurrentValue] = useState(0);
    const radius = width / 2 - 20; // Adjust for padding and marker size
    const centerX = width / 2;
    const centerY = height;

  // Animation logic to transition from 0 to the target value
  useEffect(() => {
    const start = performance.now();
    const animate = (time : number) => {
      const elapsed = time - start;
      const progress = Math.min(elapsed / duration, 1);
      const newValue = Math.round(progress * value);
      setCurrentValue(newValue);
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  }, [value, duration]);

    // Calculate the angle based on the value
    const angle = (currentValue / maxValue) * 180;
    const radian = (angle * Math.PI) / 180;

    // Create the arc path
    const arcPath = `
        M ${centerX - radius}, ${centerY}
        A ${radius}, ${radius} 0 0, 1 ${centerX + radius}, ${centerY}
    `;

    // Inner dashed arc (adjusting for smaller radius)
    const innerRadius = radius - 20;
    const innerArcPath = `
        M ${centerX - innerRadius}, ${centerY}
        A ${innerRadius}, ${innerRadius} 0 0, 1 ${centerX + innerRadius}, ${centerY}
    `;

    const outerRadius = radius + 10;

    const markerX = centerX + innerRadius * Math.cos(radian - Math.PI);
    const markerY = centerY + innerRadius * Math.sin(radian - Math.PI);

    const markerOuterX = centerX + outerRadius * Math.cos(radian - Math.PI);
    const markerOuterY = centerY + outerRadius * Math.sin(radian - Math.PI);

    return (
      <div style={{ width: '100%', height: 'auto' }}>
        {currentValue ?
        <svg
          viewBox={`0 0 ${width} ${height + 20}`}
          width="100%"
          height="auto"
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#5E6064" />
              <stop offset="70%" stop-color="#303339" />
              <stop offset="100%" stop-color="#0E1218" />
            </linearGradient>
          </defs>


          {/* Background Arc */}
          <path
            d={arcPath}
            fill="transparent"
            stroke="url(#gradient)"
            strokeWidth="20"
          />

          {/* Dashed inner arc */}
          <path
            d={innerArcPath}
            fill="transparent"
            stroke="#2d2e33"
            strokeWidth="5"
            strokeDasharray="12,8"
          />

          {/* Marker Line */}
          <line
            x1={markerX}
            y1={markerY}
            x2={markerOuterX}
            y2={markerOuterY}
            stroke="#000"
            strokeWidth="4"
          />

          {/* Marker Circle */}
          <circle cx={markerX} cy={markerY} r="10" fill="#FAFF74" stroke="#707070" strokeWidth="2" />

          {/* Centered Text */}
          {/* Text Position Adjustment */}
          <text
            x={centerX}
            y={height - 30} // Adjusted to be centrally below the gauge
            textAnchor="middle"
            fill="#fff"
            fontSize="24"
            fontWeight="bold"
          >
            {currentValue}
          </text>
        </svg> : <Skeleton
            sx={{ bgcolor: 'grey.900' }}
            variant="rectangular"
            width={"100%"}
            height={150}
          />}
      </div>
    );
};

export default GaugeChart;