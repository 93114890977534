import React, { useEffect, useState } from 'react'
import {
    Badge, Box,
    Dialog,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton,
    List, ListItem,
    ListItemText, Paper, Tooltip,
    Typography, useMediaQuery, useTheme
} from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import { fetchAllEmails } from '../../../utils/api/message'
import {
    ArrowBack as ArrowBackIcon,
    Delete as DeleteIcon,
    Forward as ForwardIcon,
    Reply as ReplyIcon
} from '@mui/icons-material'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
        boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
    },
}));

const EmailListItem = styled(ListItem)(({ theme }) => ({
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const EmailContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
}));
const MessageModal = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false)
    const [conversations, setConversations] = useState<any[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<any>(null);

    useEffect(() => {
        if (open) {
            fetchAllEmails()
                .then((response) => {
                    const data = response.data;
                    const emails = data[1];
                    const conversations = data[0];

                    conversations.forEach((conversation : any) => {
                        conversation.emails = emails.filter((email : any) => email.conversation_id === conversation.id);
                    })

                    console.log("ALL CONVS", conversations, emails);

                    setConversations(conversations);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConversationsClick = (conversation : any) => {
        setSelectedConversation(conversation);
    };

    const handleBackClick = () => {
        setSelectedConversation(null);
    };

    return (
        <>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={handleClickOpen}>
                <Badge badgeContent={4} color="error">
                    <MailIcon />
                </Badge>
            </IconButton>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
            >
                <DialogTitle>Messages</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{
                        minHeight: "50vh",
                    }}
                >
                    <Grid container spacing={2}>
                        {!isMobile && (
                            <Grid item xs={4}>
                                <StyledPaper>
                                    <List>
                                        {conversations.map((conversation : any) => (
                                            <React.Fragment key={conversation.id}>
                                                <EmailListItem
                                                    onClick={() => handleConversationsClick(conversation)}
                                                    selected={selectedConversation && selectedConversation.id === conversation.id}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="subtitle1" noWrap>
                                                                {conversation.emails[0].subject}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography variant="body2" noWrap>
                                                                {conversation.emails[0].sender} - {new Date(conversation.emails[0].createdAt).toLocaleString()}
                                                            </Typography>
                                                        }
                                                    />
                                                </EmailListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </StyledPaper>
                            </Grid>
                        )}
                        <Grid item xs={isMobile ? 12 : 8}>
                            <StyledPaper>
                                {selectedConversation ? selectedConversation.emails.map((email: any) => (
                                    <Box>
                                        {isMobile && (
                                            <IconButton onClick={handleBackClick} aria-label="go back">
                                                <ArrowBackIcon />
                                            </IconButton>
                                        )}
                                        <Typography variant="h5" gutterBottom>
                                            {email.subject}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            From: {email.sender}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {new Date(email.createdAt).toLocaleString()}
                                        </Typography>
                                        <Divider sx={{ my: 2 }} />
                                        <EmailContent>
                                            <Typography variant="body1">{email.body}</Typography>
                                        </EmailContent>
                                        <Box sx={{ mt: 2 }}>
                                            <Tooltip title="Reply">
                                                <IconButton aria-label="reply">
                                                    <ReplyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Forward">
                                                <IconButton aria-label="forward">
                                                    <ForwardIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                )) : (
                                    <Typography variant="body1">
                                        {isMobile ? "Select an email from the list above" : "Select an email to view its content"}
                                    </Typography>
                                )}
                            </StyledPaper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MessageModal