import { io } from 'socket.io-client'

// Standard Notification socket
// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:5006/notification';

console.log("URL", URL);

export const notificationSocket = io(URL, {
  autoConnect: false,
  query: {
    tenantId: 'tenant_dummy'
  }
})

// LLM Socket connection - ONLY USE FOR THIS
const LLM_URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:5003';

export const llmSocket = io(LLM_URL, {
  autoConnect: false,
  query: {
    tenantId: 'tenant_dummy'
  }
})