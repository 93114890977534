import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography
} from '@mui/material'
import { styled } from '@mui/system'
import { updateUserData } from '../../utils/api/user'
import { useAppSelector } from '../../state/stateHooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { useAuth0 } from '@auth0/auth0-react'

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  transition: "all 0.3s ease-in-out",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  marginBottom: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
  '&:hover': {
    transform: "translateY(-2px)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
  '&:hover': {
    transform: "translateY(-2px)",
  },
}));

const currentDate = new Date();
const min18YearsOld = new Date(currentDate.setFullYear(currentDate.getFullYear() - 18));

const userProfileValidationSchema = Yup.object({
  address: Yup.string().notRequired(),
  birthDate: Yup.date().min(min18YearsOld).max(new Date()).notRequired(),
  city: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  email: Yup.string().email().required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().notRequired(),
  username: Yup.string().required('Username is required'),
  zipCode: Yup.string().notRequired(),
});

const AccountPage = () => {
  const { user } = useAuth0();
  const userData = useAppSelector((state) => state.userReducer);

  console.log("USER", user, userData)

  const formik = useFormik({
    initialValues: {
      address: userData.address,
      birthDate: userData.birthDate,
      city: userData.city,
      country: userData.country,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      username: userData.username,
      zipCode: userData.zipCode,
      notification: true,
      language: "english",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: userProfileValidationSchema,
    onSubmit: async (values) => {
      console.log("SAVE", values);

      try {
        await updateUserData(user?.userId, values)
      } catch (e) {
        console.error("Error Updating User", e)
      }

    }
  })

  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [saving, setSaving] = useState(false);

  const handleImageChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setImage(files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  console.log(formik.errors);


  return (
    <LeftMenuRightChatLayout
      container={true}
      chatIntegrated={true}
      title={"User Profile"}
    >
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <StyledAvatar src={imagePreview || "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80"} alt={formik.values.firstName} />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="image-upload"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="image-upload">
                <StyledButton
                  variant="contained"
                  color="primary"
                >
                  Upload Image
                </StyledButton>
              </label>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Profile Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Firstname"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  fullWidth
                  label="Lastname"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{
                    width: '100%'
                  }}
                  label="Birthday"
                  name="birthDate"
                  value={formik.values.birthDate}
                  onChange={formik.handleChange}
                />
              </DemoContainer>
            </LocalizationProvider>

            <StyledTextField
              fullWidth
              sx={{
                marginTop: '16px'
              }}
              label="Address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <StyledTextField
                  label="Zip"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledTextField
                  fullWidth
                  label="City"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <StyledTextField
                  fullWidth
                  label="Country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
            </Grid>

            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              type="email"
            />
            <StyledTextField
              fullWidth
              label="Phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
        </Grid>
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Preferences
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                fullWidth
                select
                label="Language"
                name="language"
                value={formik.values.language}
                onChange={formik.handleChange}
              >
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="spanish">Spanish</MenuItem>
                <MenuItem value="french">French</MenuItem>
                <MenuItem value="german">German</MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.notification}
                    onChange={formik.handleChange}
                    value="enabled"
                    name="notification"
                    color="primary"
                  />
                }
                label="Enable Notifications"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <StyledButton
            variant="contained"
            color="primary"
            onClick={formik.submitForm}
            disabled={saving}
            startIcon={saving && <CircularProgress size={20} color="inherit" />}
          >
            {saving ? "Saving..." : "Save Changes"}
          </StyledButton>
        </Box>

      </Box>

    </LeftMenuRightChatLayout>
  );
};

export default AccountPage;
