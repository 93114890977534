import { combineReducers } from '@reduxjs/toolkit'
import onboardingReducer from './onboardingSlice'
import conversationReducer from './conversationSlice'
import userReducer from './userSlice'
import notificationReducer from './notificationSlice'

export default combineReducers({
    onboardingReducer,
    userReducer,
    conversationReducer,
    notificationReducer
})