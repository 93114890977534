import * as React from 'react'

interface IAlertIcon {

}

const AlertIcon : React.FC<IAlertIcon> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14.579}
        height={16.25}
        viewBox="0 0 14.579 16.25"
        {...props}
    >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #555f75;\n        fill-rule: evenodd;\n      }\n    "
                }
            </style>
        </defs>
        <g
            id="Gruppe_793"
            data-name="Gruppe 793"
            transform="translate(-3510.71 1064.125)"
        >
            <path
                id="Pfad_630"
                data-name="Pfad 630"
                className="cls-1"
                d="M1302,30.125a1.051,1.051,0,0,0-.74.305,1.036,1.036,0,0,0-.3.726.605.605,0,0,1-.1.345.632.632,0,0,1-.19.189.325.325,0,0,1-.08.047,5.272,5.272,0,0,0-2.1,1.814,5.152,5.152,0,0,0-.86,2.631v2.485c0,.025-.01.05-.01.075a3.833,3.833,0,0,1-.64,1.72.343.343,0,0,1-.05.08h10.14a.343.343,0,0,1-.05-.08,3.833,3.833,0,0,1-.64-1.72v-2.56a5.21,5.21,0,0,0-2.98-4.45.624.624,0,0,1-.36-.565,1.033,1.033,0,0,0-1.04-1.042Zm-2.26.64a2.387,2.387,0,0,1,.64-1.219,2.291,2.291,0,0,1,3.24,0,2.387,2.387,0,0,1,.64,1.219,6.52,6.52,0,0,1,2.29,2.092,6.406,6.406,0,0,1,1.07,3.28c0,.01.01.019.01.029v2.46a2.626,2.626,0,0,0,.43,1.141,2.715,2.715,0,0,0,.91.854.624.624,0,0,1-.3,1.169h-13.34a.624.624,0,0,1-.3-1.169,2.715,2.715,0,0,0,.91-.854,2.773,2.773,0,0,0,.44-1.141v-2.49a6.406,6.406,0,0,1,1.07-3.28A6.52,6.52,0,0,1,1299.74,30.765Z"
                transform="translate(2216 -1093)"
            />
            <path
                id="Pfad_631"
                data-name="Pfad 631"
                className="cls-1"
                d="M1299.5,40.542a.626.626,0,0,1,.63.625V42a1.873,1.873,0,0,0,.54,1.326A1.88,1.88,0,0,0,1303.88,42v-.833a.625.625,0,1,1,1.25,0V42a3.125,3.125,0,1,1-6.25,0v-.833A.624.624,0,0,1,1299.5,40.542Z"
                transform="translate(2216 -1093)"
            />
        </g>
    </svg>
);
export default AlertIcon;
