import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../state/stateHooks'
import instance from '../../utils/api'
import GoCardlessModal from './gocardless/GoCardlessModal'
import { v4 } from 'uuid'

const IntegrationBankAccountDemoField = () => {
  const [loading, setLoading] = useState(false);
  const data = useAppSelector(state => state.onboardingReducer)
  const location = useLocation();

  useEffect(() => {
    console.log("PARAMS", location, data)

    if (location.pathname === "/integration/bank-account/callback/demo") {
      setLoading(true);

      instance.get("/api/v1/tenant/bank-account/callback?code=demo&credentials_id=" + v4())
        .then(res => {
          setLoading(false);
          console.log("CALLBACK DEMO CONNECT", res.data)
        })
        .catch(err => {
          console.log("CALLBACK ERROR", err)
        })
    }

  }, [location.pathname, data.tenantName]);

  return (
      <>
          <GoCardlessModal demo={true} />
      </>
  );
};

export default IntegrationBankAccountDemoField;
