import React from 'react'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { Box, Grid, Typography } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { DashboardCard } from '../../components/Cards'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

const TaxOverviewPage = () => {
    return (
        <LeftMenuRightChatLayout
            container={true}
            chatIntegrated={true}
            title={"Tax Overview"}
        >
            <Box
                className="main-container"
            >
                <Grid container>
                    <Grid item xs={12}>
                        <DashboardCard
                            backgroundColor={"#000"}
                            sx={{
                                color: '#fff',
                                height: '100%',
                                padding: '20px',
                                "*": {
                                    color: '#fff'
                                },

                                ".MuiPickersDay-root": {
                                    color: '#fff'
                                }
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                textAlign: 'left'
                                            }}
                                        >Next relevant tax dates.</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <DateCalendar
                                            value={moment()}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </DashboardCard>

                    </Grid>
                </Grid>
            </Box>
        </LeftMenuRightChatLayout>
    )
}

export default TaxOverviewPage