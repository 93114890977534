import React from 'react'
import { Box, styled, Typography } from '@mui/material'

export interface ISmallInfoCard {
    subheader: string,
    header: string,
    badge: string
}

const SmallInfoCardBox = styled(Box)({
    position: 'relative',
    paddingLeft: '10px',

    '&:before': {
        position: 'absolute',
        left: 0,
        top: '25%',
        content: '""',
        height: '50%',
        width: '2px',
        background: '#6438F5'
    }
})

const SmallInfoCard: React.FC<ISmallInfoCard> = (props) => {
    return (
        <SmallInfoCardBox
            sx={{
                textAlign: 'left',
            }}
        >

        <Typography
            sx={{
                color: '#323741',
                fontSize: '12px'
            }}
        >{props.subheader}</Typography>
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography
                sx={{
                    color: '#000',
                    fontSize: '18px'
                }}
            >{props.header}</Typography>
            <Box
                sx={{
                    background: '#E1F4EF',
                    padding: '2px 6px',
                    fontSize: '12px',
                    borderRadius: '25px',
                    marginLeft: '8px'
                }}
            >
                {props.badge}
            </Box>
        </Box>
        </SmallInfoCardBox>
    );
};

export default SmallInfoCard;