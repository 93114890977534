import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {ONBOARDING } from '../../routes/routes';
import Authentication from './Authentication';
import { fetchAdminData } from '../../utils/api/admin';
import {
  setActiveStep,
  setAdminInfos,
  setAdminInfosSaved,
  setCompanyInfos,
  setCompanyInfosSaved, setFinishedOnboarding, setLoadingOnboardingData, setTenantInfos,
} from '../../state/reducer/onboardingSlice';
import { fetchCompany, fetchTenant } from '../../utils/api/instance'
import { useAppDispatch } from '../../state/stateHooks';
import { setUserData } from '../../state/reducer/userSlice';
import { fetchUserMasterData } from '../../utils/api/user';
import { useAuth0 } from '@auth0/auth0-react'

interface IAuthenticationCheck {
  children: React.ReactNode;
}
const AuthenticationCheck: React.FC<IAuthenticationCheck> = (props) => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("AuthenticationCheck:USER", user);

    const checkOnboardingStatus = async () => {
      const userData = await fetchAdminData(user?.userId || user?.sub);
      const userDetailData = await fetchUserMasterData(user?.userId || user?.sub);

      console.log("CURRENT USER DATA", userData)

      if (userData) {
        dispatch(setAdminInfos({
          authId: userData.authId,
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email
        }))

        dispatch(setAdminInfosSaved({
          id: userData.id
        }))

        if (userData.company) {
          const companyId = userData.company;
          const tenantId = userData.tenantId;

          const company = await fetchCompany(companyId);

          if (company) {
            dispatch(setCompanyInfos(company));
            dispatch(setCompanyInfosSaved({
              id: companyId
            }));

            dispatch(setActiveStep(1));

            if (tenantId) {
              const tenant = await fetchTenant(tenantId);

              console.log("FOUND TENANT", tenant);

              if (tenant) {
                dispatch(setTenantInfos(tenant));
                dispatch(setActiveStep(2));

                if (tenant.isActive && tenant.onboardingStatus !== "SUCCESS") {
                  dispatch(setActiveStep(3));
                } else if (tenant.isActive && tenant.onboardingStatus === "SUCCESS") {
                  dispatch(setFinishedOnboarding());
                  dispatch(setUserData(userDetailData));
                }
              }
            }
          }

          dispatch(setLoadingOnboardingData(false))
        }
      }
    }
    const checkTenantStatus = async ()  => {
      try {
        const userData = await fetchAdminData(user?.userId || user?.sub);
        const tenantId = userData?.tenantId;

        if (!tenantId) {
          // Redirect to onboarding
          navigate(ONBOARDING);
        } else {
          //await checkUserAlreadyExists();
          // Check if tenant is valid
        }
      } catch (error) {
        console.error('Error checking tenant status:', error);
        await logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }
    }

    if (user) {
      setLoading(true)
      checkTenantStatus()
          .then(async () => {
            await checkOnboardingStatus()
          })
          .catch((error) => {
            console.error('Error checking onboarding status:', error);
          })
          .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Authentication />;
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default AuthenticationCheck;
