import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import LeftMenuLayout from '../../../layout/LeftMenuLayout'
import IntegrationBankAccountField from '../../../components/Integration/IntegrationBankAccount.Field'


const SettingsGeneralPage = () => {
  return (
    <Box>
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Integration</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="content-container"
        sx={{
          padding: '20px'
        }}>
        <Grid container>
          <Grid item xs={4}>


          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SettingsGeneralPage;
