import React, { useEffect, useState } from 'react'
import {
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import {
    ArrowBack as ArrowBackIcon,
    Delete as DeleteIcon,
    Forward as ForwardIcon,
    Reply as ReplyIcon
} from '@mui/icons-material'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { fetchAllEmails } from '../../utils/api/message'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
  },
}));

const EmailListItem = styled(ListItem)(({ theme }) => ({
  cursor: "pointer",
  transition: "background-color 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EmailContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const MessagePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [conversations, setConversations] = useState<any[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  console.log(selectedConversation?.emails)

  useEffect(() => {
    fetchAllEmails()
      .then((response) => {
        const data = response.data;
        const emails = data[1];
        const conversations = data[0];

        conversations.forEach((conversation : any) => {
          conversation.emails = emails.filter((email : any) => email.conversation_id === conversation.id);
        })

        console.log("ALL CONVS", conversations, emails);

        setConversations(conversations);
      })
  }, []);

  // Dummy data for emails
  const emails = [
    {
      id: 1,
      sender: "John Doe",
      subject: "Meeting Reminder",
      content: "Don't forget our team meeting tomorrow at 10 AM.",
      timestamp: "2023-06-01T09:00:00Z",
    },
    {
      id: 2,
      sender: "Jane Smith",
      subject: "Project Update",
      content: "I've completed the first phase of the project. Please review and provide feedback.",
      timestamp: "2023-05-31T16:30:00Z",
    },
    {
      id: 3,
      sender: "Marketing Team",
      subject: "New Campaign Ideas",
      content: "We've brainstormed some exciting ideas for our next marketing campaign. Let's discuss them in our next meeting.",
      timestamp: "2023-05-30T11:15:00Z",
    },
  ];

  const handleConversationsClick = (conversation : any) => {
    setSelectedConversation(conversation);
  };

  const handleBackClick = () => {
    setSelectedConversation(null);
  };

  const handleSearchChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSortClick = (event : React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };

  const filteredEmails = emails.filter(
    (email) =>
      email.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
      email.subject.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <LeftMenuLayout
      container={true}
      chatIntegrated={false}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Message (Mail) Manager</Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {!isMobile && (
          <Grid item xs={4}>
            <StyledPaper>
              <List>
                {conversations.map((conversation : any) => (
                  <React.Fragment key={conversation.id}>
                    <EmailListItem
                      onClick={() => handleConversationsClick(conversation)}
                      selected={selectedConversation && selectedConversation.id === conversation.id}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" noWrap>
                            {conversation.emails[0].subject}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" noWrap>
                            {conversation.emails[0].sender} - {new Date(conversation.emails[0].createdAt).toLocaleString()}
                          </Typography>
                        }
                      />
                    </EmailListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </StyledPaper>
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 8}>
          <StyledPaper>
            {selectedConversation ? selectedConversation.emails.map((email: any) => (
              <Box>
                {isMobile && (
                  <IconButton onClick={handleBackClick} aria-label="go back">
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Typography variant="h5" gutterBottom>
                  {email.subject}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  From: {email.sender}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  {new Date(email.createdAt).toLocaleString()}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <EmailContent>
                  <Typography variant="body1">{email.body}</Typography>
                </EmailContent>
                <Box sx={{ mt: 2 }}>
                  <Tooltip title="Reply">
                    <IconButton aria-label="reply">
                      <ReplyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Forward">
                    <IconButton aria-label="forward">
                      <ForwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )) : (
              <Typography variant="body1">
                {isMobile ? "Select an email from the list above" : "Select an email to view its content"}
              </Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

    </LeftMenuLayout>
  );
};

export default MessagePage;