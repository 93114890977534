import React from 'react'
import { Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTrendUp, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'


interface ISimpleInformationBox {
    header: string,
    badge?: string,
    value: string,
    from?: string,
    sx?: SxProps<Theme>
}

const SimpleInformationBox: React.FC<ISimpleInformationBox> = (props) => {
    return (
        <Box
            sx={{
                color: '#fff',
                textAlign: 'left',
                padding: '10px',

                ...props.sx
            }}
        >
            <Box sx={{
                display: 'inline-flex',
                alignItems: 'center',
                textAlign: 'left',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textAlign: 'left'
                }}>
                    <Box sx={{
                        marginLeft: '10px',
                        marginRight: '10px'
                    }}>
                        <FontAwesomeIcon icon={faBuildingColumns} />
                    </Box>
                    <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'center',

                    }}>
                        <Typography>{props.header}</Typography>
                        {props.badge && <Typography sx={{
                            background: '#E1F4EF',
                            color: '#29896E',
                            borderRadius: '6px',
                            padding: '2px 6px',
                            marginLeft: '1.3em',
                            fontSize: '13px'
                        }}>{props.badge}</Typography>}
                    </Box>
                </Box>
                <Box sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                }}>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                </Box>
            </Box>
            <Box
                sx={{
                    marginLeft: '10px',
                    marginTop: '15px'
                }}
            >
                <Typography sx={{
                    fontSize: '1.5rem',
                    textAlign: 'left',
                    color: '#fff',
                    fontWeight: 'bold',
                }}>{props.value}</Typography>
            </Box>
            {props.from && <Box
                sx={{
                    marginLeft: '10px',
                }}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    textAlign: 'left',
                    color: '##DDDDD',
                }}>{props.from}</Typography>
            </Box>}
        </Box>
    );
};

export default SimpleInformationBox;