import React, { useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './application/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import { DevSupport } from '@react-buddy/ide-toolbox'
import { ComponentPreviews, useInitial } from './dev'
import { createTheme, ThemeProvider } from '@mui/material'
import { theme } from './utils/theme'
import './assets/scss/style.scss'

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }

    interface TypeBackground {
        chat: string;
    }
    interface TypeBackgroundOptions {
        chat?: string;
    }
}
export default function ToggleColorMode() {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                    primary: {
                        main: '#F1AE40'
                    },
                    secondary: {
                        main: '#6438F5'
                    },
                    background: {
                        chat: '#ffffff'
                    },
                },
                typography: {
                    h1: {
                        fontSize: '2.125rem',
                    },
                    h2: {
                        fontSize: '1.875rem',
                    },
                    h3: {
                        fontSize: '1.5rem',
                    },
                    h4: {
                        fontSize: '1.25rem',
                    },
                    h5: {
                        fontSize: '1.125rem'
                    },
                    h6: {
                        fontSize: '1rem'
                    }
                }
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ToggleColorMode />
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
