import React, { ReactNode } from 'react'
import { Box, Container, CssBaseline, Drawer, Fab, Grid, Modal } from '@mui/material'
import classNames from 'classnames'
import Logo from '../assets/image/CyronAILogoB.png'
import { MainMenu } from '../components/MainMenu/MainMenu'
import { UserMenuAvatar } from '../components/UserMenuAvatar/UserMenuAvatar'
import MainHeader from '../components/Header/MainHeader'
import CyronIcon from '../assets/image/Cyron_ohne_bg_icon.png'
import SimpleChat from '../components/Chat/SimpleChat'
import { useAppSelector } from '../state/stateHooks'

interface ILeftMenuLayout {
  className?: any,
  header?: ReactNode,
  sidebar?: ReactNode,
  container?: boolean
  children: ReactNode
  chatIntegrated?: boolean
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1200,
  bgcolor: '#000',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '25px',
  p: 4,
};
const LeftMenuLayout : React.FC<ILeftMenuLayout> = (props) => {
  const data = useAppSelector((state) => state.onboardingReducer);
  const userData = useAppSelector((state) => state.userReducer);

  console.log("LEFT MENU USER DATA", data);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                minHeight: '100vh',
                background: 'url(/assets/image/StandardBackground.jpeg) rgba(0, 0, 0, 0.3)',
                backgroundSize: 'cover',
                backgroundBlendMode: 'multiply',
        }}
            className={classNames("LeftMenuLayout", props.className)}
        >
            <CssBaseline />
            {/** Drawer Section **/}
            <Drawer
                sx = {{
                    width: 240,
                    flexShrink: 0,
                    marginRight: '20px',
                    '& .MuiDrawer-paper': {
                        width: 240,
                        boxSizing: 'border-box',
                    },
                }}
                className="main_drawer"
                variant={"permanent"}
                anchor={"left"}
            >

                <div className="header">
                    <div className="logo">
                        <img src={Logo} alt="CyronAI Logo" />
                    </div>
                </div>

                <div className="balance_dropdown">

                </div>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    height: '100%',
                    justifyContent:  'space-between'
                }}>
                    <MainMenu />

                    <UserMenuAvatar userData={{
                        name: userData.firstName + ' ' + userData.lastName,
                        email: userData.email || ""
                    }} />
                </Box>

            </Drawer>
            {/** Main Header Section **/}
            <Box
                sx={{
                    height: '100%'
                }}
            >
                <MainHeader />

                {/** Sidebar Section **/}
                {props.sidebar ?
                    <Grid container>
                        <Grid item xs={10}>
                            <Box>
                                {props.container ? <Container>{props.children}</Container> : <>{props.children}</>}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="sidebar">
                                {props.sidebar}
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Box>
                        {props.container ? <Container
                            maxWidth={'xl'}
                            sx={{
                                minWidth: '250px',
                            }}
                        >{props.children}</Container> : <>{props.children}</>}
                    </Box>
                }
            </Box>

          {!props.chatIntegrated ?
            <>
              <Fab
                color="primary"
                aria-label="add"
                sx={{
                  position: 'fixed',
                  bottom: '30px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  background: '#000',
                  padding: '10px',

                  "&:hover": {
                    background: '#505050'
                  }
                }}
                onClick={handleOpenModal}
              >
                <img src={CyronIcon} alt={"Cyron Icon"} width={"100%"} />
                {/*<AddIcon />*/}
              </Fab>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <SimpleChat />
                </Box>
              </Modal>
            </>
            : <></>
          }
        </Box>
    );
};

export default LeftMenuLayout;
