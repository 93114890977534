import instance from './index'
import { ChatConversationProps } from '../../interface/ChatProps'
import { ConversationProps } from '../../state/reducer/conversationSlice'
import { ActionExecutionMessage, ResultMessage, TextMessage } from "@copilotkit/runtime-client-gql";
import moment from 'moment'


const getAllConversations = async (userId: string): Promise<ChatConversationProps[] | undefined> => {
    try {
        const response = await instance.get<ChatConversationProps[]>(`/api/v1/chat/conversations`, {
            params: {
                userID: userId
            }
        })

        return response.data
    } catch (error) {
        console.log(error)
    }
}

const getConversation = async (conversationId: number, userId: string) => {
    console.log("GET CONVERSATION", conversationId, userId)
    try {
        const response = await instance.get(`/api/v1/chat/conversations/${conversationId}`, {
            params: {
                userID: userId
            }
        })

        return response.data
    }
    catch (error) {
        console.log(error)
    }
}

const deleteConversation = async (conversationId: number, userId: string) => {
    try {
        const response = await instance.delete(`/api/v1/chat/conversations/${conversationId}`, {
            params: {
                userID: userId
            }
        })

        return response.data
    }
    catch (error) {
        console.log(error)
    }
}

const parseConversationChat = (conversation: ChatConversationProps) => {

    if (conversation.chatMessages) {
        const parsedMessages = conversation.chatMessages.map((message: any) => {
            if (message.type === "TextMessage") {
                return new TextMessage({
                    id: message.id,
                    role: message.role,
                    content: message.content,
                    createdAt: message.createdAt,
                });
            } else if (message.type === "ActionExecutionMessage") {
                return new ActionExecutionMessage({
                    id: message.id,
                    name: message.name,
                    scope: message.scope,
                    arguments: message.arguments,
                    createdAt: message.createdAt,
                });
            } else if (message.type === "ResultMessage") {
                return new ResultMessage({
                    id: message.id,
                    actionExecutionId: message.actionExecutionId,
                    actionName: message.actionName,
                    result: message.result,
                    createdAt: message.createdAt,
                });
            } else {
                throw new Error(`Unknown message type: ${message.type}`);
            }
        });

        return parsedMessages.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
    }

    return [];
}

export {
    getAllConversations,
    getConversation,
    deleteConversation,
    parseConversationChat
}
