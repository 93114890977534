import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton, Link,
  List,
  ListItem, ListItemButton, ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { downloadFile, fetchAllReports, fetchFiles, fetchReport } from '../../utils/api/file'
import { FileProps } from '../../interface/IFile'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { useSnackbar } from 'notistack'
import { ReportProps } from '../../interface/ReportProps'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NavLink, useLocation, useParams } from 'react-router-dom'
import {
  BoldItalicUnderlineToggles,
  headingsPlugin,
  InsertTable,
  MDXEditor,
  tablePlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  transition: "box-shadow 0.3s",
  /*"&:hover": {
    boxShadow: theme.shadows[4],
  },*/
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));
const ReportDetailPage = () => {
  const [report, setReport] = useState<ReportProps>();

  const { reportId } = useParams();

  useEffect(() => {
    if (reportId) {
        fetchReport(reportId)
            .then((response) => {
            if (response.data) {
              console.log("REPORT", response.data)
                setReport(response.data);
            }
            })
    }
  }, [reportId]);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  return (
    <LeftMenuRightChatLayout
      container={true}
      chatIntegrated={true}
      title={"Reports"}
    >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.5rem',
              textAlign: 'left',
              color: '#000'
            }}>Report Editor</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className="findme">
        {report && report.sections && report.sections.sort((s1, s2) => s1.position - s2.position).map((section, index) => (
            <Box key={section.id} sx={{
              marginBottom: '20px',
              textAlign: 'left',
            }}>
              {/*<Typography variant="h2" sx={{
                fontSize: '1.2rem',
                textAlign: 'left',
                color: '#000'
              }}>{section.title}</Typography>*/}
              <MDXEditor
                  markdown={section.text}
                  plugins={[
                    tablePlugin(),
                    headingsPlugin(),
                    toolbarPlugin({
                      toolbarClassName: 'my-classname',
                      toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <BoldItalicUnderlineToggles />
                            <InsertTable />
                          </>
                      )
                    })
                  ]}
              />
            </Box>
        ))}
      </Box>


    </LeftMenuRightChatLayout>
  );
};

export default ReportDetailPage;