import React from 'react';
import './App.css';
import NavigationLinks from "../routes/NavigationLinks";
import AuthenticationCheck from '../components/Authentication/AuthenticationCheck';
import FullScreenDropzone from '../components/Dropzone/FullScreenDropzone'
import { SnackbarProvider } from 'notistack'
import { SocketNotificationProvider } from '../context/SocketNotificationContext'
import 'intro.js/introjs.css';
import Auth0ProviderHolder from '../components/AuthO/Auth0ProviderHolder'
import "@copilotkit/react-ui/styles.css";
import CopilotKitHolder from '../context/CopilotKitHolder'

function App() {

  return (
    <div className="App">
        <Auth0ProviderHolder>
            <CopilotKitHolder>
                <SocketNotificationProvider>
                    <SnackbarProvider preventDuplicate={true}>
                        <AuthenticationCheck>
                            <FullScreenDropzone />
                            <NavigationLinks />
                        </AuthenticationCheck>
                    </SnackbarProvider>
                </SocketNotificationProvider>
            </CopilotKitHolder>
        </Auth0ProviderHolder>
    </div>
  );
}

export default App;
