import React, { useRef } from 'react'
import classNames from 'classnames'
import HighchartsReact, { HighchartsReactProps } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import SankeyModule from 'highcharts/modules/sankey'
import { incomeType, revenueType } from './IncomeType'
import { costType, expensesType } from './ExpensesType'

interface ISankeyChart extends HighchartsReactProps{
    className?: any,
    data: any
}

// Register the Sankey module
SankeyModule(Highcharts);

const SankeyChart : React.FC<ISankeyChart> = (props) => {
    let allIncomeStatements = props.data.bankAccountModelList.filter((item: any) => item.type === 'Income');
    let allExpanseStatements = props.data.bankAccountModelList.filter((item: any) => item.type === 'Expenses' && item.slug !== "corporate_taxes" && item.parent !== "corporateTaxes");
    const totalIncomeSum = allIncomeStatements.reduce((acc: any, cur: any) => acc + cur.sum, 0);
    const totalExpanseSum = allExpanseStatements.reduce((acc: any, cur: any) => acc + cur.sum, 0);

    allIncomeStatements = allIncomeStatements.map((item: any) => ({
        ...item,
        percentage: (item.sum / totalIncomeSum) * 100
    }))
      .map((item: any) => ([item.slug, item.child, item.percentage]));

    allExpanseStatements = allExpanseStatements
        .filter((item: any) => item.parent !== undefined)
        .map((item: any) => ({
            ...item,
            percentage: (item.sum / totalExpanseSum) * 100
    }))
      .map((item: any) => ([item.parent, item.slug, item.percentage]));

    console.log("SankeyChart", allIncomeStatements, allExpanseStatements);

    const grossProfit = props.data.grossProfit;
    const costOfGoodsSold = props.data.costOfGoodsSold;

    const grossProfitToGoodsPercentage = (grossProfit / props.data.totalRevenue) * 100;
    const costsOfGoodsSoldToGrossProfitPercentage = (costOfGoodsSold / props.data.totalRevenue) * 100;

    const operatingIncome = props.data.operatingIncome;
    const totalOperatingExpenses = props.data.totalOperatingExpenses;

    const operatingIncomePercentage = ((operatingIncome > 0 ? operatingIncome : 0) / grossProfit) * 100;
    const totalOperatingExpensesPercentage = (totalOperatingExpenses / grossProfit) * 100;

    const earningsBeforeTaxes = props.data.earningsBeforeTaxes;


    const corporateTaxes = props.data.corporateTaxes;
    const netIncome = props.data.netIncome;

    const corporateTaxesPercentage = ((operatingIncome > 0 ? corporateTaxes : 0) / earningsBeforeTaxes) * 100;
    const netIncomePercentage = ((operatingIncome > 0 ? netIncome : 0) / earningsBeforeTaxes) * 100;

    const employeeCost = props.data.employeeCost;
    const officeCost = props.data.officeCost;
    const marketingConst = props.data.marketingCost;
    const technologyCost = props.data.technologyCost;
    const professionalFees = props.data.professionalFees;
    const travelAndTrainingCost = props.data.travelAndTrainingCost;
    const insuranceRiskCost = props.data.insuranceRiskCost;
    const otherExpenses = props.data.otherExpenses;

    const employeeCostPercentage = (employeeCost / totalOperatingExpenses) * 100;
    const officeCostPercentage = (officeCost / totalOperatingExpenses) * 100;
    const marketingConstPercentage = (marketingConst / totalOperatingExpenses) * 100;
    const technologyCostPercentage = (technologyCost / totalOperatingExpenses) * 100;
    const professionalFeesPercentage = (professionalFees / totalOperatingExpenses) * 100;
    const travelAndTrainingCostPercentage = (travelAndTrainingCost / totalOperatingExpenses) * 100;
    const insuranceRiskCostPercentage = (insuranceRiskCost / totalOperatingExpenses) * 100;
    const otherExpensesPercentage = (otherExpenses / totalOperatingExpenses) * 100;



    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

console.log("data", props.data, corporateTaxesPercentage, netIncomePercentage, [
    ...allIncomeStatements,

    ...allIncomeStatements.map((item: any) => ([item[1], 'totalRevenue', item[2]])),

    isFinite(grossProfitToGoodsPercentage) && ['totalRevenue', 'grossProfit', grossProfitToGoodsPercentage],
    isFinite(costsOfGoodsSoldToGrossProfitPercentage) && ['totalRevenue', 'costsOfGoodsSold', costsOfGoodsSoldToGrossProfitPercentage],

    isFinite(totalOperatingExpensesPercentage) && ['grossProfit', 'totalOperatingExpenses', totalOperatingExpensesPercentage],
    isFinite(operatingIncomePercentage) && ['grossProfit', 'operatingIncome', operatingIncomePercentage],

    ['totalOperatingExpenses', 'employeeCost', employeeCostPercentage],
    ['totalOperatingExpenses', 'officeCost', officeCostPercentage],
    ['totalOperatingExpenses', 'marketingCost', marketingConstPercentage],
    ['totalOperatingExpenses', 'technologyCost', technologyCostPercentage],
    ['totalOperatingExpenses', 'professionalFees', professionalFeesPercentage],
    ['totalOperatingExpenses', 'travelAndTrainingCost', travelAndTrainingCostPercentage],
    ['totalOperatingExpenses', 'insuranceRiskCost', insuranceRiskCostPercentage],
    ['totalOperatingExpenses', 'otherExpenses', otherExpensesPercentage],

    ...allExpanseStatements,

    corporateTaxesPercentage > 0 && ['operatingIncome', 'corporateTaxes', corporateTaxesPercentage],
    netIncomePercentage > 0 && ['operatingIncome', 'netIncome', netIncomePercentage],

    //corporateTaxesPercentage > 0 && ['operatingIncome', 'corporateTaxes', corporateTaxesPercentage],
    //netIncomePercentage > 0 && ['operatingIncome', 'netIncome', netIncomePercentage],


  ],)


    const options: Highcharts.Options = {
        chart: {
          type: 'sankey',
          backgroundColor: '#000',
          borderRadius: 25,
        },
        title: {
            text: 'Your P&L',
            style: {
                color: '#fff'
            }
        },
        subtitle: {
          text: 'Smart financial planning starts with understanding your P&L',
          style: {
            color: '#fff'
          }
        },
        accessibility: {
            point: {
                valueDescriptionFormat: '{index}. {point.from} to {point.to}, ' +
                  '{point.weight}.'
            }
        },
        tooltip: {
            pointFormat:
              '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:.2f} ' +
              'quads',
            //nodeFormat: '{point.name}: {point.sum:.2f} quads'
        },

        series: [{
            keys: ['from', 'to', 'weight'],

            nodes: [
              // Revenue details NODE COLUMN 1
              ...incomeType.map(type => ({
                  ...type,
                  dataLabels: {
                    enabled: true,
                    align: 'left',
                    color: '#ffffff',
                    style: {
                      fontSize: '12px'
                    }
                  }
                }
              )),


              // Revenue NODE COLUMN 2
              ...revenueType.map(type => ({
                  ...type,
                  dataLabels: {
                    enabled: true,
                    align: 'left',
                    color: '#ffffff',
                    style: {
                      fontSize: '12px'
                    }
                  }
                }
              )),

              // Revenue NODE COLUMN 3 - 7
                {
                    name: 'Total Revenue',
                    id: 'totalRevenue',
                    color: '#33cb4b',
                    dataLabels: {
                      enabled: true,
                      align: 'left',
                      color: '#ffffff',
                      style: {
                        fontSize: '12px'
                      }
                    }
                },
                {
                    name: 'Gross Profit',
                    id: 'grossProfit',
                    color: '#33cb4b',
                    dataLabels: {
                      enabled: true,
                      align: 'left',
                      color: '#ffffff',
                      style: {
                        fontSize: '12px'
                      }
                    }
                },
                {
                    name: 'Operating Income',
                    id: 'operatingIncome',
                    color: '#33cb4b',
                    dataLabels: {
                      enabled: true,
                      align: 'left',
                      color: '#ffffff',
                      style: {
                        fontSize: '12px'
                      }
                    }
                },
                {
                    name: 'Earnings before Taxes',
                    id: 'earningsBeforeTaxes',
                    color: '#33cb4b',
                    dataLabels: {
                      enabled: true,
                      align: 'left',
                      color: '#ffffff',
                      style: {
                        fontSize: '12px'
                      }
                    }
                },
                {
                    name: 'Net Income',
                    id: 'netIncome',
                    color: '#33cb4b',
                    dataLabels: {
                      enabled: true,
                      align: 'left',
                      color: '#ffffff',
                      style: {
                        fontSize: '12px'
                      }
                    }
                },

              // Expenses details NODE COLUMN 1
              ...expensesType.map(type => ({
                  ...type,
                  dataLabels: {
                    enabled: true,
                    align: 'left',
                    color: '#ffffff',
                    style: {
                      fontSize: '12px'
                    }
                  }
                }
              )),

              // Expenses NODE COLUMN 2
              ...costType.map(type => ({
                  ...type,
                  dataLabels: {
                    enabled: true,
                    align: 'left',
                    color: '#ffffff',
                    style: {
                      fontSize: '12px'
                    }
                  }
                }
              )),

              // Expenses NODE COLUMN 3 - 7
                {
                    name: 'Total operating Expenses',
                    id: 'totalOperatingExpenses',
                    color: '#ff4c4c',
                  dataLabels: {
                    enabled: true,
                    align: 'left',
                    color: '#ffffff',
                    style: {
                      fontSize: '12px'
                    }
                  }
                },
                {
                    name: 'Corporate Taxes',
                    id: 'corporateTaxes',
                    color: '#ff4c4c',

                }


            ],

            data: [
              ...allIncomeStatements,

              ...allIncomeStatements.map((item: any) => ([item[1], 'totalRevenue', item[2]])),

                isFinite(grossProfitToGoodsPercentage) ? ['totalRevenue', 'grossProfit', grossProfitToGoodsPercentage] : ['totalRevenue', 'grossProfit', 100],
                isFinite(costsOfGoodsSoldToGrossProfitPercentage) &&  ['totalRevenue', 'costsOfGoodsSold', costsOfGoodsSoldToGrossProfitPercentage],

                isFinite(totalOperatingExpensesPercentage) ? ['grossProfit', 'totalOperatingExpenses', totalOperatingExpensesPercentage] : ['grossProfit', 'totalOperatingExpenses', 100],
                isFinite(operatingIncomePercentage) && ['grossProfit', 'operatingIncome', operatingIncomePercentage],

                ['totalOperatingExpenses', 'employeeCost', employeeCostPercentage],
                ['totalOperatingExpenses', 'officeCost', officeCostPercentage],
                ['totalOperatingExpenses', 'marketingCost', marketingConstPercentage],
                ['totalOperatingExpenses', 'technologyCost', technologyCostPercentage],
                ['totalOperatingExpenses', 'professionalFees', professionalFeesPercentage],
                ['totalOperatingExpenses', 'travelAndTrainingCost', travelAndTrainingCostPercentage],
                ['totalOperatingExpenses', 'insuranceRiskCost', insuranceRiskCostPercentage],
                ['totalOperatingExpenses', 'otherExpenses', otherExpensesPercentage],

                ...allExpanseStatements,

                corporateTaxesPercentage > 0 && ['operatingIncome', 'corporateTaxes', corporateTaxesPercentage],
                netIncomePercentage > 0 && ['operatingIncome', 'netIncome', netIncomePercentage],


            ],
            type: 'sankey',
            events: {
                click: (e) => {
                    console.log("SANKEY EVENT", e, e.point.name)
                }
            }
        }],

        ...props.options
    }


    return (
        <div className={classNames('Chart', 'LineChart', props.className)}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </div>
    );
};

export default SankeyChart;