import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'
import LineChart from '../Chart/LineChart'


interface SimpleDataBoxProps {
    header: string,
    badge?: string,
    value: string,
    icon?: React.ReactNode,
    from?: string,
    sx?: SxProps<Theme>,
    iconSX?: SxProps<Theme>,
    textSX?: SxProps<Theme>
}

const SimpleDataBox: React.FC<SimpleDataBoxProps> = (props) => {
  console.log("SIMPLE DATA BOX", props);

    return (
        <Box
            sx={{
                color: '#000',
                textAlign: 'left',
                padding: '10px',

                ...props.sx
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        width: '100%'
                    }}><Box>
                        {props.icon ? props.icon : <Box
                            sx={{
                                background: '#000',
                                borderRadius: '6px',
                                padding: '10px',
                                marginRight: '10px',

                                ...props.iconSX
                            }}
                        />}
                        </Box>
                        <Box sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            ...props.textSX

                        }}>
                            <Typography>{props.header}</Typography>
                            {props.badge && <Typography sx={{
                                background: '#E1F4EF',
                                color: '#29896E',
                                borderRadius: '6px',
                                padding: '2px 6px',
                                marginLeft: '1.3em',
                                fontSize: '13px'
                            }}>{props.badge}</Typography>}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box
                      sx={{
                          marginLeft: '10px',
                          marginTop: '15px'
                      }}
                    >
                        <Typography sx={{
                            fontSize: '1.5rem',
                            textAlign: 'left',
                            color: '#000',
                            fontWeight: 'bold',
                            ...props.textSX
                        }}>{props.value}
                        </Typography>
                    </Box>
                    {props.from && <Box
                      sx={{
                          marginLeft: '10px',
                      }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            textAlign: 'left',
                            color: '##DDDDD',
                        }}>{props.from}</Typography>
                    </Box>}
                </Grid>
                <Grid item xs={4}>
                    <LineChart
                      options={{
                          chart: {
                              type: 'line',
                            backgroundColor: 'transparent',
                            height: '70px'
                          },
                          credits: {
                            enabled: false
                          },
                          title: {
                              text: ''
                          },
                          xAxis: {
                            visible: false
                          },
                          yAxis: {
                              visible: false,
                              title: {
                                  text: ''
                              }
                          },
                          legend: {
                            enabled: false
                          },
                        plotOptions: {
                          series: {
                            marker: {
                              enabled: false
                            },
                            label: {
                              connectorAllowed: false
                            }
                          }
                        },
                          tooltip: {
                              enabled: false
                          },
                        series: [{
                            type: 'line',
                            name: 'Income',
                            data: [0, 10, 5, 2, 20, 30, 45]
                        }]
                      }}
                        />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SimpleDataBox;