import React from 'react'
import { useCopilotChat } from '@copilotkit/react-core'
import ChatCyron from './ChatCyron'

const SidebarChat = () => {
    /*const { state, setState } = useCoAgent<AgentState>({
        name: "cyron",
        initialState: {
            corporate_id: "AT102983192",
            tenant_id: "XYZ"
        },
    });*/

    const data = useCopilotChat();

    console.log("COPILOT CHAT", data);

  return (
    <ChatCyron
        className={"sidebar-chat"}
    />
  );
};

export default SidebarChat;
