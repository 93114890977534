import React from 'react'
import ChatCyron from './ChatCyron'

const SimpleChat= () => {

    return (
        <ChatCyron />
    );
};

export default SimpleChat;
