import React from 'react'
import { Box, TextField } from '@mui/material'
import { Option } from './interface'

interface SearchCountryInstituteFieldProps {
    type: SearchType,
    callback: (search: string) => void
}

type SearchType =
    | 'Country'
    | 'Institute'


const SearchCountryInstituteField: React.FC<SearchCountryInstituteFieldProps> = (props) => {
    const [search, setSearch] = React.useState<string>('')

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        props.callback(e.target.value)
    }

    return (
        <Box>
            <TextField
                fullWidth={true}
                label={`Search for ${props.type}`}
                onChange={onChange}
                value={search}
                variant="standard" />


        </Box>
    )
}

export default SearchCountryInstituteField