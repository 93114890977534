import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationProps } from '../../interface/NotificationProps'

const initialState = {
  loaded: false,
  notificationList: [{
    tenantName: 'tenantName',
    notificationId: 'notificationId',
    userId: 'userId',
    read: false,
    timestamp: new Date(),
    message: {
      title: 'title',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      progress: 0,
      total: 100
    }
  }] as NotificationProps[]
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationList: (state, action: PayloadAction<NotificationProps[]>) => {
        state.notificationList = action.payload;

        return state;
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
        state.loaded = action.payload;

        return state
    },
    addNotification: (state, action: PayloadAction<NotificationProps>) => {
      //Check if the notification already exists
      const notification = state.notificationList.find(notification => notification.notificationId === action.payload.notificationId);

      //Update if exists
      if (notification) {
          notification.message = action.payload.message;
          notification.read = action.payload.read;
          notification.timestamp = action.payload.timestamp;
          notification.link = action.payload.link;
      } else {
          //Add new notification
          state.notificationList.push(action.payload);
      }

      return state;
    },
    removeConversation: (state, action: PayloadAction<string>) => {
      state.notificationList = state.notificationList.filter(notification => notification.notificationId !== action.payload);

      return state;
    },
    clearNotification: (state) => {
      state.notificationList = [];

      return state
    }
  }
})
const notificationReducer = notificationSlice.reducer;

export const {setNotificationList, setLoaded, addNotification, removeConversation, clearNotification}  = notificationSlice.actions;
export default notificationReducer;
