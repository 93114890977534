import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../state/stateHooks'
import instance from '../../utils/api'
import GoCardlessModal from './gocardless/GoCardlessModal'

const IntegrationBankAccountField = () => {
  const [loading, setLoading] = useState(false);
  const data = useAppSelector(state => state.onboardingReducer)
  const location = useLocation();

  useEffect(() => {
    console.log("PARAMS", location)

    if (location.pathname === "/integration/bank-account/callback") {
      setLoading(true);

      instance.get("/api/v1/tenant/bank-account/callback" + location.search)
        .then(res => {
          setLoading(false);
          console.log("CALLBACK", res.data)
        })
        .catch(err => {
          console.log("CALLBACK ERROR", err)
        })
    }

  }, [location.pathname]);

  return (
      <>
          <GoCardlessModal />
      </>
  );
};

export default IntegrationBankAccountField;
