import instance from './index'

const uploadFIle = async (files : File[]) => {
    const formData = new FormData();

    formData.append("parse", "true");

    files.forEach(file => {
      formData.append('files', file);
    })

    return await instance.post('/api/v1/file', formData)
      .catch((error) => {
        console.error('Error uploading file', error);
        throw 'An error happened'
      });
}

const fetchFiles = async (type : string) => {
    return await instance(`/api/v1/file?type=${type}`)
      .catch((error) => {
        console.error('Error fetching files', error);
        throw 'An error happened'
      });
}

const downloadFile = async (path : string, fileName: string) => {
  const response = await instance(`/api/v1/file/single?path=${path}`, {
    responseType: 'blob', // Important for handling file downloads
  })

  // Create a new Blob from the response data
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // The file name you want to download
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link element after the download
  // @ts-ignore
  link.parentNode.removeChild(link);
  //window.open(`/api/v1/file/single?path=${path}`, '_blank');
}

/**
 * ---------  REPORT SECTION --------
 */
const fetchAllReports = async () => {
    return await instance.get('/api/v1/file/report')
        .catch((error) => {
        console.error('Error fetching reports', error);
        throw 'An error happened'
    });
}

const fetchReport = async (reportId : string) => {
    return await instance.get(`/api/v1/file/report/${reportId}`)
        .catch((error) => {
        console.error('Error fetching report', error);
        throw 'An error happened'
    });
}


export {
  uploadFIle,
  fetchFiles,
  downloadFile,
fetchAllReports,
fetchReport
}