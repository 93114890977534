import instance from './index'
import { TenantProps, TenantUpdateProps } from '../../interface/TenantProps'
import { Company, CompanyCreateInput, CompanySearchProps } from '../../interface/CompanySearchProps'
import { AxiosResponse } from 'axios'
import { setActiveStep } from '../../state/reducer/onboardingSlice'
import { IFullFirm } from '../../interface/IFirm'


type TenantData = {
  name: string,
  domain: string,
  userId: number,
  companyName: string,
  companyId: string
}

const fetchTenant = async (tenantId: string): Promise<TenantProps | undefined> => {
  const response = await instance.get(`/api/v1/instance/tenant/${tenantId}`)

  if (response.data) {
    return response.data;
  }
  return undefined;
}

const sendTenantData = async (values: TenantData) : Promise<TenantProps> => {
  const response = await instance.post<TenantProps>("/api/v1/instance/tenant", values);
  return response.data;
}

const updateTenantData = async (values: TenantUpdateProps, tenantId: string) : Promise<TenantProps> => {
  const response = await instance.put<TenantProps>(`/api/v1/instance/tenant/${tenantId}`, values);
  return response.data;
}

const fetchTenantByName = async (tenantName: string): Promise<TenantProps[]> => {
  const response = await instance.get(`/api/v1/instance/tenant/name/${tenantName}`)
  return response.data;
}

const setFinalFirmData = async (id: string, userId: number) => {
  const response = await instance.put(`/api/v1/instance/user/${userId}`, {
    company: id
  })
  console.log('Response setFinalFirmData:', response.data)
  return response.data;
}

const searchCompanyInformation = async (companyName: string): Promise<CompanySearchProps | null> => {
  const response = await instance.post("/api/v1/instance/search/company", {
    "question": companyName
  }, {
    headers: {
      "Content-Type": "application/json"
    },
  })

  if (response.data && response.data.text) {
    console.log(response.data.text)
    const data = response.data.text.replaceAll("```", "")
        .replaceAll("json", "");

    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Error parsing company information:', error);
      return null;
    }
  }

  return null;
}

const saveCompanyInformation = async (companyData: CompanyCreateInput): Promise<Company> => {
    const response = await instance.post<Company>("/api/v1/instance/company", companyData)

    return response.data;
}

const fetchCompany = async (id: string) : Promise<IFullFirm | undefined> => {
  const response = await instance.get<IFullFirm>(`/api/v1/instance/company/${id}`)

  if (response && response.data) {
    return response.data;
  }
  return undefined;
}

export {
  fetchTenant,
  fetchTenantByName,
  sendTenantData,
  updateTenantData,
  setFinalFirmData,
  searchCompanyInformation,
  saveCompanyInformation,
  fetchCompany
}
