import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { styled } from '@mui/system'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  transition: "all 0.3s ease-in-out",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  marginRight: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const CompanyManagePage = () => {

  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", email: "john@example.com", role: "Admin", avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1160&q=80" },
    { id: 2, name: "Jane Smith", email: "jane@example.com", role: "Manager", avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" },
    { id: 3, name: "Bob Johnson", email: "bob@example.com", role: "Employee", avatar: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" },
  ]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [loading, setLoading] = useState(false);

  const handleOpenDialog = (user : any = null) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpenDialog(false);
  };

  const handleSaveUser = (userData : any) => {
    setLoading(true);
    // Simulating an API call
    setTimeout(() => {
      if (selectedUser) {
        setUsers(users.map((user) => (user.id === selectedUser.id ? { ...user, ...userData } : user)));
        setSnackbar({ open: true, message: "User updated successfully!" });
      } else {
        const newUser = { ...userData, id: users.length + 1, avatar: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80" };
        setUsers([...users, newUser]);
        setSnackbar({ open: true, message: "User added successfully!" });
      }
      setLoading(false);
      handleCloseDialog();
    }, 1000);
  };

  const handleDeleteUser = (userId : number) => {
    setLoading(true);
    // Simulating an API call
    setTimeout(() => {
      setUsers(users.filter((user) => user.id !== userId));
      setSnackbar({ open: true, message: "User deleted successfully!" });
      setLoading(false);
    }, 1000);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };


  return (
    <LeftMenuRightChatLayout
      container={true}
      chatIntegrated={true}
      title={"Company Manager"}
    >
      <StyledPaper>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">User List</Typography>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
          >
            Add User
          </StyledButton>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <StyledAvatar src={user.avatar} alt={user.name} />
                      {user.name}
                    </Box>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(user)} color="primary">
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user.id)} color="error">
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedUser ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          {/*<UserForm user={selectedUser} onSave={handleSaveUser} loading={loading} />*/}
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />

    </LeftMenuRightChatLayout>
  );
};

export default CompanyManagePage;