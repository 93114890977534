import * as THREE from 'three'
import { createNoise3D } from 'simplex-noise'
import { NoiseFunction3D } from 'simplex-noise/simplex-noise'

interface BubbleParams {
  position: THREE.Vector3;
  radius: number;
  color: number;
  detail: number;
  isPlaying: boolean;
  audioAmplitude: number;
}

class Bubble extends THREE.Points {
  noise: NoiseFunction3D;
  amplitude: number;
  radius: number;
  lastUpdateTime: number;

  constructor(params: BubbleParams) {
    const geometry = new THREE.IcosahedronGeometry(params.radius, params.detail);
    const material = new THREE.PointsMaterial({
      color: params.color,
      size: 0.008,
      sizeAttenuation: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending,
    });

    super(geometry, material);
    this.noise = createNoise3D();
    this.radius = params.radius;
    this.position.copy(params.position);
    this.amplitude = 0;
    this.lastUpdateTime = performance.now(); // To track time and control update speed
  }

  updateGeometry(isPlaying: boolean, audioAmplitude: number) {
    const currentTime = performance.now();
    const deltaTime = (currentTime - this.lastUpdateTime) / 1000; // Get time in seconds

    if (deltaTime < 0.016) return; // Throttle updates to 60fps (16ms per frame)
    this.lastUpdateTime = currentTime;

    const geometry = this.geometry as THREE.IcosahedronGeometry;
    this.amplitude = isPlaying ? audioAmplitude + 0.2 : 0.2;

    // Adjust the speed of updates
    const amplitudeScale = 0.3; // Reduces the effect of audio amplitude changes on geometry

    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(geometry.attributes.position, i);
      vertex.normalize().multiplyScalar(this.radius + amplitudeScale * this.amplitude * this.noise(vertex.x, vertex.y, vertex.z));
      geometry.attributes.position.setXYZ(i, vertex.x, vertex.y, vertex.z);
    }
    geometry.attributes.position.needsUpdate = true;

    // Control the speed of rotation
    const rotationSpeed = 0.001; // Reduce rotation speed
    geometry.rotateX(rotationSpeed * (Math.random() * 0.5 > 1 ? 1 : -1));
    geometry.rotateY(rotationSpeed);
  }
}

export default Bubble;