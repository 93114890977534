import { Integration } from './interface'

export const getAllUniqueCountries = (institutions: Integration[]) => {
    let arrCountries: string[] = [];
    institutions.forEach((aspsp) => {
        arrCountries.push(...aspsp.countries);
    });
    let uniqueCountries = [...new Set(arrCountries)];
    return uniqueCountries.sort((a, b) => a.localeCompare(b));
};

export const getCountryFromISO = (country: string) => {
    try {
        let languageNames = new Intl.DisplayNames(['en'], {type: 'region'});
        return languageNames.of(country);
    } catch (err) {
        return country;
    }
};