import { RootState } from '../store'
import { connect, ConnectedProps } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import * as ROUTES from '../routes/routes'
import React from 'react'
import HomePage from '../pages/home/HomePage'
import CyronOnboarding from '../pages/onboarding/CyronOnboarding'
import AccountSetup from '../pages/account/Account.Setup'
import BalanceMain from '../pages/balance/Balance.Main'
import ChatPage from '../pages/chat/ChatPage'
import ReportPage from '../pages/report/Report.Page'
import FilesPage from '../pages/files/Files.Page'
import MessagePage from '../pages/message/Message.Page'
import NotificationPage from '../pages/notification/Notification.Page'
import AccountPage from '../pages/account/Account.Page'
import CompanyManagePage from '../pages/company/Company.Manage.Page'
import BudgetAndFinancePage from '../pages/budget_finance/BudgetAndFinance.Page'
import NewBudgetPage from '../pages/budget_finance/NewBudget.Page'
import TaxOverviewPage from '../pages/tax/TaxOverview.Page'
import ReportDetailPage from '../pages/report/ReportDetail.Page'
import IntegrationPage from '../pages/configuration/integration/Integration.Page'
import SettingsPage from '../pages/configuration/Settings.Page'
import SettingsGeneralPage from '../pages/configuration/integration/Settings.General.Page'

interface INavigationLinks extends PropsFromRedux{

}

const NavigationLinks : React.FC<INavigationLinks> = () => {
    return (
        <div>
            <Routes>
                {/**
                 * Login/Singup Routes
                 */}
                {/*<Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.REGISTER} element={<Register />} />
                <Route path={ROUTES.VERIFY} element={<Verify />} />
                <Route path={ROUTES.TENANT_CREATION} element={<CompanyCreation />} />
                <Route path={ROUTES.TENANT_CREATION} element={<CompanyCreation />} />*/}
                <Route path={ROUTES.ONBOARDING} element={<CyronOnboarding />} />
                {/*<Route path={ROUTES.ONBOARDING_TENANT} element={<Onboarding />} />
                <Route path={ROUTES.ONBOARDING_COMPANY} element={<Onboarding />} />
                <Route path={ROUTES.ONBOARDING_QUESTIONAIRE} element={<Onboarding />} />*/}

                {/**
                 * TODO: Onboarding Routes, Landing Page, etc.
                */}

                {/**
                 * Main Routes (Authenticated)
                 * TODO: Create Pages and Authentication
                 */}
                <Route path={ROUTES.HOME} element={<HomePage />} />

                {/** Budget & Finance */}
                <Route path={ROUTES.BUDGET_FINANCE_OVERVIEW} element={<BudgetAndFinancePage />} />
                <Route path={ROUTES.BUDGET_FINANCE_CREATE} element={<NewBudgetPage />} />

                {/** Cash Flow */}
                <Route path={ROUTES.CASH_FLOW_OVERVIEW} element={<BalanceMain />} />

                {/** Tax */}
                <Route path={ROUTES.TAX_OVERVIEW} element={<TaxOverviewPage />} />

                {/** Report */}
                <Route path={ROUTES.REPORT_OVERVIEW} element={<ReportPage />} />
                <Route path={ROUTES.REPORT_DETAIL} element={<ReportDetailPage />} />



                <Route path={ROUTES.ACCOUNT_SETUP} element={<AccountSetup />} />
                <Route path={ROUTES.BALANCE} element={<BalanceMain />} />
                <Route path={ROUTES.REPORTS} element={<ReportPage />} />
                <Route path={ROUTES.CHAT} element={<ChatPage />} />
                <Route path={ROUTES.FILES} element={<FilesPage />} />
                <Route path={ROUTES.MESSAGES} element={<MessagePage />} />
                <Route path={ROUTES.NOTIFICATION} element={<NotificationPage />} />
                <Route path={ROUTES.INTEGRATION} element={<IntegrationPage />} />
                <Route path={ROUTES.CALLBACK_INTEGRATION_BANK} element={<IntegrationPage />} />
                <Route path={ROUTES.CALLBACK_INTEGRATION_BANK_DEMO} element={<IntegrationPage />} />

                <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />
                <Route path={ROUTES.COMPANY_ADMIN} element={<CompanyManagePage />} />

                <Route path={ROUTES.SETTINGS} element={<SettingsPage />} >
                    <Route index element={<SettingsGeneralPage />} />
                    <Route path={ROUTES.SETTINGS_GENERAL} element={<SettingsGeneralPage />} />
                    <Route path={ROUTES.SETTINGS_INTEGRATION} element={<IntegrationPage />} />
                </Route>

            </Routes>
        </div>
    )
}


const mapState = (state : RootState) => {
    return state
};


const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavigationLinks);
