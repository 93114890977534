import React, { useState } from 'react'
import { IconButton, Paper, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

interface MessageInputProps {
  onSend: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSend }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      onSend(message);
      setMessage('');
    }
  };

  return (
    <Paper
      sx={{
        padding: '8px',
        borderTop: '1px solid #333',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#1E1E1E',
      }}
      elevation={3}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Reply to Claude..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
        sx={{
          marginRight: '8px',
          input: { color: '#E0E0E0' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#424242',
            },
            '&:hover fieldset': {
              borderColor: '#616161',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#616161',
            },
          },
        }}
      />
      <IconButton color="primary" onClick={handleSend}>
        <SendIcon />
      </IconButton>
    </Paper>
  );
};

export default MessageInput;