import React, { useEffect, useState } from 'react'
import { DrawerHeader, RightSidebarDrawer } from '../../layout/LeftMenuRightChatLayout'
import {
    Box,
    Button,
    Divider,
    Grid, Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material'
import SidebarChat from '../Chat/SidebarChat'
import GaugeChart from '../Chart/GaugeChart'
import instance from '../../utils/api'
import { fetchLatestMetric } from '../../utils/api/firm'
import { useAppSelector } from '../../state/stateHooks'
import { DashboardCard } from '../Cards'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../../routes/routes'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SimpleInformationBox from '../DataView/SimpleInformationBox'

interface DataViewDrawerProps {
}

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
}
const ListItemLink = (props: ListItemLinkProps) => {
    const { icon, primary, to } = props;

    return (
        <ListItemButton component={NavLink} to={to}>
            <ListItemText primary={primary} />
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        </ListItemButton>
    );
}
const DataViewDrawer = () => {
    const data = useAppSelector((state) => state.onboardingReducer);
    const [companyMetrics, setCompanyMetrics] = useState<any>({});

    const [companyData, setCompanyData] = useState<any>({});

    useEffect(() => {
        /*const peerGroupRequest = (nace: String) => {
            instance.get(`/api/v1/firm/metric/${nace}`)
                .then((resp) => {
                    console.log("NACE RESPONSE", resp.data);

                    setPeerGroupData(resp.data.map((item: any) => {
                        return {
                            title: item.name,
                            value: item.corporateFinancialMetrics.cyron_score
                        }
                    }));

                })
                .catch((err) => {
                    console.error("ERROR", err);
                });
        }
*/
        instance.get(`/api/v1/firm/${data.companyData.id}`, {
            params: {
                "financials": true,
            }
        })
            .then((resp) => {
                console.log("RESPONSE COMPANY DATA", resp.data);
                setCompanyData(resp.data);

                //peerGroupRequest(resp.data.nace);
            })
            .catch((err) => {
                console.error("ERROR", err);
            });

        fetchLatestMetric(data.companyData.id)
            .then(async (metricData) => {
                console.log("METRIC RESPONSE", metricData);

                if (metricData) {
                    setCompanyMetrics(metricData);
                } else {
                    const recalc = await instance.get(`/api/v1/firm/${data.companyData.id}/metric/calculate`)
                    console.log("RECALC", recalc)
                    const newMetricData = await fetchLatestMetric(data.companyData.id)

                    if (metricData) {
                        setCompanyMetrics(newMetricData);
                    }
                }

            })
            .catch((err) => {
                console.error("ERROR", err);
            });

    }, [data.companyData.id]);

    return (
        <RightSidebarDrawer
            anchor="right"
            variant="permanent" open={true}>
            <DrawerHeader />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    padding: '8%',
                    overflowY: 'auto',
                    alignItems: 'center',
                }}
            >

                <DashboardCard
                    backgroundColor={"#000"}
                    sx={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '330px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    {/** Cyron Score Box **/}
                    <Box sx={{
                        width: '100%',
                    }}>
                        <Typography
                            variant={"h4"}
                            sx={{
                                fontSize: '1.1rem',
                                color: '#fff',
                                textAlign: 'left',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                marginLeft: '10px'
                            }}
                        >Financial Health - Cyron Score</Typography>
                        <GaugeChart
                            //title={"Financial Health - Cyron Score"}
                            value={companyMetrics.cyron_score}
                        />
                        <Box
                            sx={{
                                border: "1px solid #323741",
                                borderRadius: '10px',
                                padding: '10px'
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: '#464646',
                                            color: '#fff',
                                            textTransform: 'none',
                                        }}

                                    >Show History</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: '#ffffff',
                                            color: '#000',
                                            textTransform: 'none',
                                        }}
                                    >Improve now</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DashboardCard>

                <DashboardCard
                    backgroundColor={"#000"}
                >
                    <SimpleInformationBox
                        header={"Revenue"}
                        badge={"160%"}
                        value={"€86,044"}
                        from={"From €175.26"}
                        sx={{
                            borderBottom: '2px solid #fff',
                        }}
                    />

                    <SimpleInformationBox
                        header={"Transactions"}
                        badge={"160%"}
                        value={"132"}
                        from={"From 8 months"}
                        sx={{
                            borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
                        }}
                    />

                    <SimpleInformationBox
                        header={"Avg. Transactions"}
                        badge={"60%"}
                        value={"€6,144"}
                        from={"From €29.21"}
                    />


                </DashboardCard>

                <DashboardCard
                    backgroundColor={"#e3e3e3"}
                    sx={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '300px',
                    }}>

                    <Typography variant={"h4"}>Quicklinks</Typography>

                    <List>
                        <ListItem disablePadding>
                            <ListItemLink to={ROUTES.CASH_FLOW_OVERVIEW} primary="Current CashFlow" icon={<ChevronRightIcon />} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemLink to={ROUTES.CASH_FLOW_OVERVIEW} primary="Check Mails" icon={<ChevronRightIcon />} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemLink to={ROUTES.CASH_FLOW_OVERVIEW} primary="Automation" icon={<ChevronRightIcon />} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemLink to={ROUTES.CASH_FLOW_OVERVIEW} primary="Start Market Analyse" icon={<ChevronRightIcon />} />
                        </ListItem>
                    </List>
                </DashboardCard>

            </Box>



        </RightSidebarDrawer>
    )
}

export default DataViewDrawer