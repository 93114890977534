import React, { useEffect, useState } from 'react'
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import LeftMenuLayout from '../../layout/LeftMenuLayout'
import { DashboardCard } from '../../components/Cards'
import SimpleDataBox from '../../components/DataView/SimpleDataBox'
import SankeyChart from '../../components/Chart/SankeyChart/SankeyChart'
import instance from '../../utils/api'
import { useAppSelector } from '../../state/stateHooks'
import ServerBasicDropzone from '../../components/Dropzone/ServerBasicDropzone'
import { numericFormatter } from 'react-number-format'
import TransactionDataGripBalance from './components/TransactionDataGrip.Balance'
import DateRange from '../../components/Date/DateRange'
import { fetchBankStatementOverview } from '../../utils/api/tenant'
import moment from 'moment'
import { Moment } from 'moment/moment'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'

const BalanceMain = () => {
  const data = useAppSelector((state) => state.onboardingReducer);
  const [bankAccountData, setBankAccountData] = useState<any>({});
  const [transactionHistory, setTransactionHistory] = useState<any>([]);
  const [specificRange, setSpecificRange] = useState("0m");
  const [dateRange, setDateRange] = useState({
    start: moment().set("M", moment().get("M") - 6),
    end: moment()
  })

  const onChangeSpecificRangeSetting = (
    event: React.MouseEvent<HTMLElement>,
    newRange: string,
  ) => {
    setSpecificRange(newRange)
    getBankOverview(undefined, undefined, newRange)
  }

  const onChangeDateRange = (startDate: Moment | null, endDate: Moment | null) => {
    if (startDate) {
      setDateRange(range => ({
        ...range,
        start: startDate
      }))
    }
    if (endDate) {
      setDateRange(range => ({
        ...range,
        end: endDate
      }))
    }

    if (startDate && dateRange.end) {
      getBankOverview(startDate.toDate(), dateRange.end.toDate())
    } else if (dateRange.start && endDate) {
      getBankOverview(dateRange.start.toDate(), endDate.toDate())
    }
  }

  const getBankOverview = (startDate?: Date, endDate?: Date, range?: string) => {
    fetchBankStatementOverview(startDate, endDate, range)
      .then((resp) => {
        console.log("RESPONSE BANKACCOUNT", resp.data);
        setBankAccountData(resp.data);
      })
      .catch((err) => {
        console.error("ERROR", err);
      });
  }
  useEffect(() => {
    if (data.tenantName) {
      getBankOverview()

      instance.get(`/api/v1/tenant/bank-account`)
        .then((resp) => {
          console.log("RESPONSE BANKACCOUNT", resp.data);
          setTransactionHistory(resp.data);
        })
        .catch((err) => {
          console.error("ERROR", err);
        });
    }
  }, [data.tenantName]);


  return (
      <LeftMenuRightChatLayout
          container={true}
          chatIntegrated={true}
          title={"CashFlow Management"}
      >
      <Box
        className="title-container"
        sx={{
          marginBottom: '20px'
        }}
      >
        <Grid
          spacing={4}
          container>
          <Grid item xs={3}>

          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                display: "inline-flex",
                flexWrap: 'nowrap',
                justifyContent: "flex-end",
                alignItems: 'center',
                width: '100%'
              }}
            >
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={specificRange}
                exclusive
                onChange={onChangeSpecificRangeSetting}
                aria-label="Platform"
                sx={(theme) => ({
                  ".MuiButtonBase-root": {
                    background: "#000000",
                    color: "#ffffff",

                    "&.Mui-selected": {
                      background: "#262626",
                      color: theme.palette.primary.main
                    }
                  }
                })}
              >
                <ToggleButton value="0m">Current</ToggleButton>
                <ToggleButton value="1m">1M</ToggleButton>
                <ToggleButton value="3m">3M</ToggleButton>
                <ToggleButton value="6m">6M</ToggleButton>
                <ToggleButton value="1y">1Y</ToggleButton>
                <ToggleButton value="3y">3Y</ToggleButton>
              </ToggleButtonGroup>

              <Box>
                <DateRange value={dateRange} onChange={onChangeDateRange}

                />
              </Box>

            </Box>
          </Grid>

          <Grid item xs={3}>
            <DashboardCard
              backgroundColor={"#000"}
              sx={{
                color: '#fff',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <SimpleDataBox
                  sx={{
                      color: '#fff'
                  }}
                  iconSX={{
                      color: '#fff'
                  }}
                  textSX={{
                      color: '#fff'
                  }}
                header={"Total Income"}
                value={bankAccountData.netIncome && numericFormatter(bankAccountData.netIncome.toString(), {
                    decimalScale: 2,
                    thousandSeparator: true,
                    prefix: '€',
                  })}
              />
            </DashboardCard>
          </Grid>
          <Grid item xs={3}>
            <DashboardCard
              backgroundColor={"#000"}
              sx={{
                color: '#fff',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <SimpleDataBox
                  sx={{
                      color: '#fff'
                  }}
                  iconSX={{
                      color: '#fff'
                  }}
                  textSX={{
                      color: '#fff'
                  }}
                header={"Revenue"}
                value={bankAccountData.totalRevenue && numericFormatter(bankAccountData.totalRevenue.toString(), {
                  decimalScale: 2,
                  thousandSeparator: true,
                  prefix: '€',
                })}
              />
            </DashboardCard>
          </Grid>

          <Grid item xs={3}>
            <DashboardCard
              backgroundColor={"#000"}
              sx={{
                color: '#fff',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <SimpleDataBox
                  sx={{
                      color: '#fff'
                  }}
                  iconSX={{
                      color: '#fff'
                  }}
                  textSX={{
                      color: '#fff'
                  }}
                header={"Taxes"}
                value={bankAccountData.corporateTaxes && numericFormatter(bankAccountData.corporateTaxes.toString(), {
                  decimalScale: 2,
                  thousandSeparator: true,
                  prefix: '€',
                })}
              />
            </DashboardCard>
          </Grid>

          <Grid item xs={3}>
            <DashboardCard
              backgroundColor={"#000"}
              sx={{
                color: '#fff',
                height: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <SimpleDataBox
                  sx={{
                      color: '#fff'
                  }}
                  iconSX={{
                      color: '#fff'
                  }}
                  textSX={{
                      color: '#fff'
                  }}
                header={"Expenses"}
                value={bankAccountData.totalOperatingExpenses && numericFormatter(bankAccountData.totalOperatingExpenses.toString(), {
                  decimalScale: 2,
                  thousandSeparator: true,
                  prefix: '€',
                })}
              />
            </DashboardCard>
          </Grid>

        </Grid>
      </Box>

      <Box>
        <Grid
          spacing={4}
          container>
          <Grid item xs={12}>
            {bankAccountData.bankAccountModelList && <SankeyChart data={bankAccountData} />}
          </Grid>

          {/*<Grid item xs={3}>
            <DashboardCard
              backgroundColor={'#fff'}
              sx={{
                height: 'auto',
              }}
            >
              <ServerBasicDropzone
                sx={{
                  height: '100%',
                  "& .filepond--root .filepond--drop-label": {
                    minHeight: '360px'
                  },
                }}
              />
            </DashboardCard>
          </Grid>*/}
        </Grid>
      </Box>

      {/*<Box
        sx={{
          marginTop: "20px"
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h1" sx={{
              fontSize: '1.2rem',
              textAlign: 'left',
              color: '#000'
            }}>Transaction history</Typography>
          </Grid>
          <Grid item xs={6}>

          </Grid>

          <Grid item xs={12}>
            <TransactionDataGripBalance />
          </Grid>

        </Grid>
      </Box>*/}

      </LeftMenuRightChatLayout>
  );
};

export default BalanceMain;