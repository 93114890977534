import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { styled } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import CommentIcon from '@mui/icons-material/Comment'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useAppDispatch, useAppSelector } from '../../state/stateHooks'
import Moment from 'react-moment'
import { NotificationMessageProps } from '../../interface/NotificationProps'
import CircularProgressWIthLabel from '../Progress/CircularProgressWIthLabel'
import CloseIcon from '@mui/icons-material/Close';
import { removeConversation, setNotificationList } from '../../state/reducer/notificationSlice'
import { useSocketNotificationContext } from '../../context/SocketNotificationContext'
import { loadAllUnreadNotifications } from '../../utils/api/notification'
import { useSnackbar } from 'notistack'

const NotificationPanel = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: '65px',
  right: 0,
  bottom: 0,
  width: "30%",
  maxHeight: 'calc(100vh - 65px)',
  backgroundColor: theme.palette.background.chat,
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
  overflow: "auto",
  zIndex: 1000,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const NotificationCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.chat,
  color: theme.palette.text.primary,
}));

const NotificationTab = () => {
  const [expanded, setExpanded] = React.useState(false);
  const notificationReducer = useAppSelector((state) => state.notificationReducer);
  const dispatch = useAppDispatch();
  const notificationSocket = useSocketNotificationContext();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    if (notificationSocket.message) {
      const content = notificationSocket.message.message.content.replace("Please visit <a href=\"/cash\">CashFlow Management for data visualisation</a>", "")

      enqueueSnackbar(content, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        }
      });
    }
  }, [notificationSocket.message])


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const notificationList = notificationReducer.notificationList;

  const handleDiscard = (notificationId: string) => {
    const notification = notificationList.find(notification => notification.notificationId === notificationId);

    if (!notification) {
        return;
    }

    const sendNotification = {
      ...notification,
      read: true
    }
    dispatch(removeConversation(notificationId));

    notificationSocket.sendSocketMessage(sendNotification)
  }

  useEffect(() => {
    if (!notificationReducer.loaded) {
      loadAllUnreadNotifications()
          .then((response) => {
            dispatch(setNotificationList(response.data));
          })
          .catch(() => {

          })
    }
  }, [])


  /*const notifications = [
    {
      id: 1,
      avatar: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      text: "Dylan has requested to edit the file Astro Illustration",
      timestamp: "2 hours ago",
      icon: <EditIcon />,
    },
    {
      id: 2,
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
      text: "Marina Niki edited Earthfund - Master",
      timestamp: "5 hours ago",
      icon: <CommentIcon />,
    },
  ];*/

  console.log(notificationList)

  return (
    <>
      <IconButton
        /*sx={{
          minWidth: '35px',
          width: '35px',
          height: '35px',
          margin: '0px 5px'
        }}*/
        onClick={handleExpandClick}
        color="inherit" aria-label="notifications">
        {notificationList.length > 0 ? (
          <Badge badgeContent={notificationList.length} color="error">
            <NotificationsIcon />
            </Badge>
        ) : <NotificationsIcon />}
      </IconButton>

      <Collapse
        in={expanded}
        orientation={"vertical"}
        easing={"ease-in-out"}
        timeout="auto"
        unmountOnExit>
        <NotificationPanel>
          <Box sx={{
            p: 3,
            textAlign: 'left'
          }}>
            <Typography variant="h4">Notifications</Typography>
          </Box>


          <Box sx={{ p: 1 }}>
            {notificationList.length === 0 ? (
                <Box>
                  <Typography variant="h5" sx={{ textAlign: 'center', mt: 5 }}>No notifications</Typography>
                </Box>
                ) :
                (<>{notificationList.map((notification) => (
                      <NotificationCard key={notification.notificationId}>
                        <CardContent>
                          <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 0
                              }}
                          >
                            <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 0
                                }}
                            >
                              {("progress" in notification.message)  ? (
                                  <CircularProgressWIthLabel
                                      value={notification.message.progress}
                                  />
                              ) : <></>}
                              <Typography variant="h5" sx={{ fontWeight: '700', ml: 2 }}>{notification.message.title}</Typography>
                            </Box>

                            <IconButton onClick={() => handleDiscard(notification.notificationId)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{
                            textAlign: 'left',
                            mb: 2,
                            ml: ("progress" in notification.message ? 7 : 2)
                          }}
                          >
                            <Typography variant="body1"
                                        dangerouslySetInnerHTML={{ __html: notification.message.content }}></Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Typography variant="caption" color="text.secondary">
                              <Moment format="DD.MM.YYYY HH:mm">{notification.timestamp}</Moment>
                            </Typography>
                          </Box>
                        </CardContent>
                      </NotificationCard>
                  ))}</>)}
          </Box>
        </NotificationPanel>
      </Collapse>
    </>
  );
};

export default NotificationTab;