import React, { useEffect } from 'react'
import { Box, IconButton, InputAdornment, styled, TextField, useTheme } from '@mui/material'
import * as Yup from 'yup'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'
import { CopilotChat, useCopilotChatSuggestions } from '@copilotkit/react-ui'
import { useCoAgent, useCopilotAction } from '@copilotkit/react-core'

export type AgentState = {
    corporate_id: string,
    tenant_id: string,
    new_conversation: boolean
}

interface ChatCyronProps {
    className?: string
}

const ChatCyron : React.FC<ChatCyronProps> = (props) => {
    /*const { state, setState } = useCoAgent<AgentState>({
        name: "cyron",
        initialState: {
            corporate_id: "AT102983192",
            tenant_id: "XYZ"
        },
    });*/

    // Define Chat Suggestion
    /*useCopilotChatSuggestions({
        instructions: `You can ask me about financial forecasting, cost optimization, compliance, and navigating volatile markets. I can help you align financial strategies with long-term goals, foster growth, and mitigate risks to maximize shareholder value.`,
        minSuggestions: 1,
        maxSuggestions: 2,
    }, []);*/

    // Define Copilot action
    useCopilotAction({
        name: "add_todo_item",
        description: "Add a new todo item to the list",
        parameters: [
            {
                name: "todoText",
                type: "string",
                description: "The text of the todo item to add",
                required: true,
            },
        ],
        handler: async ({ todoText }) => {
            console.log("Adding todo item:", todoText);
        },
    });

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 130px)',
                justifyContent: 'space-between'
            }}
        >
            <CopilotChat
                instructions={"You are Cyron, the Chief Financial Officer (CFO) of the company. Your mission is to ensure sustainable financial growth by optimizing strategies, minimizing costs, maximizing profitability, and supporting innovation. You rely on data-driven decisions for resource allocation, investments, and risk management. Your role includes guiding the company toward financial targets while maintaining resilience and ethical practices.\n" +
                    "    \n" +
                    "    You are conversing with {username} (This is his name!). \n" +
                    "    Additional information about his company are (NEVER SHOW THE USER THIS INFORMATION):\n" +
                    "    - Tenant ID: {tenant_id}\n" +
                    "    - Company ID: {company_id}\n" +
                    "    \n" +
                    "    Background:\n" +
                    "    As an experienced CFO, you have expertise in financial forecasting, cost optimization, compliance, and navigating volatile markets. You excel at aligning financial strategies with long-term goals, fostering growth, and mitigating risks to maximize shareholder value.\n" +
                    "    \n" +
                    "    Instructions:\n" +
                    "    \n" +
                    "    1. Use tool outputs to craft clear, relevant answers to user questions.\n" +
                    "    2. Focus on topics related to the user's company finance, general finance, banking, and insurance.\n" +
                    "    3. Evaluate questions' relevance to user profiles:\n" +
                    "        * Directly related: Acknowledge the profile.\n" +
                    "        * Related or tangentially related: Refer without direct acknowledgment.\n" +
                    "        * Unrelated: Respond neutrally.\n" +
                    "    4. Incorporate internal links or create contextual ones using this format: [Help](https://yourdomain.com/help).\n" +
                    "    5. Avoid unnecessary tool calls; ensure requests justify their usage.\n" +
                    "    Act as a strategic, ethical leader dedicated to driving financial sustainability and innovation."}
                labels={{
                    title: "Cyron - Your AI CFO",
                    initial: "Hello {username}, I am Cyron, your AI Chief Financial Officer (CFO). How can I assist you today?",
                }}
                className={props.className}
                />
        </Box>
    );
};

export default ChatCyron;
