import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material'
import { DashboardCard } from '../../components/Cards'
import GaugeChart from '../../components/Chart/GaugeChart'
import { useLocation } from 'react-router-dom'
import instance from '../../utils/api'
import { useAppDispatch, useAppSelector } from '../../state/stateHooks'
import { fetchLatestMetric } from '../../utils/api/firm'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import CommentIcon from '@mui/icons-material/Comment'
import SimpleDataBox from '../../components/DataView/SimpleDataBox'
import { numericFormatter } from 'react-number-format'
import StartIcon from '@mui/icons-material/Start';
import { fetchBankStatementOverview, fetchRecommendationList } from '../../utils/api/tenant'
import { v4 } from 'uuid'
import { RecommendationProps } from '../../interface/RecommendationProps'
import { useAuth0 } from '@auth0/auth0-react'

const notificationExamples = [
  {
    title: "Monthly Budget Review Reminder",
    subtitle: "It's time for your monthly budget review. Make sure to assess your spending and income compared to your forecasted budget."
  },
  {
    title: "Cash Flow Alert",
    subtitle: "Your cash flow is showing a discrepancy of 15% compared to last month’s projection. Consider reviewing high-expense items."
  },
  {
    title: "Receivables Due for Collection",
    subtitle: "Several outstanding invoices are due within the next 3 days. Follow up on payments to ensure timely collection."
  },
  {
    title: "Weekly Profit and Loss Statement Available",
    subtitle: "Your latest profit and loss (P&L) statement is ready for review. Take a look to track your business’s performance."
  },
  {
    title: "Expense Threshold Alert",
    subtitle: "Alert! Expenses have reached 85% of the monthly limit. Review your spending and consider adjusting discretionary expenses."
  },
  {
    title: "Budget Variance Warning",
    subtitle: "A 10% variance has been detected in the projected vs. actual budget for this quarter. Investigate areas with significant discrepancies."
  },
  {
    title: "Low Cash Reserve Alert",
    subtitle: "Your cash reserve has dipped below the minimum threshold. Consider adjusting expenditures or exploring financing options."
  },
  {
    title: "Upcoming Tax Payment Reminder",
    subtitle: "Tax payments are due in 10 days. Ensure you have the necessary funds allocated to avoid penalties."
  },
  {
    title: "Expense Approval Pending",
    subtitle: "Several expenses are pending approval. Review them to avoid delays in budget allocations or reimbursement."
  },
  {
    title: "Quarterly Financial Health Report Ready",
    subtitle: "Your quarterly financial health report is available. Review the insights on cash flow, profitability, and budget adherence."
  }
];

const HomePage = () => {
    const data = useAppSelector((state) => state.onboardingReducer);

    const [tenant, setTenant] = useState<string>("");
    const [companyMetrics, setCompanyMetrics] = useState<any>({});
    const [companyData, setCompanyData] = useState<any>({});
    const [peerGroupData, setPeerGroupData] = useState<any | undefined>();
    const [bankAccountData, setBankAccountData] = useState<any>({});
    const [recommendationList, setRecommendationList] = useState<RecommendationProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [noData, setNoData] = useState(false);

    const dispatch = useAppDispatch();

    let lastFinancials = {} as any;
    if (companyData.corporateFinancialList) {
        lastFinancials = companyData.corporateFinancialList[companyData.corporateFinancialList.length - 1];
    }

    const conversationData = useAppSelector((state) => state.conversationReducer);
    const currentConversationID = conversationData.currentConversation;
    const conversationList = conversationData.conversationList;
    const currentConversation = conversationList.find((conversation) => conversation.conversation_id === currentConversationID) || {intro: true, name: "", conversation_id: "", messages: []};



    const { user } = useAuth0();

    console.log("DATA", data);

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        /*dispatch(sendCurrentView({
            currentPageUrl: location.pathname,
            currentPageName: "Home",
            currentPageElements: [],
            user_id: user.userId,
            tenant_id: data.tenantId
        }))*/

        // Get state from the URL
        const locationState = location.state;
        if(locationState && locationState.tenant) {
            setTenant(locationState.tenant);
        } else {
            setTenant("tenant_testname123");
        }

        const peerGroupRequest = (nace: String) => {
            instance.get(`/api/v1/firm/metric/${nace}`)
                .then((resp) => {
                    console.log("NACE RESPONSE", resp.data);

                  setPeerGroupData(resp.data.map((item: any) => {
                    return {
                      title: item.name,
                      value: item.corporateFinancialMetrics.cyron_score
                    }
                  }));

                })
                .catch((err) => {
                    console.error("ERROR", err);
                });
        }

        fetchRecommendationList()
            .then((resp) => {
                console.log("RESPONSE RECOMMENDATION", resp.data);
                setRecommendationList(resp.data);
            })
            .catch((err) => {
                console.error("ERROR RECOMMENDATION", err);
            });


        instance.get(`/api/v1/firm/${data.companyData.id}`, {
          params: {
            "financials": true,
          }
        })
            .then((resp) => {
                console.log("RESPONSE COMPANY DATA", resp.data);
                setCompanyData(resp.data);

              peerGroupRequest(resp.data.nace);


              setLoading(false)

            })
            .catch((err) => {
                setNoData(true);
                console.error("ERROR", err);
            });

          fetchLatestMetric(data.companyData.id)
            .then(async (metricData) => {
                console.log("METRIC RESPONSE", metricData);

                if (metricData) {
                    setCompanyMetrics(metricData);
                } else {
                  const recalc = await instance.get(`/api/v1/firm/${data.companyData.id}/metric/calculate`)
                  console.log("RECALC", recalc)
                  const newMetricData = await fetchLatestMetric(data.companyData.id)

                  if (metricData) {
                    setCompanyMetrics(newMetricData);
                  }
                }

            })
            .catch((err) => {
                console.error("ERROR", err);
            });

    }, [data.companyData.id]);

    useEffect(() => {
        console.log("LOADING BANK ACCOUNT DATA", data);
      if (data.tenantName) {
          fetchBankStatementOverview()
              .then((resp) => {
                  console.log("RESPONSE BANKACCOUNT", resp.data);
                  setBankAccountData(resp.data);
              })
              .catch((err) => {
                  console.error("ERROR BANKACCOUNT", err);
              });
      }
    }, [data.tenantName]);

    /** Notification Alert Handler **/
    const onNotificationClick = (item: any) => {
        console.log("Notification Clicked", item);

        /*dispatch(addMessageToConversation({
            conversation_id: currentConversationID,
            message: {
                run_id: v4(),
                text: `Please help me with: '${item.title}'`,
                sender: SenderType.USER,
                timestamp: new Date().toISOString()
            }
        }));*/

        /*sendSocketMessage({
            message: `Please help me with: '${item.title} - ${item.subtitle}'`,
            type: "MESSAGE",
            sender: 'user',
            user_id: user?.userId,
            conversation_id: currentConversationID,
            tenant_id: data.tenantId,
            intro: false
        })*/

    }

    /** Recommendation Handler **/
    const onRecommendationClick = (item: RecommendationProps) => {
        console.log("Recommendation Clicked", item);

        /*dispatch(addMessageToConversation({
            conversation_id: currentConversationID,
            message: {
                run_id: v4(),
                text: `Please help me with: '${item}'`,
                sender: SenderType.USER,
                timestamp: new Date().toISOString()
            }
        }));*/

        /*sendSocketMessage({
            message: `Please provide the user (me) more information about: '${item.title}'. System Information: '${item.recommendation}'. DONT USE ANY TOOL ON THIS!`,
            type: "MESSAGE",
            sender: 'user',
            user_id: user?.userId,
            conversation_id: currentConversationID,
            tenant_id: data.tenantId,
            intro: false
        })*/

    }

    const onExitIntro = () => {

    }

    return (
        <LeftMenuRightChatLayout
            container={true}
            chatIntegrated={true}
            title={"Overview"}
        >
            {/*<Steps
                enabled={true}
                steps={introSteps}
                initialStep={0}
                onExit={onExitIntro}
            />*/}
            <Box
                className="main-container">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <DashboardCard
                            backgroundColor={"#fff"}
                            sx={{
                                color: '#000',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <SimpleDataBox
                                header={"Total Income"}
                                value={bankAccountData.netIncome && numericFormatter(bankAccountData.netIncome.toString(), {
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '€',
                                })}
                            />
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={3}>
                        <DashboardCard
                            backgroundColor={"#fff"}
                            sx={{
                                color: '#000',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <SimpleDataBox
                                header={"Revenue"}
                                value={bankAccountData.totalRevenue && numericFormatter(bankAccountData.totalRevenue.toString(), {
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '€',
                                })}
                            />
                        </DashboardCard>
                    </Grid>

                    <Grid item xs={3}>
                        <DashboardCard
                            backgroundColor={"#fff"}
                            sx={{
                                color: '#000',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <SimpleDataBox
                                header={"Taxes"}
                                value={bankAccountData.corporateTaxes && numericFormatter(bankAccountData.corporateTaxes.toString(), {
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '€',
                                })}
                            />
                        </DashboardCard>
                    </Grid>

                    <Grid item xs={3}>
                        <DashboardCard
                            backgroundColor={"#fff"}
                            sx={{
                                color: '#000',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <SimpleDataBox
                                header={"Expenses"}
                                value={bankAccountData.totalOperatingExpenses && numericFormatter(bankAccountData.totalOperatingExpenses.toString(), {
                                    decimalScale: 2,
                                    thousandSeparator: true,
                                    prefix: '€',
                                })}
                            />
                        </DashboardCard>
                    </Grid>

                    {!noData && (<>
                        <Grid item xs={3}>
                            <DashboardCard
                                id={"cyronScore"}
                                backgroundColor={"#000"}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >

                                {/** Cyron Score Box **/}
                                <Box sx={{
                                    width: '100%',
                                }}>
                                    <Typography
                                        variant={"h4"}
                                        sx={{
                                            fontSize: '1.1rem',
                                            color: '#fff',
                                            textAlign: 'left',
                                            fontWeight: 'bold',
                                            marginBottom: '20px',
                                            marginLeft: '10px'
                                        }}
                                    >Financial Health - Cyron Score</Typography>
                                    <GaugeChart
                                        //title={"Financial Health - Cyron Score"}
                                        value={companyMetrics.cyron_score}
                                    />
                                    <Box
                                        sx={{
                                            border: "1px solid #323741",
                                            borderRadius: '10px',
                                            padding: '10px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        background: '#464646',
                                                        color: '#fff',
                                                        textTransform: 'none',
                                                    }}

                                                >Show History</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        background: '#ffffff',
                                                        color: '#000',
                                                        textTransform: 'none',
                                                    }}
                                                >Improve now</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </DashboardCard>


                            {/*<DashboardCard
                            backgroundColor={"#000"}
                            >

                            * Account Box *
                            <SimpleInformationBox
                                header={"Revenue"}
                                badge={"160%"}
                                value={"$86,044"}
                                from={"From $175.26"}
                                sx={{
                                    borderBottom: '2px solid #fff',
                                }}
                            />

                            <SimpleInformationBox
                                header={"Transactions"}
                                badge={"160%"}
                                value={"132"}
                                from={"From 8 months"}
                                sx={{
                                    borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
                                }}
                            />

                            <SimpleInformationBox
                                header={"Avg. Transactions"}
                                badge={"60%"}
                                value={"$6,144"}
                                from={"From $29.21"}
                            />


                        </DashboardCard>*/}
                        </Grid>
                        <Grid item xs={9}>
                            <DashboardCard>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography variant={"h5"}>Notification / Alerts</Typography>
                                    <Button variant="text" size="small">Set to read</Button>
                                </Box>
                                <List
                                    sx={{
                                        maxHeight: "280px",
                                        overflowY: "auto",

                                    }}
                                >
                                    {notificationExamples.map((item, index) => (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <Tooltip title="Click, to get direct help from Cyron. He will guide you through the necessary steps.">
                                                    <IconButton edge="end" aria-label="comments" onClick={(e) => onNotificationClick(item)}>
                                                        <CommentIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton role={undefined} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': "labelId" }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={"labelId"} primary={item.title} secondary={item.subtitle} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </DashboardCard>

                        </Grid>
                    </>)}

                    <Grid item xs={12}>
                        <DashboardCard>
                            <Typography sx={{
                                textAlign: 'left',
                                paddingLeft: '20px'
                            }} variant={"h5"}>Recommendations</Typography>
                            <List
                                sx={{
                                    maxHeight: "280px",
                                    overflowY: "auto",

                                }}
                            >
                                {recommendationList.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton aria-label="comment" onClick={() => onRecommendationClick(item)}>
                                                <StartIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemButton role={undefined} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={item.done || false}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': item.slug }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={item.slug}
                                                          primary={item.title}
                                                          secondary={item.subtitle} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </DashboardCard>
                    </Grid>

                </Grid>
            </Box>
          {/*<Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>*/}
        </LeftMenuRightChatLayout>
    )
};

export default HomePage;
