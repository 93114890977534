import React from 'react'
import LeftMenuRightChatLayout from '../../layout/LeftMenuRightChatLayout'
import { Box } from '@mui/material'

const NewBudgetPage = () => {
    return (
        <LeftMenuRightChatLayout
            container={true}
            chatIntegrated={true}
            title={"Budget & Finance > New Budget"}
        >
            <Box
                className="main-container">
                Work in Progress
            </Box>
        </LeftMenuRightChatLayout>
    )
}

export default NewBudgetPage