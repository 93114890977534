import React, { useState } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import ChatMessage from './ChatMessage'
import ChatMessageInput from './ChatMessageInput'
import ChatMessageQuestionInput from './ChatMessageQuestionInput'
import FirmSearchAutocomplete from '../Autocomplete/FirmSearchAutocomplete'
import { useAppSelector } from '../../state/stateHooks'
import { slugify } from '../../utils/helper/helper'
import SendIcon from '@mui/icons-material/Send'
import Bubble from '../Bubble/Bubble'
import BubbleMain from '../Bubble/BubbleMain'
import { useFormik } from 'formik'
import ChatMessageSimpleInput from './ChatMessageSimpleInput'
import CreateCompanyOnboarding from '../../pages/onboarding/components/CreateCompany.Onboarding'

interface ChatWindowProps {
  onboarding?: boolean;
  onSubmit?: (data: any, step: number) => void;
}

interface MessageType {
  sender: 'user' | 'bot';
  content: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ onboarding, onSubmit }) => {
  const onboardingData = useAppSelector((state) => state.onboardingReducer);
  const [companyNotFoundAndCreate, setCompanyNotFoundAndCreate] = useState(false);
  const loading = true;

  const [messages, setMessages] = useState<MessageType[]>([
    { sender: 'bot', content: 'Here is your joke...' },
    { sender: 'user', content: 'Schreibe mir einen kleinen Witz' },
  ]);

  const handleSendMessage = (content: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'user', content },
      { sender: 'bot', content: `Bot: ${content}` }, // Mock bot response
    ]);
  };


  if (onboarding) {
    return (
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 2,
            }}>
          <BubbleMain dark={true} height={200} />
        </Box>
        <Box sx={{ overflowY: 'auto', padding: 2 }}>

          <Box
            sx={{
              color: "#ffffff",
              textAlign: "left",
            }}
          >
            <Typography variant={"h3"} sx={{
              marginBottom: 1,
              lineHeight: 1.5,
            }}>Hello, I'm Cyron</Typography>
          </Box>

            {onboardingData.activeStep >= 0 && (
                <>
                    <Typography variant={"h4"} sx={{
                        color: "#ffffff",
                        textAlign: "left",
                        mb: 2,
                        lineHeight: 1.5
                    }}>I'm a next generation AI assistant built for financial questions, work, tasks and much more.</Typography>

                    <ChatMessageQuestionInput
                        question={"I do not know your name. Please tell me your name."}
                        error={onboardingData.error}
                        value={(onboardingData.admin.firstName && onboardingData.admin.lastName) ? (onboardingData.admin.firstName + " " + onboardingData.admin.lastName): ""}
                        loading={onboardingData.stepIsLoading}
                        loadingText={"Saving user data..."}
                        showButton={((onboardingData.admin.firstName || "") + (onboardingData.admin.lastName || "")) === ""}
                        onSend={(message) => onSubmit ? onSubmit(message, onboardingData.activeStep) : ""} />
                </>
            )}
        </Box>
        <Box sx={{ overflowY: 'auto', padding: 2 }}>
          {onboardingData.activeStep >= 1 && (
              <>
                  <Box
                      sx={{
                          color: "#ffffff",
                          textAlign: "left",
                      }}
                  >
                      <Typography variant={"h4"} sx={{
                          lineHeight: 1.5
                      }}>The next three questions are mandatory to start my work and help you with your daily questions and problems.</Typography>

                      <Typography variant={"body2"} sx={{
                          marginTop: 2,
                          fontWeight: 100,
                          fontSize: "12px",
                          color: 'rgba(255,255,255,0.5)'
                      }}>
                          For demo use:
                          <ul>
                              <li>Bins, Kirlin and Schuster</li>
                              <li>Wolf, Quitzon and Fritsch</li>
                              <li>Roob, Gulgowski and Brakus</li>
                              <li>Gleason Inc</li>
                              <li>Harris Group</li>
                          </ul>
                      </Typography>
                  </Box>

                  {companyNotFoundAndCreate ?
                      <CreateCompanyOnboarding /> :
                      <ChatMessageQuestionInput
                          question={"Please search for and select the company you work for."}
                          inputNode={<FirmSearchAutocomplete
                              setCompanyNotFoundAndCreate={() => setCompanyNotFoundAndCreate(true)}
                              setFirmInformation={(firm) => onSubmit ? onSubmit(firm, onboardingData.activeStep) : ""}
                              value={onboardingData.companyData}
                          />}
                          value={onboardingData.companyData.name}
                          loading={onboardingData.stepIsLoading}
                          loadingText={"Saving company data..."}
                          showButton={onboardingData.companyData.name === ""}
                          onSend={(message) => onSubmit ? onSubmit(null, onboardingData.activeStep) : ""} />
                  }
              </>
          )}

          {onboardingData.activeStep >= 2 && (
            <>
              <Typography variant={"h4"} sx={{
                color: "#ffffff",
                textAlign: "left",
                lineHeight: 1.5
              }}>Thank you for this information. I now understand your current company and will start analysing the data.</Typography>

              <ChatMessageQuestionInput
                question={"Please specify your instance/tenant name."}
                error={onboardingData.error}
                value={onboardingData.tenantName}
                loading={onboardingData.stepIsLoading}
                loadingText={"Saving tenant data..."}
                showButton={onboardingData.tenantName === ""}
                onSend={(message) => onSubmit ? onSubmit(message, onboardingData.activeStep) : ""} />
            </>
          )}

          {onboardingData.activeStep >= 3 && (
            <>
              <Typography variant={"h4"} sx={{
                color: "#ffffff",
                textAlign: "left",
                lineHeight: 1.5
              }}>This name sounds wonderful. For your tenant we provide a custom domain for you. Feel free to change it.</Typography>

              <ChatMessageQuestionInput
                question={"Please specify your custom domain name."}
                value={onboardingData.tenantDomain ? onboardingData.tenantDomain : slugify(onboardingData.tenantName)}
                loading={onboardingData.stepIsLoading}
                loadingText={"Creating tenant..."}
                showButton={!onboardingData.tenantIsSet}
                onSend={(message) => onSubmit ? onSubmit(message, onboardingData.activeStep) : ""} />
            </>
          )}
          {onboardingData.activeStep >= 4 && (
            <>
              <Typography variant={"h4"} sx={{
                color: "#ffffff",
                textAlign: "left",
                lineHeight: 1.5
              }}>Your private Instance is ready and I am happy to start working with you.</Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    color: '#ffffff',
                    marginTop: '3em',
                    "&.Mui-disabled": {
                      backgroundColor: '#343434',
                      color: 'rgba(255, 255, 255, 0.5)'
                    }
                  }}
                  onClick={() => onSubmit ? onSubmit(null, onboardingData.activeStep) : ''}
                  disabled={onboardingData.stepIsLoading}
                  endIcon={!onboardingData.stepIsLoading ? <SendIcon /> : <CircularProgress
                    color="inherit"
                    size={20}
                  />}
                >
                  Start working
                </Button>
              </Box>
            </>
          )}

        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#121212' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: 2 }}>
        {messages.map((message, index) => (
          <ChatMessage key={index} sender={message.sender} content={message.content} />
        ))}
      </Box>
      <ChatMessageInput onSend={handleSendMessage} />
    </Box>
  );
};

export default ChatWindow;
