

export const incomeType = [
  // Sales in Revenue = Sales + Product sales
  {
    name: 'Sales Revenue',
    id: 'sales_revenue',
    color: '#33cb4b'
  },
  {
    name: 'Product Sales',
    id: 'product_sales',
    color: '#33cb4b'
  },
  // Sales from services = services_fees + consulting_income
  {
    name: 'Service Fees',
    id: 'service_fees',
    color: '#33cb4b'
  },
  {
    name: 'Consulting Income',
    id: 'consulting_income',
    color: '#33cb4b'
  },
  // Recurring Revenue = subscription_income + licensing_fees
  {
    name: 'Subscription Income',
    id: 'subscription_income',
    color: '#33cb4b'
  },
  {
    name: 'Licensing Fees',
    id: 'licensing_fees',
    color: '#33cb4b'
  },
  // Other operating revenue = rental + comission + roaylities + franchies + partnership + refunds + miscellaneous
  {
    name: 'Commission Income',
    id: 'commission_income',
    color: '#33cb4b'
  },
  {
    name: 'Rental Income',
    id: 'rental_income',
    color: '#33cb4b'
  },
  {
    name: 'Interest Income',
    id: 'interest_income',
    color: '#33cb4b'
  },
  {
    name: 'Royalties',
    id: 'royalties',
    color: '#33cb4b'
  },
  {
    name: 'Partnership Income',
    id: 'partnership_income',
    color: '#33cb4b'
  },
  {
    name: 'Franchise Fees',
    id: 'franchiseFees',
    color: '#33cb4b'
  },
  {
    name: 'Refunds/Reimbursements',
    id: 'refundsreimbursements',
    color: '#33cb4b'
  },
  {
    name: 'Refunds/Reimbursements',
    id: 'refunds_reimbursements',
    color: '#33cb4b'
  },
  {
    name: 'Miscellaneous Income',
    id: 'miscellaneous_income',
    color: '#33cb4b'
  },
  {
    name: 'Other Operating Income',
    id: 'other_operating_income',
    color: '#33cb4b'
  },
  //non-operating = interest + dividend + investment + foreign exhange + gain on sale + grantssub
  {
    name: 'Grants/Subsidies',
    id: 'grants_subsidies',
    color: '#33cb4b'
  },
  {
    name: 'Investment Income',
    id: 'investment_income',
    color: '#33cb4b'
  },
  {
    name: 'Dividends',
    id: 'dividends',
    color: '#33cb4b'
  },
  {
    name: 'Foreign Exchange Gains',
    id: 'foreign_exchange_gains',
    color: '#33cb4b'
  },
  {
    name: 'Gain on Sale of Assets',
    id: 'gain_on_sale_of_assets',
    color: '#33cb4b'
  },
]

export const revenueType = [
  {
    name: 'Revenue from Sales',
    id: 'salesRevenue',
    color: '#33cb4b'
  },
  {
    name: 'Revenue from Services',
    id: 'serviceRevenue',
    color: '#33cb4b'
  },
  {
    name: 'Recurring Revenue',
    id: 'recurringRevenue',
    color: '#33cb4b'
  },
  {
    name: 'Other operating revenue',
    id: 'otherOperatingIncome',
    color: '#33cb4b'
  },
  {
    name: 'Non-operating revenue',
    id: 'nonOperatingIncome',
    color: '#33cb4b'
  },
]