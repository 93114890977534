import React from 'react'
import { Box, Paper, Typography } from '@mui/material'

interface MessageProps {
  sender: 'user' | 'bot';
  content: string;
}
const ChatMessage: React.FC<MessageProps> = ({ sender, content }) => {
  const isUser = sender === 'user';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
        marginBottom: '8px',
      }}
    >
      <Paper
        sx={{
          padding: '12px',
          backgroundColor: isUser ? '#1976d2' : '#2E2E2E',
          color: 'white',
          maxWidth: '60%',
          borderRadius: '12px',
          borderBottomRightRadius: isUser ? '0' : '12px',
          borderBottomLeftRadius: isUser ? '12px' : '0',
        }}
      >
        <Typography variant="body1">{content}</Typography>
      </Paper>
    </Box>
  );
};

export default ChatMessage;