import React from 'react'
import { CopilotKit } from '@copilotkit/react-core'
import { useAppSelector } from '../state/stateHooks'
import { useAuth0 } from '@auth0/auth0-react'

interface CopilotKitHolderProps {
    children: React.ReactNode
}

const CopilotKitHolder: React.FC<CopilotKitHolderProps> = ({ children }) => {
    const tenantData = useAppSelector((state) => state.onboardingReducer);
    const { user } = useAuth0();


    return (
        <CopilotKit
            runtimeUrl="/api/v1/chat/copilotkit"
            headers={{
                "x-tenant-id": tenantData.tenantName
            }}
            agent="cyron"
            properties={{
                corporate_id: tenantData.companyData.id,
                tenant_id: tenantData.tenantName,
                user_id: user ? (user.sub || user.userId) : null,
            }}
        >
            {children}
        </CopilotKit>
    )
}

export default CopilotKitHolder
