import instance from './index'
import { UserProps, UserUpdateInput } from '../../interface/IUser'
const fetchUserMasterData = async (userId: string) : Promise<UserProps | undefined> => {
  const userData = await instance.get<UserProps>(`/api/v1/user/user/${userId}`, {
    headers: {
      "x-tenant-id": "master"
    }
  })

  if (userData && userData.data) {
    return userData.data;
  }

  return undefined;
}

const updateUserData = async (userId: string, data: UserUpdateInput) : Promise<UserProps | undefined> => {
  const userData = await instance.put(`/api/v1/user/user/${userId}`, data)

  if (userData && userData.data) {
    return userData.data;
  }

  return undefined;
}


export {
  fetchUserMasterData,
  updateUserData
}
